import { useLayoutEffect, useRef } from 'react';
import './MJGAnimatedPinIcon.scss';

type MJGAnimatedPinIconProps = {
  animate: boolean;
};

export default function MJGAnimatedPinIcon({ animate }: MJGAnimatedPinIconProps) {
  const path1 = useRef<SVGPathElement>(null);
  const path2 = useRef<SVGPathElement>(null);
  const path3 = useRef<SVGPathElement>(null);
  const path4 = useRef<SVGPathElement>(null);

  useLayoutEffect(() => {
    if (!path1.current || !path2.current || !path3.current || !path4.current) {
      return;
    }

    if (animate) {
      path1.current.style.animationName = 'none';
      path2.current.style.animationName = 'none';
      path3.current.style.animationName = 'none';
      path4.current.style.animationName = 'none';

      requestAnimationFrame(() => {
        setTimeout(() => {
          if (!path1.current || !path2.current || !path3.current || !path4.current) return;
          path1.current.style.animationName = 'path1FirstAnim';
          path2.current.style.animationName = 'path2FirstAnim';
          path3.current.style.animationName = 'path3FirstAnim';
          path4.current.style.animationName = 'path4FirstAnim';
        }, 0);
      });
    }
  }, [animate, path1.current, path2.current, path3.current, path4.current]);

  return (
    <svg
      width="48"
      height="54"
      viewBox="0 0 48 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mjg-animated-icon"
    >
      <path
        ref={path1}
        id="path1"
      />
      <path
        ref={path2}
        id="path2"
      />
      <path
        ref={path3}
        id="path3"
      />
      <path
        ref={path4}
        id="path4"
      />
    </svg>
  );
}
