import { defineMessages } from 'react-intl';
import { REQUEST_NEW_PASSWORD_ERRORS } from '../../../../@types/graphql-errors';

export const errorTranslations = defineMessages({
  [REQUEST_NEW_PASSWORD_ERRORS.NOT_FOUND]: {
    id: 'RequestNewPasswordForm.notFound',
    defaultMessage: 'Une erreur est survenue, veuillez nous contacter.',
  },
});

export default defineMessages({
  title: {
    id: 'RequestNewPassword.title',
    defaultMessage: 'Mot de passe oublié ?',
  },
  description: {
    id: 'RequestNewPassword.description',
    defaultMessage:
      'Saisissez l’email de votre compte My Job Glasses afin de recevoir un lien et réinitialiser votre mot de passe.',
  },
  backToSignin: {
    id: 'RequestNewPassword.backToSignin',
    defaultMessage: 'Retour à la page de connexion',
  },
});
