import { PropsWithChildren } from 'react';
import './Layout.scss';
import classNames from 'classnames';
import { Languages, useLanguage } from 'hooks/use-languages/useLanguages';
import { setStorageItem } from 'utils/storage';
import Storage from 'enums/storage';
import { AuthenticationTranslations } from 'scenes/authentication/Authentication.translations';
import { useIntl } from 'react-intl';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
import Routes from 'Routes';

type LayoutProps = PropsWithChildren & {
  withAsideImage?: boolean;
  notAnimated?: boolean;
  withCompanySignUpBtn?: boolean;
  withFullScreenImage?: boolean;
};

export default function Layout({ children, withAsideImage, withCompanySignUpBtn, withFullScreenImage }: LayoutProps) {
  const { language, setLanguage } = useLanguage();
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  function changeLanguage(lang: Languages) {
    setStorageItem(Storage.LANGUAGE, lang);
    setLanguage(lang);
    window.location.reload();
  }

  const isOnHrAdminSignup = location.pathname === Routes.hrAdminSignup;
  const route = isOnHrAdminSignup ? Routes.signup : Routes.hrAdminSignup;
  const buttonLabel = isOnHrAdminSignup
    ? AuthenticationTranslations.userSignup
    : AuthenticationTranslations.companySignup;

  return (
    <div className={classNames('layout', { 'layout--full-screen-image': withFullScreenImage })}>
      <div className="layout__main">
        <main
          className={classNames('layout__main__container', {
            'layout__main__container--with-aside': withAsideImage && !withFullScreenImage,
          })}
        >
          <nav
            className={classNames('layout__main__container__nav', {
              'layout__main__container__nav--white': withFullScreenImage,
            })}
          >
            <img
              className="layout__main__container__nav__logo"
              src="https://assets.myjobglasses.com/logo/mjg-logo-v3-rectangle.svg"
              alt="my job glasses logo"
            />
            {!withFullScreenImage && (
              // Waiting for the English version of Connect
              <div className="layout__main__container__nav__languages">
                <button
                  type="button"
                  className={classNames({ selected: language === 'fr' })}
                  onClick={() => changeLanguage('fr')}
                >
                  FR
                </button>
                |
                <button
                  type="button"
                  className={classNames({ selected: language === 'en' })}
                  onClick={() => changeLanguage('en')}
                >
                  EN
                </button>
              </div>
            )}
            {withCompanySignUpBtn && (
              <PrimaryButton
                label={intl.formatMessage(buttonLabel)}
                cyLabel="company-signup"
                onClick={() => navigate(route)}
                outlined
                smaller
              />
            )}
          </nav>

          <div className="layout__main__container__separator" />

          <div className="layout__main__container__content">{children}</div>
        </main>
      </div>
      {withAsideImage && !withFullScreenImage && <aside className="layout__aside" />}
    </div>
  );
}

export function LayoutHeader({ children }: PropsWithChildren) {
  return <div className="layout-header">{children}</div>;
}

export function LayoutSeparator({ children }: PropsWithChildren) {
  return <div className="layout-separator">{children}</div>;
}
