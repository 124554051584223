import { defineMessages } from 'react-intl';

export const StrengthHintsTranslations = defineMessages({
  charactersLength: {
    id: 'StrengthHintsTranslations.charactersLength',
    defaultMessage: 'Au moins 8 caractères',
  },
  uppercase: {
    id: 'StrengthHintsTranslations.uppercase',
    defaultMessage: 'Au moins une majuscule',
  },
  lowercase: {
    id: 'StrengthHintsTranslations.lowercase',
    defaultMessage: 'Au moins une minuscule',
  },
  number: {
    id: 'StrengthHintsTranslations.number',
    defaultMessage: 'Au moins un chiffre',
  },
  specialCharacter: {
    id: 'StrengthHintsTranslations.specialCharacter',
    defaultMessage: 'Au moins un caractère spécial',
  },
});
