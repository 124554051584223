import { gql } from '@apollo/client';

export const GET_SETTINGS = gql`
  query GET_SETTINGS($locale: AvailableLanguagesEnum) {
    currentUserV2 {
      id
    }
    settings {
      franceTravailAuthorizationUrl
      linkedinAuthorizationUrl
      primarySituations {
        key
        name(locale: $locale)
        secondarySituations {
          key
          name(locale: $locale)
          grades
        }
      }
      jobTitles {
        key
        name(locale: $locale)
      }
      recommendedQualifications
      diplomas {
        key
        name(locale: $locale)
      }
      comesFrom {
        key
        name(locale: $locale)
      }
      jobSeekerSupportOrganizationTypes {
        key
        name(locale: $locale)
      }
      jobSeekerSupportPrograms {
        key
        name(locale: $locale)
      }
      hrChallenges {
        key
        name(locale: $locale)
      }
    }
  }
`;
