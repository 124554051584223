import { TextField } from '@myjobglasses/account-ui';
import { Field, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useRequestNewPassword } from '../../../../hooks/useRequestNewPassword';
import translations from './RequestNewPasswordForm.translations';
import validation from './RequestNewPasswordForm.validation';

import styles from './RequestNewPasswordForm.module.scss';

function RequestNewPasswordForm() {
  const intl = useIntl();

  const onSubmit = useRequestNewPassword();

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validateOnMount
      validateOnChange
      validationSchema={validation(intl)}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({
        isValid,
        handleSubmit,
        // eslint-disable-next-line arrow-body-style
      }) => {
        return (
          <form
            className={styles.form}
            onSubmit={handleSubmit}
          >
            <Field
              name="email"
              type="email"
              className={styles.field}
              label="Email"
              component={TextField}
            />

            <button
              id="request-new-password-form-submit"
              data-testid="submit-button"
              type="submit"
              className={styles.submitButton}
              disabled={!isValid}
            >
              {intl.formatMessage(translations.requestButton)}
            </button>
          </form>
        );
      }}
    </Formik>
  );
}

export default RequestNewPasswordForm;
