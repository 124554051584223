import { useMutation } from '@apollo/client';
import AnimatedText from 'components/animated-text/AnimatedText';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import { motion } from 'framer-motion';
import { REQUEST_MODERATION_MUTATION } from 'gqls/Publication.gql';
import { useLayoutEffect } from 'react';
import { useIntl } from 'react-intl';
import { PANELS } from '.';
import { RequestModerationMutation, RequestModerationMutationVariables } from '../../../@types/graphql';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import './ThankYouPanel.scss';
import { ThankYouPanelTranslations } from './panels.translations';
import { error } from 'console';
import { errorTracker } from 'error-tracker/error-tracker';

export default function ThankYouPanel() {
  const { formatMessage } = useIntl();

  const { user } = useProfileCompletionContext();
  const updateUser = useProfileCompletionUserUpdate();
  const { currentPanelIndex } = useProfileCompletionContext();

  const [requestModeration] = useMutation<RequestModerationMutation, RequestModerationMutationVariables>(
    REQUEST_MODERATION_MUTATION,
    {
      variables: {
        userId: user?.id || '',
      },
    },
  );

  async function finishCompletion() {
    if (
      (user?.currentUserAuthorizations.canRequestModeration && user?.isModeratable) ||
      (user?.currentUserAuthorizations.canRequestAdminRhModeration && user?.isAdminRhModeratable)
    ) {
      await requestModeration();
    } else {
      if (user?.wantToShare) {
        errorTracker.sendError(
          '[REQUEST_MODERATION] User cannot request moderation at the end of step by step V2',
          'User cannot request moderation at the end of step by step',
          {
            namespace: 'Frontend',
            tags: {
              isModeratable: `${user?.isModeratable}`,
              isAdminHRModeratable: `${user?.isAdminRhModeratable}`,
              canRequestModeration: `${user?.currentUserAuthorizations.canRequestModeration}`,
              canRequestAdminRhModeration: `${user?.currentUserAuthorizations.canRequestAdminRhModeration}`,
            },
          },
        );
      }
    }
    await updateUser({ showCongratulationsPanel: false });
  }

  useLayoutEffect(() => {
    // REFACTO: we should not need this condition to not trigger confettins every frames ...
    if (currentPanelIndex === PANELS.length - 1) {
      setTimeout(() => {
        finishCompletion();
      }, 12000);
    }
  }, [PANELS, currentPanelIndex, user?.isModeratable, requestModeration]);

  return (
    <>
      <motion.h1
        className="thank-you-panel__title"
        initial={{ transform: 'translate3d(0, 25vh, 0)' }}
        animate={{ width: 'fit-content', transform: 'translate3d(0, 0, 0)' }}
        transition={{ duration: 0.5, delay: 4, ease: 'easeInOut' }}
      >
        <AnimatedText
          text={formatMessage(ThankYouPanelTranslations.title)}
          delay={1}
        />
      </motion.h1>

      <motion.h2
        className="thank-you-panel__subtitle"
        initial={{ opacity: 0, transform: 'translate3d(50px, 0px, 0px)' }}
        animate={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px)' }}
        transition={{ delay: 4.5, ease: 'easeInOut' }}
        dangerouslySetInnerHTML={{
          __html: user?.requestProfileModificationUpdate
            ? formatMessage(ThankYouPanelTranslations.subtitleMember)
            : user?.wantToShare
              ? formatMessage(ThankYouPanelTranslations.subtitleAmbassador)
              : formatMessage(ThankYouPanelTranslations.subtitleMember),
        }}
      />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 6, ease: 'easeInOut' }}
        className="thank-you-panel__redirection-block"
      >
        <PrimaryButton
          label={formatMessage(ThankYouPanelTranslations.goToMarketplaceButton)}
          onClick={finishCompletion}
        />
      </motion.div>
    </>
  );
}
