import { defineMessages } from 'react-intl';

export const ErrorsTranslations = defineMessages({
  default: {
    id: 'ErrorsTranslations.default',
    defaultMessage: 'Une erreur est survenue',
  },
  network: {
    id: 'CommonTranslations.network',
    defaultMessage: 'Une erreur de réseau est surevenue',
  },
  technical: {
    id: 'CommonTranslations.technical',
    defaultMessage: 'Une erreur technique est surevenue',
  },
});
