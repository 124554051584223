import { Point } from 'framer-motion';
import { useCallback, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import { getCroppedImage } from '../AvatarInput.util';
import ButtonsGroup from 'components/buttons-group/ButtonsGroup';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import useModal from 'components/modal/Modal.hook';
import { useIntl } from 'react-intl';
import { CommonTranslations } from 'i18n/common.translations';
import './AvatarCropModal.scss';

const INITIAL_CROP_VALUE = { x: 0, y: 0 };
const CROP_SIZE = { width: 256, height: 256 };
const INITIAL_CROPPED_AREA_PERCENTAGES = {
  x: 25,
  y: 25,
  width: 100,
  height: 100,
};

type AvatarCropModalProps = {
  value: string;
  onCropComplete: (file: File) => void;
};

export default function AvatarCropModal({ value, onCropComplete }: AvatarCropModalProps) {
  const { formatMessage } = useIntl();
  const [, closeModal] = useModal();

  const [crop, setCrop] = useState<Point>(INITIAL_CROP_VALUE);
  const [zoom, setZoom] = useState(1);
  const [cropAreaPixels, setCropAreaPixels] = useState<Area>();

  const handleCrop = useCallback(
    (_: Area, cropAreaPixels: Area) => {
      if (!value) return;
      setCropAreaPixels(cropAreaPixels);
    },
    [value],
  );

  const submitCrop = useCallback(async () => {
    if (!cropAreaPixels || !value) return;
    const blob = await getCroppedImage(value, cropAreaPixels);
    if (blob) onCropComplete(new File([blob], 'avatar.jpg', { type: 'image/jpeg' }));
  }, [cropAreaPixels, value, onCropComplete]);

  return (
    <div className="avatar-crop-modal">
      <div className="avatar-crop-modal__container">
        <Cropper
          image={value}
          crop={crop}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          cropSize={CROP_SIZE}
          initialCroppedAreaPercentages={INITIAL_CROPPED_AREA_PERCENTAGES}
          onCropComplete={handleCrop}
          cropShape="round"
        />
      </div>

      <ButtonsGroup>
        <SecondaryButton
          label={formatMessage(CommonTranslations.cancel)}
          onClick={closeModal}
        />
        <PrimaryButton
          label={formatMessage(CommonTranslations.confirm)}
          onClick={submitCrop}
        />
      </ButtonsGroup>
    </div>
  );
}
