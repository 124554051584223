import { defineMessages } from 'react-intl';
import { GradeEnum } from '../@types/graphql';

export const GradeEnumTranslations = defineMessages({
  [GradeEnum.Autre]: {
    id: 'GradeEnum.Autre',
    defaultMessage: 'Autre cursus',
  },
  [GradeEnum.Bts1]: {
    id: 'GradeEnum.Bts1',
    defaultMessage: 'BTS - 1ère année',
  },
  [GradeEnum.Bts2]: {
    id: 'GradeEnum.Bts2',
    defaultMessage: 'BTS - 2ème année',
  },
  [GradeEnum.But1]: {
    id: 'GradeEnum.But1',
    defaultMessage: 'BUT - 1ère année',
  },
  [GradeEnum.But2]: {
    id: 'GradeEnum.But2',
    defaultMessage: 'BUT - 2ème année',
  },
  [GradeEnum.But3]: {
    id: 'GradeEnum.But3',
    defaultMessage: 'BUT - 3ème année',
  },
  [GradeEnum.Cap1]: {
    id: 'GradeEnum.Cap1',
    defaultMessage: 'CAP - 1ère année',
  },
  [GradeEnum.Cap2]: {
    id: 'GradeEnum.Cap2',
    defaultMessage: 'CAP - 2ème année',
  },
  [GradeEnum.Cfa1]: {
    id: 'GradeEnum.Cfa1',
    defaultMessage: 'Première',
  },
  [GradeEnum.Cfa2]: {
    id: 'GradeEnum.Cfa2',
    defaultMessage: 'Seconde',
  },
  [GradeEnum.Cfat]: {
    id: 'GradeEnum.Cfat',
    defaultMessage: 'Terminale',
  },
  [GradeEnum.College3]: {
    id: 'GradeEnum.College3',
    defaultMessage: '3e',
  },
  [GradeEnum.College4]: {
    id: 'GradeEnum.College4',
    defaultMessage: '4e',
  },
  [GradeEnum.College5]: {
    id: 'GradeEnum.College5',
    defaultMessage: '5e',
  },
  [GradeEnum.College6]: {
    id: 'GradeEnum.College6',
    defaultMessage: '6e',
  },
  [GradeEnum.Cpge1]: {
    id: 'GradeEnum.Cpge1',
    defaultMessage: 'CPGE - 1ère année',
  },
  [GradeEnum.Cpge2]: {
    id: 'GradeEnum.Cpge2',
    defaultMessage: 'CPGE - 2ème année',
  },
  [GradeEnum.Dcg1]: {
    id: 'GradeEnum.DCG1',
    defaultMessage: 'DCG - 1ère année',
  },
  [GradeEnum.Dcg2]: {
    id: 'GradeEnum.DCG2',
    defaultMessage: 'DCG - 2ème année',
  },
  [GradeEnum.Dcg3]: {
    id: 'GradeEnum.DCG3',
    defaultMessage: 'DCG - 3ème année',
  },
  [GradeEnum.DiplomeUniversitaire]: {
    id: 'GradeEnum.DiplomeUniversitaire',
    defaultMessage: 'Doctorat',
  },
  [GradeEnum.Doctorat]: {
    id: 'GradeEnum.Doctorat',
    defaultMessage: 'Diplome Universitaire',
  },
  [GradeEnum.Ec1]: {
    id: 'GradeEnum.Ec1',
    defaultMessage: 'Bac +1',
  },
  [GradeEnum.Ec2]: {
    id: 'GradeEnum.Ec2',
    defaultMessage: 'Bac +2',
  },
  [GradeEnum.Ec3]: {
    id: 'GradeEnum.Ec3',
    defaultMessage: 'Bac +3',
  },
  [GradeEnum.Ec4]: {
    id: 'GradeEnum.Ec4',
    defaultMessage: 'Bac +4',
  },
  [GradeEnum.Ec5]: {
    id: 'GradeEnum.Ec5',
    defaultMessage: 'Bac +5',
  },
  [GradeEnum.Ec6]: {
    id: 'GradeEnum.Ec6',
    defaultMessage: 'Bac >+5',
  },
  [GradeEnum.Ei1]: {
    id: 'GradeEnum.Ei1',
    defaultMessage: 'Bac +1',
  },
  [GradeEnum.Ei2]: {
    id: 'GradeEnum.Ei2',
    defaultMessage: 'Bac +2',
  },
  [GradeEnum.Ei3]: {
    id: 'GradeEnum.Ei3',
    defaultMessage: 'Bac +3',
  },
  [GradeEnum.Ei4]: {
    id: 'GradeEnum.Ei4',
    defaultMessage: 'Bac +4',
  },
  [GradeEnum.Ei5]: {
    id: 'GradeEnum.Ei5',
    defaultMessage: 'Bac +5',
  },
  [GradeEnum.Ei6]: {
    id: 'GradeEnum.Ei6',
    defaultMessage: 'Bac >+5',
  },
  [GradeEnum.Licence1]: {
    id: 'GradeEnum.Licence1',
    defaultMessage: 'Licence 1',
  },
  [GradeEnum.Licence2]: {
    id: 'GradeEnum.Licence2',
    defaultMessage: 'Licence 2',
  },
  [GradeEnum.Licence3]: {
    id: 'GradeEnum.Licence3',
    defaultMessage: 'Licence 3',
  },
  [GradeEnum.Lycee1]: {
    id: 'GradeEnum.Lycee1',
    defaultMessage: 'Première',
  },
  [GradeEnum.Lycee2]: {
    id: 'GradeEnum.Lycee2',
    defaultMessage: 'Seconde',
  },
  [GradeEnum.LyceeT]: {
    id: 'GradeEnum.LyceeT',
    defaultMessage: 'Terminale',
  },
  [GradeEnum.Master1]: {
    id: 'GradeEnum.Master1',
    defaultMessage: 'Master 1',
  },
  [GradeEnum.Master2]: {
    id: 'GradeEnum.Master2',
    defaultMessage: 'Master 2',
  },
  [GradeEnum.Medecine1]: {
    id: 'GradeEnum.Medecine1',
    defaultMessage: 'Médecine - 1er cycle',
  },
  [GradeEnum.Medecine2]: {
    id: 'GradeEnum.Medecine2',
    defaultMessage: 'Médecine - 2e cycle',
  },
  [GradeEnum.Medecine3]: {
    id: 'GradeEnum.Medecine3',
    defaultMessage: 'Médecine - 3e cycle',
  },
});
