import { Company } from '../@types/graphql';

type HRAdminPayload = {
  companyAdminProfiles?: Pick<Company, 'id'>[] | null;
  schoolAdminProfiles?: Pick<Company, 'id'>[] | null;
};

export function hasCompanyAdminProfile(user?: HRAdminPayload | null) {
  return (user?.companyAdminProfiles || []).length > 0;
}
export function hasSchoolAdminProfile(user?: HRAdminPayload | null) {
  return (user?.schoolAdminProfiles || []).length > 0;
}
