import { gql } from '@apollo/client';

export const GET_DEPARTMENTS_QUERY = gql`
  query GetDepartments($search: String, $locale: String) {
    departments(search: $search, locale: $locale)
  }
`;

export const GET_GOUV_SCHOOLS = gql`
  query GetGouvSchools($term: String!, $department: String!, $establishmentType: SecondarySituationEnum) {
    gouvSchoolSearch(
      searchQuery: $term
      filters: { departmentLabel: $department, establishmentType: $establishmentType }
      page: 1
      perPage: 20
    ) {
      id
      name
      communeName
      academyLabel
    }
  }
`;

export const CREATE_GOUV_SCHOOL_MUTATION = gql`
  mutation CreateGouvSchool(
    $name: String!
    $userId: ID!
    $communeName: String
    $establishmentType: SecondarySituationEnum
    $departmentLabel: String
  ) {
    createGouvSchool(
      name: $name
      userId: $userId
      communeName: $communeName
      establishmentType: $establishmentType
      departmentLabel: $departmentLabel
    ) {
      id
      name
      communeName
      departmentLabel
    }
  }
`;
