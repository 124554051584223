import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import {
  containsUppercase,
  containsLowercase,
  containsSpecialCharacter,
  containsDigit,
  haveMinimumLength,
} from '../../utils/password.util';
import translations from './MandatoryPasswordSpecs.translations';

import styles from './MandatoryPasswordSpecs.module.scss';

interface MandatoryPasswordSpecsProps {
  password: string;
}

function MandatoryPasswordSpecs({ password }: MandatoryPasswordSpecsProps) {
  const intl = useIntl();
  const uppercaseCharacter = containsUppercase(password);
  const lowercaseCharacter = containsLowercase(password);
  const specialCharacter = containsSpecialCharacter(password);
  const digitCharacter = containsDigit(password);
  const minimumLength = haveMinimumLength(password);

  return (
    <div
      data-testid="mandatory-password-specs"
      className={styles.mandatoryPasswordSpecsContainer}
    >
      <p className={styles.helper}>
        {intl.formatMessage<ReactNode>(translations.helper, {
          link: (content) => (
            <a href="https://help.myjobglasses.com/fr/articles/4396180-comment-definir-un-mot-de-passe-securise">
              {content}
            </a>
          ),
        })}
      </p>

      <ul className={styles.specsContainer}>
        <li
          data-testid="mandatory-password-specs-uppercase"
          className={classnames(styles.spec, {
            [styles.valid]: uppercaseCharacter,
          })}
        >
          <span className={styles.bullet} />
          {intl.formatMessage(translations.uppercaseCharacter)}
        </li>

        <li
          data-testid="mandatory-password-specs-lowercase"
          className={classnames(styles.spec, {
            [styles.valid]: lowercaseCharacter,
          })}
        >
          <span className={styles.bullet} />
          {intl.formatMessage(translations.lowercaseCharacter)}
        </li>

        <li
          data-testid="mandatory-password-specs-special"
          className={classnames(styles.spec, {
            [styles.valid]: specialCharacter,
          })}
        >
          <span className={styles.bullet} />
          {intl.formatMessage(translations.specialCharacter)}
        </li>

        <li
          data-testid="mandatory-password-specs-digit"
          className={classnames(styles.spec, {
            [styles.valid]: digitCharacter,
          })}
        >
          <span className={styles.bullet} />
          {intl.formatMessage(translations.digitCharacter)}
        </li>

        <li
          data-testid="mandatory-password-specs-minimum-length"
          className={classnames(styles.spec, {
            [styles.valid]: minimumLength,
          })}
        >
          <span className={styles.bullet} />
          {intl.formatMessage(translations.minimumLength)}
        </li>
      </ul>
    </div>
  );
}

export default MandatoryPasswordSpecs;
