import { defineMessages } from 'react-intl';

export const ContactAppointmentTranslations = defineMessages({
	title: {
		id: 'ContactAppointmentTranslations.title',
		defaultMessage: "Révolutionnez votre stratégie de recrutement avec nos offres Premium",
	},
	takeAppointment: {
		id: 'ContactAppointmentTranslations.takeAppointment',
		defaultMessage: 'Prendre rendez-vous',
	},
});