import EyeIcon from 'assets/component-icons/EyeIcon';
import EyeOffIcon from 'assets/component-icons/EyeOffIcon';
import HelpCircleIcon from 'assets/component-icons/HelpCircleIcon';
import { IconProps } from 'assets/component-icons/Icon';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { ComponentType, DetailedHTMLProps, FocusEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Routes from 'Routes';
import './PasswordInput.scss';
import { PasswordInputTranslations } from './PasswordInput.translations';
import StrengthHints from './strength-hints/StrengthHints';
import ConfirmProgressBar from './strength-progress-bar/ConfirmProgressBar';
import StrengthProgressBar from './strength-progress-bar/StrengthProgressBar';

// eslint-disable-next-line max-len
type DefaultInputProps = Pick<
  DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'onFocus' | 'onBlur' | 'placeholder'
>;
type PasswordInputProps = DefaultInputProps & {
  icon?: ComponentType<IconProps>;
  name: string;
  value: string;
  label?: string;
  error?: string;
  strengthHints?: boolean;
  confirm?: boolean;
  passwordToConfirm?: string;
  forgottenPassword?: boolean;
  dataCy?: string;
};

export default function PasswordInput({
  icon: Icon,
  name,
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  error,
  strengthHints,
  passwordToConfirm,
  confirm,
  forgottenPassword,
  dataCy,
}: PasswordInputProps) {
  const intl = useIntl();

  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  function togglePasswordVisibility() {
    setShowPassword((sp) => !sp);
  }

  function handleFocus(event: FocusEvent<HTMLInputElement>) {
    setIsFocused(true);
    onFocus?.(event);
  }

  function handleBlur(event: FocusEvent<HTMLInputElement>) {
    setIsFocused(false);
    onBlur?.(event);
  }

  return (
    <div className="password-input">
      <div className="password-input__header">
        {label && (
          <label
            htmlFor={name}
            className={classNames({ error: error })}
          >
            {label}
          </label>
        )}
        {strengthHints && (
          <a
            className="password-input__header__help-link"
            href="https://help.myjobglasses.com/fr/articles/109088-comment-creer-un-mot-de-passe-securise"
            target="_blank"
            rel="noreferrer"
          >
            <HelpCircleIcon /> {intl.formatMessage(PasswordInputTranslations.needHelp)}
          </a>
        )}
        {forgottenPassword && (
          <Link
            data-cy={dataCy && `${dataCy}__forgotten-password`}
            className="password-input__header__help-link"
            to={Routes.requestNewPassword}
          >
            <HelpCircleIcon />
            {intl.formatMessage(PasswordInputTranslations.forgottenPassword)}
          </Link>
        )}
      </div>

      <div
        className={classNames('password-input__main', {
          'password-input__main--focused': isFocused,
          'password-input__main--error': error,
        })}
      >
        {Icon && <Icon className="password-input__main__icon" />}
        <input
          data-cy={dataCy}
          id={name}
          type={showPassword ? 'text' : 'password'}
          {...{
            name,
            value,
            onChange,
            placeholder,
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {strengthHints && <StrengthProgressBar password={value} />}
        {confirm && (
          <ConfirmProgressBar
            password={value}
            passwordToConfirm={passwordToConfirm}
          />
        )}
        <button
          type="button"
          className="password-input__main__show-button"
          onClick={togglePasswordVisibility}
          tabIndex={-1}
        >
          {showPassword ? <EyeIcon /> : <EyeOffIcon />}
        </button>
      </div>

      {error && (
        <span
          data-cy={dataCy && `${dataCy}__error`}
          className="password-input__error"
        >
          {error}
        </span>
      )}

      <AnimatePresence>{strengthHints && isFocused && <StrengthHints password={value} />}</AnimatePresence>
    </div>
  );
}
