import CameraPlusIcon from 'assets/component-icons/CameraPlusIcon';
import classNames from 'classnames';
import useModal from 'components/modal/Modal.hook';
import { ChangeEvent, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import AvatarCropModal from 'components/avatar-input/crop-modal/AvatarCropModal';
import { CREATE_UPLOAD_LINK_MUTATION } from './UploadLogoInput.gql';
import { useMutation } from '@apollo/client';
import { UploadLogoInputTranslations } from './UploadLogoInput.translations';
import {
  Create_Upload_LinkMutation,
  Create_Upload_LinkMutationVariables,
  UploadFormatEnum,
  UploadLinkKindEnum,
} from '../../@types/graphql';

import './UploadLogoInput.scss';

const MAX_FILE_SIZE = 5 * 1024 * 1024;

type UploadLogoInputProps = {
  onChange: (uploadId: string) => void;
  small?: boolean;
  label?: string;
};

function acceptedImageTypes() {
  return Object.values(UploadFormatEnum).map((format) => `image/${format.toLowerCase()}`);
}

export default function UploadLogoInput({ onChange, small, label }: UploadLogoInputProps) {
  const { formatMessage } = useIntl();
  const [openModal, closeModal] = useModal();
  const inputRef = useRef<HTMLInputElement>(null);

  const [blob, setBlob] = useState<string>();
  const [image, setImage] = useState<File>();
  const [error, setError] = useState<string | null>(null);

  const [createUploadLink] = useMutation<Create_Upload_LinkMutation, Create_Upload_LinkMutationVariables>(
    CREATE_UPLOAD_LINK_MUTATION,
  );

  useLayoutEffect(() => {
    if (!blob || image) return;
    openModal({
      title: formatMessage(UploadLogoInputTranslations.modalTitle),
      subtitle: formatMessage(UploadLogoInputTranslations.modalSubtitle),
      content: (
        <AvatarCropModal
          value={blob}
          onCropComplete={handleImageCrop}
        />
      ),
    });
  }, [blob, image, openModal]);

  const onImageUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target?.files?.[0];
      if (!file) return;

      const validImageTypes = acceptedImageTypes();

      if (!validImageTypes.includes(file.type)) {
        setError(formatMessage(UploadLogoInputTranslations.invalidType));
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        setError(formatMessage(UploadLogoInputTranslations.fileTooLarge));
        return;
      }

      setBlob(URL.createObjectURL(file));
      setError(null);
      event.target.value = '';
    },
    [setBlob],
  );

  const onImageReset = useCallback(() => {
    setBlob(undefined);
    setImage(undefined);
    onUploadClick();
  }, [setBlob, setImage, onUploadClick]);

  async function handleImageCrop(logo: File) {
    setImage(logo);
    const fileType = logo.type.split('/')[1].toUpperCase();
    try {
      const { data } = await createUploadLink({
        variables: {
          kind: UploadLinkKindEnum.CompanyCreationRequestCompanyLogo,
          format: fileType as UploadFormatEnum,
        },
      });

      if (!data?.createUploadLink?.awsUrl) {
        throw new Error(formatMessage(UploadLogoInputTranslations.failedToGetLink));
      }

      await fetch(data.createUploadLink.awsUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': logo.type,
        },
        body: logo,
      });

      onChange(data.createUploadLink.id);
      setError(null);
    } catch (err) {
      console.error('Logo upload failed:', err);
      setError(formatMessage(UploadLogoInputTranslations.uploadFailed));
    }
    closeModal();
  }

  function onUploadClick() {
    inputRef.current?.click();
  }

  return (
    <div className='upload-logo'>
    {label && (
      <label
      className={classNames({ error: error })}
      >
        {label}
      </label>
    )}
    <div className={classNames('upload-logo-input', { 'upload-logo-input--small': small })}>
      <input
        className="upload-logo-input__file-input"
        ref={inputRef}
        type="file"
        accept={acceptedImageTypes().join(', ')}
        onChange={onImageUpload}
        />

      <button
        type="button"
        className="upload-logo-input__viewer"
        onClick={onUploadClick}
        disabled={!!image}
        >
        {image ? (
          <img
          className="upload-logo-input__viewer__logo"
          src={URL.createObjectURL(image)}
          alt="Uploaded logo"
          />
          ) : (
            <CameraPlusIcon className="upload-logo-input__viewer__placeholder-icon" />
            )}
      </button>

      {error && <p className="upload-logo-input__error">{error}</p>}

      <button
        className="upload-logo-input__btn"
        onClick={image ? onImageReset : onUploadClick}
        >
        {formatMessage(UploadLogoInputTranslations.chooseButtom)}
      </button>
    </div>
        </div>
  );
}
