import { IntlShape } from 'react-intl';
import { array, boolean, mixed, number, object, string } from 'yup';
import {
  GenderEnum,
  GradeEnum,
  JobTitleKeyEnum,
  PrimarySituationEnum,
  SecondarySituationEnum,
} from '../../../@types/graphql';
import { PanelErrorTranslations } from './panels.translations';
import { BinaryResponse } from 'models/BinaryResponse.enum';

export const identityPanelValidationSchema = (intl: IntlShape) =>
  object({
    gender: mixed<GenderEnum>().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    firstName: string()
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .min(2, intl.formatMessage(PanelErrorTranslations.inputTooShort)),
    lastName: string()
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .min(1, intl.formatMessage(PanelErrorTranslations.inputTooShort)),
  });

export const professionalSituationPanelValidationSchema = (intl: IntlShape) =>
  object({
    position: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    companyName: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    yearsOfExperience: number().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

// secondarySituation: user?.secondarySituation?.key,
// currentGrade: user?.currentGrade,
// currentSchoolName: user?.currentSchoolName || '',
// currentSchoolCity: user?.currentSchoolCity || '',
export const studentPanelValidationSchema = (intl: IntlShape) =>
  object({
    secondarySituation: mixed<SecondarySituationEnum>().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    currentGrade: mixed<GradeEnum>().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    currentSchoolName: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    currentSchoolCity: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    currentSchoolDepartment: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const otherSituationPanelValidationSchema = (intl: IntlShape) =>
  object({
    neverWorked: boolean(),
    position: string().when('neverWorked', {
      is: false,
      then: (schema) => schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
    companyName: string().when('neverWorked', {
      is: false,
      then: (schema) => schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
    yearsOfExperience: number().when('neverWorked', {
      is: false,
      then: (schema) => schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
  });

export const memberPresentationPanelValidationSchema = (intl: IntlShape) =>
  object({
    memberPresentation: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const ambassadorPresentationPanelValidationSchema = (intl: IntlShape) =>
  object({
    ambassadorPresentation: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    aspirationPresentation: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    expertisePresentation: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    interestsPresentation: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const maxMeetingsPerMonthPanelValidationSchema = () =>
  object({
    maxMeetingsPerMonth: number(),
  });

export const phonePanelValidationSchema = (intl: IntlShape) =>
  object({
    phoneNumber: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const meetingPlacePanelValidationSchema = (intl: IntlShape) =>
  object({
    googlePlace: object().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const avatarPanelValidationSchema = (intl: IntlShape) =>
  object({
    avatar: mixed().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });

export const schoolCoachSituationPanelValidationSchema = (intl: IntlShape, primarySituation?: PrimarySituationEnum) =>
  object({
    secondarySituation: mixed<SecondarySituationEnum>().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    currentSchoolDepartment: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    currentSchoolName: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    position: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    teachedSchoolSubjects: array().when('position', {
      is: JobTitleKeyEnum.Teacher,
      then: (schema) =>
        schema
          .of(string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)))
          .min(1, intl.formatMessage(PanelErrorTranslations.inputEmpty))
          .required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
    accompaniedStudyLevels: array()
      .of(string().oneOf(Object.values(GradeEnum), intl.formatMessage(PanelErrorTranslations.invalidGradeEnum)))
      .min(1, intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    headTeacher: mixed<BinaryResponse>().when(['position', 'primarySituation'], {
      is: (position: JobTitleKeyEnum, primarySituation: PrimarySituationEnum) =>
        position === JobTitleKeyEnum.Teacher &&
        primarySituation === PrimarySituationEnum.ProfessionalAndSecondarySchoolStudentSupport,
      then: (schema) => schema.required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
      otherwise: (schema) => schema,
    }),
  });

export const franceTravailSupportSituationPanelValidationSchema = (intl: IntlShape) =>
  object({
    jobSeekerSupportOrganizationType: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    position: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    companyName: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    jobSeekerSupportPrograms: array()
      .of(string())
      .min(1, intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
  });
