import { defineMessages } from 'react-intl';

export const SignupTranslations = defineMessages({
  title: {
    id: 'Signup.title',
    defaultMessage: "S'inscrire",
  },
  subtitle: {
    id: 'Signup.subtitle',
    defaultMessage:
      'Pour vous inscrire, entrez votre adresse email, ou utilisez vos comptes LinkedIn ou France Travail.',
  },
  email: {
    id: 'Signup.email',
    defaultMessage: 'Email',
  },
  createAccount: {
    id: 'Signup.createAccount',
    defaultMessage: 'Créer mon compte',
  },
  existingAccount: {
    id: 'Signup.existingAccount',
    defaultMessage: 'Vous avez un compte ?',
  },
  signIn: {
    id: 'Signup.signIn',
    defaultMessage: 'Connectez-vous',
  },
  or: {
    id: 'Authentication.orSeparator',
    defaultMessage: 'ou',
  },
  linkedIn: {
    id: 'Authentication.continueWithLinkedIn',
    defaultMessage: 'Continuer avec LinkedIn',
  },
  franceTravail: {
    id: 'Authentication.continueWithFranceTravail',
    defaultMessage: 'Continuer avec France Travail',
  },
});

export const SignupErrorTranslations = defineMessages({
  emailTaken: {
    id: 'SignupError.emailTaken',
    defaultMessage: "Cette adresse email n'est pas disponible.",
  },
  error: {
    id: 'SignupError.error',
    defaultMessage: 'Une erreur est survenue.',
  },
});
