import { ComponentType } from 'react';
import ReactPhoneNumberInput from 'react-phone-number-input';
import './PhoneInput.scss';
import { IconProps } from 'assets/component-icons/Icon';
import classNames from 'classnames';

// eslint-disable-next-line max-len
type PhoneInputProps = {
  icon?: ComponentType<IconProps>;
  name: string;
  value: string;
  onChange: (value: string) => void;
  label?: string;
  error?: string;
};

export default function PhoneInput({ icon: Icon, name, label, value, onChange, error }: PhoneInputProps) {
  return (
    <div className={classNames('phone-input')}>
      {label && <label htmlFor={name}>{label}</label>}

      <div className="phone-input__main">
        {Icon && <Icon className="phone-input__main__icon" />}
        <ReactPhoneNumberInput
          data-cy="phone-number"
          value={value}
          onChange={onChange}
          international
          defaultCountry="FR"
        />
      </div>

      {error && <span className="phone-input__error">{error}</span>}
    </div>
  );
}
