import { gql } from '@apollo/client';

export const PROFILE_COMPLETION_USER_PARTS_FRAGMENT = gql`
  fragment ProfileCompletionUserParts on AmbivalentUser {
    id
    stepByStepCompletionStatus: completionFor(completionContext: $completionContext) {
      fieldsCompletion {
        name
        complete
        points
        disabled
      }
    }
    currentUserAuthorizations {
      canRequestModeration
      canRequestAdminRhModeration
    }

    showWelcomePanel
    isModeratable
    isAdminRhModeratable

    email

    firstName
    lastName

    primarySituation {
      key
    }

    secondarySituation {
      key
      name(locale: $locale)
    }

    availableGrades

    meetingPreferences {
      key
      name(locale: $locale)
      description(locale: $locale)
      value
    }

    wantToShare

    presentationTranslations {
      aspirationsQuestion(locale: $locale)
      expertisesQuestion(locale: $locale)
      interestsQuestion(locale: $locale)
      memberPresentationQuestion(locale: $locale)
      ambassadorPresentationQuestion(locale: $locale)

      aspirationsExample(locale: $locale)
      expertisesExample(locale: $locale)
      interestsExample(locale: $locale)
      memberPresentationExample(locale: $locale)
      ambassadorPresentationExample(locale: $locale)
    }

    requestProfileModificationUpdate

    headTeacher
    teachedSchoolSubjects
    accompaniedStudyLevels {
      key
      name(locale: $locale)
    }

    jobSeekerSupportOrganizationType
    jobSeekerSupportPrograms
    vocationCoachSignup
    isCoach
    canAcceptGroupConversation
    acceptGroupConversations
    companyName
  }
`;

export const PROFILE_COMPLETION_USER_PARTS_WITH_CONTEXT_FRAGMENT = gql`
  fragment ProfileCompletionUserPartsWithContext on AmbivalentUser {
    id
    stepByStepCompletionStatus: completionFor(completionContext: $completionContext) {
      complete
      fieldsCompletion {
        name
        complete
        points
        disabled
      }
    }
    currentUserAuthorizations {
      canRequestModeration
      canRequestAdminRhModeration
    }

    showWelcomePanel
    isModeratable
    isAdminRhModeratable

    email

    firstName
    lastName

    primarySituation {
      key
    }

    secondarySituation {
      key
      name(locale: $locale)
    }

    availableGrades

    meetingPreferences {
      key
      name(locale: $locale)
      description(locale: $locale)
      value
    }

    wantToShare

    presentationTranslations {
      aspirationsQuestion(locale: $locale)
      expertisesQuestion(locale: $locale)
      interestsQuestion(locale: $locale)
      memberPresentationQuestion(locale: $locale)
      ambassadorPresentationQuestion(locale: $locale)

      aspirationsExample(locale: $locale)
      expertisesExample(locale: $locale)
      interestsExample(locale: $locale)
      memberPresentationExample(locale: $locale)
      ambassadorPresentationExample(locale: $locale)
    }

    requestProfileModificationUpdate

    headTeacher
    teachedSchoolSubjects
    accompaniedStudyLevels {
      key
      name(locale: $locale)
    }

    jobSeekerSupportOrganizationType
    jobSeekerSupportPrograms
    vocationCoachSignup
    isCoach
    canAcceptGroupConversation
    acceptGroupConversations
    companyName
  }
`;

export const GET_USER_COMPLETION_QUERY = gql`
  ${PROFILE_COMPLETION_USER_PARTS_WITH_CONTEXT_FRAGMENT}
  query GET_USER_COMPLETION($locale: AvailableLanguagesEnum, $completionContext: CompletionContextEnum!) {
    currentUserV2 {
      ...ProfileCompletionUserPartsWithContext
    }
  }
`;
