import { MouseEvent, useCallback } from 'react';
import './CounterInput.scss';
import classNames from 'classnames';
import MinusIcon from 'assets/component-icons/MinusIcon';
import PlusIcon from 'assets/component-icons/PlusIcon';

type CounterInputProps = {
  name?: string;
  label?: string;
  value: number;
  min?: number;
  max?: number;
  onChange: (value: number) => void;
  error?: string;
};

export default function CounterInput({ name, label, value, onChange, error, min, max }: CounterInputProps) {
  const add = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (max && value >= max) return;
      onChange(value + 1);
    },
    [value, onChange],
  );

  const minus = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (min && value <= min) return;
      onChange(value - 1);
    },
    [value, onChange],
  );

  return (
    <div className="counter-input">
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error: error })}
        >
          {label}
        </label>
      )}

      <div
        className={classNames('counter-input__main', {
          'counter-input__main--error': error,
        })}
      >
        <button
          className="counter-input__main__control"
          onClick={minus}
        >
          <MinusIcon />
        </button>

        <div className="counter-input__main__content">{value}</div>

        <button
          className="counter-input__main__control"
          onClick={add}
        >
          <PlusIcon />
        </button>
      </div>

      {error && <span className="counter-input__error">{error}</span>}
    </div>
  );
}
