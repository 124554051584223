import ArrowLeftIcon from 'assets/component-icons/ArrowLeftIcon';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import CalendarIcon from 'assets/component-icons/CalendarIcon';
import LoadingIcon from 'assets/component-icons/LoadingIcon';
import MailIcon from 'assets/component-icons/MailIcon';
import ButtonsGroup from 'components/buttons-group/ButtonsGroup';
import CheckboxInput from 'components/checkbox-input/CheckboxInput';
import formikError from 'components/formik-helper/formik.helper';
import Layout, { LayoutHeader } from 'components/layout/Layout';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import SelectInput from 'components/select-input/SelectInput';
import TextInput from 'components/text-input/TextInput';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import { useFormik } from 'formik';
import { CommonInputFormTranslations, CommonTranslations } from 'i18n/common.translations';
import { ReactNode, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import Routes from 'Routes';
import useAuth from 'scenes/authentication/Auth.hook';
import { ComesFromEnum } from '../../@types/graphql';
import useFormikNetworkState from '../../hooks/use-formik-network-state';
import { ConfirmSignupTranslations } from './ConfirmSignup.translations';
import { signupValidationSchema } from './ConfirmSignup.validation';
import useEmailFromInvitationToken from './hooks/useEmailFromInvitationToken.hook';
import HrAdminInvitationSignup from './components/hr-admin-invitation-signup/HrAdminInvitationSignup';

type LocationState = {
  email: string;
  nonce?: string;
};

type FormikFields = {
  email: string;
  birthdate: string;
  cgu: boolean;
  comesFrom?: ComesFromEnum;
  vocationCoachSignup: boolean;
};

type SignupProps = {
  vocationCoachSignup?: boolean;
};

export default function Signup({ vocationCoachSignup }: SignupProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const settingsContext = useContext(SettingsContext);

  const location = useLocation() as Location<LocationState>;
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const auth = useAuth();

  const networkState = useFormikNetworkState(ArrowRightIcon);

  const invitationToken = searchParams.get('invitation_token') || undefined;

  const [emailFromInvitation, { loading: emailFromInvitationLoading }] = useEmailFromInvitationToken({
    invitationToken,
  });
  const emailFromParams = location.state?.email || searchParams.get('email');

  const email = emailFromInvitation || emailFromParams;
  const { flow } = useParams();
  if (flow === 'COMPANY_ADMIN') return <HrAdminInvitationSignup invitationToken={invitationToken}/>;

  if (vocationCoachSignup === undefined) {
    vocationCoachSignup = flow === 'SCHOOL_COACH';
  }

  const formik = useFormik<FormikFields>({
    enableReinitialize: true,
    initialValues: {
      email: email || '',
      birthdate: '',
      comesFrom: undefined,
      cgu: false,
      vocationCoachSignup: vocationCoachSignup || false,
    },
    validationSchema: signupValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async ({ email, birthdate, vocationCoachSignup }) => {
      // TODO if nonce get all states and remove them
      networkState.loading();
      await auth({
        email,
        birthdate: new Date(birthdate),
        comesFrom: formik.values.comesFrom,
        nonce: location.state?.nonce,
        highSchoolTeacher: !!searchParams.get('high_school_teacher') || undefined,
        invitationToken,
        companyRegistrationToken: localStorage.getItem('company_registration_token') || undefined,
        vocationCoachSignup,
      });
      networkState.succeeded();
    },
  });

  if (!emailFromParams && !emailFromInvitation && !emailFromInvitationLoading && flow !== 'SCHOOL_COACH') {
    navigate(Routes.home);
  }

  return (
    <Layout withAsideImage>
      <LayoutHeader>
        <h1>{intl.formatMessage(ConfirmSignupTranslations.title)}</h1>
        <p>{intl.formatMessage(ConfirmSignupTranslations.subtitle)}</p>
      </LayoutHeader>

      <main>
        <form onSubmit={formik.handleSubmit}>
          <TextInput
            icon={emailFromInvitationLoading ? LoadingIcon : MailIcon}
            name="email"
            label={intl.formatMessage(CommonInputFormTranslations.email)}
            value={formik.values.email}
            error={formikError(formik.touched, formik.errors, 'email')}
            disabled
          />

          <TextInput
            dataCy="birthdate"
            icon={CalendarIcon}
            label={intl.formatMessage(CommonInputFormTranslations.birthdate)}
            name="birthdate"
            type="date"
            value={formik.values.birthdate}
            onChange={formik.handleChange}
            error={formikError(formik.touched, formik.errors, 'birthdate')}
          />

          <SelectInput
            dataCy="comes-from"
            name="comesFrom"
            label={intl.formatMessage(CommonInputFormTranslations.comesFromLabel)}
            values={(settingsContext?.settings?.comesFrom || []).map(({ key, name }) => ({
              value: key,
              translation: name,
            }))}
            onChange={(comesFrom) => formik.setFieldValue('comesFrom', comesFrom)}
            error={formikError(formik.touched, formik.errors, 'comesFrom')}
          />

          <CheckboxInput
            name="cgu"
            label={intl.formatMessage<ReactNode>(CommonInputFormTranslations.cgu, {
              cguLink: (text) => (
                <a
                  href="https://www.myjobglasses.com/conditions-generales-dutilisation"
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </a>
              ),
              cguLinkMinor: (text) => (
                <a
                  href="https://assets.myjobglasses.com/cgu/2024-04-confidentialite-mineurs-numeriques.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </a>
              ),
              privacyLink: (text) => (
                <a
                  href="https://www.myjobglasses.com/confidentialite-communaute-connect"
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </a>
              ),
            })}
            checked={formik.values.cgu}
            onChange={formik.handleChange}
            error={formikError(formik.touched, formik.errors, 'cgu')}
          />

          <ButtonsGroup>
            <SecondaryButton
              leftIcon={ArrowLeftIcon}
              onClick={() => window.history.back()}
            />

            <PrimaryButton
              label={intl.formatMessage(CommonTranslations.continue)}
              icon={networkState.iconBasedOnNetworkState}
              accent
              submit
              state={networkState.state}
              disabled={!formik.dirty}
            />
          </ButtonsGroup>
        </form>
      </main>
    </Layout>
  );
}
