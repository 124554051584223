import { useIntl } from 'react-intl';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { WelcomePanelTranslations } from './panels.translations';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';

export default function WelcomePanel() {
  const intl = useIntl();

  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  return (
    <form>
      <PrimaryButton
        label={intl.formatMessage(WelcomePanelTranslations.submitButton)}
        onClick={async () => {
          networkState.loading();
          await updateUser({ showWelcomePanel: false });
        }}
        icon={networkState.iconBasedOnNetworkState}
      />
    </form>
  );
}
