const MARKETPLACE_REDIRECT_URL_SEARCH_PARAM = 'marketplace_redirect_url';
const MARKETPLACE_REDIRECT_URL_LS_KEY = 'marketplace_redirect_url';

function saveMarketplaceRedirection() {
  const url = new URL(window.location.href);
  const marketplaceRedirectURL = url.searchParams.get(MARKETPLACE_REDIRECT_URL_SEARCH_PARAM);
  if (marketplaceRedirectURL) {
    localStorage.setItem(MARKETPLACE_REDIRECT_URL_LS_KEY, marketplaceRedirectURL);
  }
}

function redirectToMarketplace() {
  const marketplaceRedirectURL = localStorage.getItem(MARKETPLACE_REDIRECT_URL_LS_KEY);
  if (marketplaceRedirectURL) {
    return marketplaceRedirectURL;
  }
  return window.__RUNTIME_CONFIG__.REACT_APP_APP_FRONTEND_URL || 'http://localhost:4001';
}

export default function useMarketplaceRedirect() {
  return {
    setRedirectIfNeeded: () => saveMarketplaceRedirection(),
    getRedirectUrl: () => redirectToMarketplace(),
  };
}
