import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import TextAreaInput from 'components/text-area-input/TextAreaInput';
import { useFormik } from 'formik';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { CommonTranslations } from 'i18n/common.translations';
import { useIntl } from 'react-intl';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';
import { expertisePresentationValidationSchema } from './panels.validation';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';

type FormikValues = {
  expertisePresentation: string;
};

export default function ExpertisePresentationPanel() {
  const intl = useIntl();

  const { user } = useProfileCompletionContext();
  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikValues>({
    initialValues: {
      expertisePresentation: '',
    },
    validationSchema: expertisePresentationValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async ({ expertisePresentation }) => {
      networkState.loading();
      await updateUser({ expertisePresentation });
      networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextAreaInput
        dataCy="expertise-presentation"
        name="expertisePresentation"
        label={user?.presentationTranslations.expertisesQuestion}
        value={formik.values.expertisePresentation}
        onChange={formik.handleChange}
        info={user?.presentationTranslations.expertisesExample}
      />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        submit
        disabled={!formik.isValid}
      />
    </form>
  );
}
