import { ComponentType, useEffect, useState } from 'react';
import MJGAnimatedPinIcon from 'assets/MJGAnimatedPinIcon';
import { IconProps } from 'assets/component-icons/Icon';

import './ProfileCompletionBar.scss';
import classNames from 'classnames';
import { motion } from 'framer-motion';

type ProfileCompletionBarProps = {
  value: number;
  icon: ComponentType<IconProps>;
};

const TOTAL_DURATION = 1000;

export default function ProfileCompletionBar({ value, icon: Icon }: ProfileCompletionBarProps) {
  const [currentValue, setCurrentValue] = useState(0);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const diff = value - currentValue;
    const sleep = TOTAL_DURATION / diff;
    setAnimate(true);
    for (let i = 0; i < diff; i += 1) {
      setTimeout(() => {
        setCurrentValue((v) => v + 1);
        if (i === diff - 1) {
          setAnimate(false);
        }
      }, i * sleep);
    }
  }, [value]);

  return (
    <motion.div
      className="profile-completion-bar"
      initial={{ opacity: 0, transform: 'translate3d(80%, 0, 0)' }}
      animate={{ opacity: 1, transform: 'translate3d(0, 0, 0)' }}
      exit={{ opacity: 0, transform: 'translate3d(-80%, 0, 0)' }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      <div className="profile-completion-bar__progress">
        <div
          className={classNames('profile-completion-bar__progress__pin', {
            'profile-completion-bar__progress__pin--animate': animate,
          })}
          style={{ left: `${value}%` }}
        >
          <MJGAnimatedPinIcon animate={animate} />
          <Icon className="profile-completion-bar__progress__pin__icon" />
        </div>
        <div
          className="profile-completion-bar__progress__completion"
          style={{ width: `${value}%` }}
        />
      </div>
      <div className="profile-completion-bar__percentage">{currentValue}%</div>
    </motion.div>
  );
}
