import { useLazyQuery, useMutation } from '@apollo/client';
import Layout, { LayoutHeader } from 'components/layout/Layout';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Routes from 'Routes';
import {
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityQueryVariables,
  OauthMutation,
  OauthMutationVariables,
  OauthProviderEnum,
} from '../../../@types/graphql';
import { CHECK_EMAIL_AVAILABILITY_QUERY } from '../gql/CheckEmailAvailability.gql';
import { OAUTH_MUTATION } from './gql/OAuth.gql';

export default function FranceTravailOAuthCallback() {
  const location = useLocation();
  const navigate = useNavigate();

  const [checkEmailAvailability] = useLazyQuery<CheckEmailAvailabilityQuery, CheckEmailAvailabilityQueryVariables>(
    CHECK_EMAIL_AVAILABILITY_QUERY,
  );
  const [oauthFranceTravail] = useMutation<OauthMutation, OauthMutationVariables>(OAUTH_MUTATION, {
    onCompleted: async ({ oauth }) => {
      if (oauth) {
        const { email, firstName, lastName, nonce } = oauth;
        const emailAvailability = await checkEmailAvailability({
          variables: { email },
        });

        if (emailAvailability.data?.checkEmailAvailability.available) {
          localStorage.setItem(email, JSON.stringify({ firstName, lastName }));
          navigate(Routes.signup, { state: { email, nonce } });
        } else {
          navigate(Routes.home, { state: { email } });
          window.location.reload();
        }
      }
    },
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    oauthFranceTravail({
      variables: {
        authorizationCode: searchParams.get('code')!,
        provider: OauthProviderEnum.FranceTravail,
        redirectUri: `${window.location.origin}${window.location.pathname}`,
      },
    });
  }, [location]);

  return (
    <Layout>
      <LayoutHeader>
        <h1>Nous interrogeons les services de FranceTravail ...</h1>
      </LayoutHeader>
    </Layout>
  );
}
