import { useFormik } from 'formik';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import { useIntl } from 'react-intl';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { birthdateValidationSchema } from './panels.validation';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { CommonTranslations } from 'i18n/common.translations';
import TextInput from 'components/text-input/TextInput';
import CalendarIcon from 'assets/component-icons/CalendarIcon';
import formikError from 'components/formik-helper/formik.helper';
import { BirthdatePanelTranslations } from './panels.translations';
import useFormikNetworkState from 'hooks/use-formik-network-state';

type FormikValues = {
  birthdate: string;
};

export default function BirthdatePanel() {
  const intl = useIntl();

  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikValues>({
    initialValues: {
      birthdate: '',
    },
    validationSchema: birthdateValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async (values) => {
      networkState.loading();
      await updateUser(values);
      networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextInput
        icon={CalendarIcon}
        label={intl.formatMessage(BirthdatePanelTranslations.birthdate)}
        name="birthdate"
        type="date"
        value={formik.values.birthdate}
        onChange={formik.handleChange}
        error={formikError(formik.touched, formik.errors, 'birthdate')}
      />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        submit
        disabled={!formik.dirty}
      />
    </form>
  );
}
