import * as Yup from 'yup';
import { IntlShape } from 'react-intl';
import { AuthenticationErrorTranslations } from './Authentication.translations';

export default (intl: IntlShape) =>
  Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage(AuthenticationErrorTranslations.emailInvalid))
      .required(intl.formatMessage(AuthenticationErrorTranslations.emailRequired)),
    password: Yup.string().required(intl.formatMessage(AuthenticationErrorTranslations.putPassword)),
  });
