import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CompanyAlreadyCustomer.title',
    defaultMessage: 'Votre entreprise est déjà partenaire',
  },
  description: {
    id: 'CompanyAlreadyCustomer.description',
    defaultMessage:
      "Contactez l'administrateur du compte pour rejoindre le programme Ambassadeur de votre entreprise : <mailTo>{email}</mailTo>",
  },
});
