import { gql } from '@apollo/client';

export const ADMIN_RH_INVITATION_SIGN_UP_MUTATION = gql`
  mutation AdminHrInvitationSignup(
    $email: String!
    $phone: String
    $birthdate: ISO8601Date
    $password: String!
    $jobTitle: String
    $invitationToken: String
  ) {
    signup(
      email: $email
      phone: $phone
      birthdate: $birthdate
      password: $password
      jobTitle: $jobTitle
      invitationToken: $invitationToken
    ) {
      email
      companyAdminProfiles {
        company {
          id
        }
      }
      schoolAdminProfiles {
        company {
          id
        }
      }
    }
  }
`;
