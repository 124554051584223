import { defineMessages } from 'react-intl';

export const SidebarTranslations = defineMessages({
  preview: {
    id: 'SidebarTranslations.preview',
    defaultMessage: 'Prévisualiser',
  },
  requestModeration: {
    id: 'SidebarTranslations.requestModeration',
    defaultMessage: 'Demander la publication',
  },
  unrequestPublication: {
    id: 'SidebarTranslations.unrequestPublication',
    defaultMessage: 'Se dépublier',
  },
  ongoingModeration: {
    id: 'SidebarTranslations.ongoingModeration',
    defaultMessage: 'Profil en cours de modération',
  },
  uncompletedProfile: {
    id: 'SidebarTranslations.uncompletedProfile',
    defaultMessage: 'Veuillez compléter vos informations',
  },
});
