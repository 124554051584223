import Appsignal from '@appsignal/javascript';

import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator';
import { onError } from '@apollo/client/link/error';

type BreadcrumbCategory = 'Network' | 'UI';
type ErrorNamespace = 'GraphQL' | 'Frontend';
type GraphQLQueryType = 'query' | 'mutation';

const ANONYMIZED_FIELDS = [
  'password',
  'email',
  'name',
  'phone',
  'gender',
  'presentation',
  'address',
  'birthdate',
  'meeting_place',
  'meetingplace',
];

class ErrorTracker {
  private readonly tracker: Appsignal;
  private _userId?: string;

  constructor() {
    this.tracker = new Appsignal({
      key: window.__RUNTIME_CONFIG__.REACT_APP_APPSIGNAL_ACCOUNT_FRONTEND_MONITORING_KEY,
      revision: window.__RUNTIME_CONFIG__.REACT_APP_CODE_VERSION,
    });
    this.usePlugins();
  }

  public set userId(userId: string) {
    this._userId = userId;
  }

  public getNetworkErrorHandler() {
    return onError(({ graphQLErrors, operation }) => {
      // Use a timeout to let breadcrumbs addition operate
      setTimeout(() => {
        const operationType = (operation.query.definitions[0] as any).operation || 'undefined';

        graphQLErrors?.forEach((graphQLError) => {
          const name = `[${operation.operationName}] ${graphQLError.message}`;
          const desc = JSON.stringify(graphQLError.extensions);
          this.sendError(name, desc, {
            namespace: 'GraphQL',
            payload: operation.variables,
            tags: { type: operationType },
          });
        });
      }, 500);
    });
  }

  public getBoundaryErrorHandler() {
    return (error: Error) => {
      // Use a timeout to let breadcrumbs addition operate
      setTimeout(() => {
        const name = `[${error.name}] ${error.message}`;
        const desc = error.stack || '';

        const tags: { [key: string]: string } = {};
        if (error.cause) tags.cause = JSON.stringify(error.cause);

        this.sendError(name, desc, {
          namespace: 'Frontend',
          tags,
        });
      }, 500);
    };
  }

  public addNetworkBreadcrumb(message: string, type: GraphQLQueryType) {
    this.addBreadcrumb('Network', message, { queryType: type });
  }

  public addUIBreadcrumb(message: string) {
    this.addBreadcrumb('UI', message);
  }

  private usePlugins() {
    this.tracker.use(pluginPathDecorator());
  }

  private addBreadcrumb(category: BreadcrumbCategory, message: string, data?: { [key: string]: string }) {
    this.tracker.addBreadcrumb({
      category,
      action: message,
      metadata: data,
    });
  }

  public sendError(
    name: string,
    description: string,
    opts: {
      namespace: ErrorNamespace;
      payload?: object;
      tags?: { [key: string]: string };
    },
  ) {
    const error = new Error(description);
    error.name = name;

    const tags = opts.tags || {};
    if (opts.payload) {
      this.anonymizePayload(opts.payload);
      tags.payload = JSON.stringify(opts.payload);
    }

    if (this._userId) tags.userId = this._userId;

    try {
      this.tracker.sendError(error, (sp) => {
        sp.setNamespace(opts.namespace);
        sp.setTags(tags);
      });
    } catch (e) {
      console.error(e);
    }
  }

  private anonymizePayload(object: any) {
    Object.keys(object).forEach((key) => {
      if (ANONYMIZED_FIELDS.some((field) => key.toLowerCase().includes(field.toLowerCase()))) {
        object[key] = '[ANONYMIZED]';
      }
    });
  }
}

export const errorTracker = new ErrorTracker();
