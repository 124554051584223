	import { defineMessages } from 'react-intl';

export const StickyDescriptionOfferTranslations = defineMessages({
	title: {
		id: 'StickyDescriptionOfferTranslations.title',
		defaultMessage: "Offre Découverte",
	},
	subtitleInclude: {
		id: 'StickyDescriptionOfferTranslations.subtitleInclude',
		defaultMessage: 'Inclus',
	},
	subtitleNotInclude: {
			id: 'StickyDescriptionOfferTranslations.subtitleNotInclude',
			defaultMessage: 'Non inclus',
	},
	password: {
		id: 'StickyDescriptionOfferTranslations.password',
		defaultMessage: 'Mot de passe',
	},
	passwordConfirmation: {
		id: 'StickyDescriptionOfferTranslations.passwordConfirmation',
		defaultMessage: 'Confirmez votre mot de passe',
	},
	gender: {
		id: 'StickyDescriptionOfferTranslations.gender',
		defaultMessage: 'Genre',
	},
	genderPlaceholder: {
		id: 'StickyDescriptionOfferTranslations.genderPlaceholder',
		defaultMessage: 'Sélectionnez votre genre',
	},
	firstName: {
		id: 'StickyDescriptionOfferTranslations.firstName',
		defaultMessage: 'Prénom',
	},
	firstNamePlaceholder: {
		id: 'StickyDescriptionOfferTranslations.firstNamePlaceholder',
		defaultMessage: 'Gabriel',
	},
	lastName: {
		id: 'StickyDescriptionOfferTranslations.lastName',
		defaultMessage: 'Nom',
	},
	lastNamePlaceholder: {
		id: 'StickyDescriptionOfferTranslations.lastNamePlaceholder',
		defaultMessage: 'Martin',
	},
	job: {
		id: 'StickyDescriptionOfferTranslations.job',
		defaultMessage: 'Poste actuel',
	},
	jobPlaceholder: {
		id: 'StickyDescriptionOfferTranslations.jobPlaceholder',
		defaultMessage: 'Ingénieur',
	},
	companyName: {
		id: 'StickyDescriptionOfferTranslations.companyName',
		defaultMessage: "Nom de l'entreprise",
	},
	companyNamePlaceholder: {
		id: 'StickyDescriptionOfferTranslations.companyNamePlaceholder',
		defaultMessage: 'My Job Glasses',
	},
	companySector: {
		id: 'StickyDescriptionOfferTranslations.companySector',
		defaultMessage: 'Secteur d\'activité de l\'entreprise',
	},
	companySize: {
		id: 'StickyDescriptionOfferTranslations.companySize',
		defaultMessage: 'Taille de l\'entreprise',
	},
	createAccount: {
		id: 'StickyDescriptionOfferTranslations.createAccount',
		defaultMessage: 'Créer mon compte',
	},
	cgu: {
		id: 'StickyDescriptionOfferTranslations.cgu',
		defaultMessage: "J'ai lu et j'accepte les <cguLink>Conditions Générales d'Utilisation</cguLink>, et la <privacyLink>Politique de Protection des Données Personnelles</privacyLink>.",
	},
});