import { useIntl } from 'react-intl';
import { appleAuthHelpers } from 'react-apple-signin-auth';
import IconAppleConnect from './icon-apple-connect.svg';
import { AuthenticationTranslations } from 'scenes/authentication/Authentication.translations';
import styles from './AppleButton.module.scss';

export default function AppleButton(props: any) {
  const intl = useIntl();

  return (
    <button
      onClick={props.onClick}
      className={styles.AppleButton}
      type="button"
    >
      <img
        src={IconAppleConnect}
        alt="Ic&ocirc;ne Apple"
        width="25"
        height="31"
      />
      <span
        data-testid="Apple-button-text"
        className={styles.text}
      >
        {intl.formatMessage(AuthenticationTranslations.apple)}
      </span>
    </button>
  );
}
