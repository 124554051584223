import { defineMessages } from 'react-intl';

export const PasswordInputTranslations = defineMessages({
  needHelp: {
    id: 'PasswordInput.needHelp',
    defaultMessage: "Besoin d'aide ?",
  },
  forgottenPassword: {
    id: 'PasswordInput.forgottenPassword',
    defaultMessage: 'Mot de passe oublié ?',
  },
});
