import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { useIntl } from 'react-intl';
import { studentPanelValidationSchema } from './panels.validation';
import { CommonTranslations } from 'i18n/common.translations';
import useUserContextUpdate from 'contexts/user/UserContextUpdate.hook';
import { UserContext } from 'contexts/user/UserContext';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import RefreshIcon from 'assets/component-icons/RefreshIcon';
import SelectInput from 'components/select-input/SelectInput';
import {
  GetDepartmentsQuery,
  GetDepartmentsQueryVariables,
  GetGouvSchoolsQuery,
  GetGouvSchoolsQueryVariables,
  GradeEnum,
  PrimarySituationEnum,
  SecondarySituation,
  SecondarySituationEnum,
} from '../../../@types/graphql';
import { CurrentEducationPanelTranslations } from './panels.translations';
import GraduationHatIcon from 'assets/component-icons/GraduationHatIcon';
import { GradeEnumTranslations } from 'i18n/grade.translations';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import formikError from 'components/formik-helper/formik.helper';
import { InputValue } from 'components/types/BaseInputValue';
import DynamicAutocompleteSelectInput from 'components/dynamic-autocomplete-select-input/DynamicAutocompleteSelectInput';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_DEPARTMENTS_QUERY, GET_GOUV_SCHOOLS } from 'gqls/Schools.gql';
import DynamicAutocompleteCategorizedSelectInput from 'components/dynamic-autocomplete-categorized-select-input/DynamicAutocompleteCategorizedSelectInput';
import RadioInput from 'components/radio-input/RadioInput';
import { BinaryResponse } from 'models/BinaryResponse.enum';
import { BinaryResponseTranslations } from 'i18n/binary-response.translations';
import useDebounce from 'hooks/use-debounce';
import TextInput from 'components/text-input/TextInput';
import useModal from 'components/modal/Modal.hook';
import GouvSchoolCreateModal from 'scenes/profile-completion/components/gouv-school-create-modal/GouvSchoolCreateModal';
import { GouvSchoolCreateModalTranslations } from 'scenes/profile-completion/components/gouv-school-create-modal/GouvSchoolCreateModal.translations';

type FormikValues = {
  primarySituation: PrimarySituationEnum;
  secondarySituation?: SecondarySituationEnum;
  currentGrade?: GradeEnum | null;
  currentSchoolDepartment: string;
  currentSchoolName: string;
  currentSchoolCity: string;
  apprentice?: BinaryResponse;
  preparedDiplomaTitle?: string;
};

export default function StudentSituationPanel({
  primarySituationProps,
}: {
  primarySituationProps: PrimarySituationEnum;
}) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const { user } = useContext(UserContext);
  const updateUser = useUserContextUpdate();
  const settingsContext = useContext(SettingsContext);
  const [openModal, closeModal] = useModal();

  const networkState = useFormikNetworkState();

  const [departementSearchValue, setDepartementSearchValue] = useState('');
  const [schoolSearchValue, setSchoolSearchValue] = useState('');
  const [debouncedSchoolSearchValue, isSchoolSearchDebouncing] = useDebounce(schoolSearchValue);
  const [availableGrades, setAvailableGrades] = useState<GradeEnum[]>(user?.availableGrades || []);
  const initialPrimarySituationRef = useRef(primarySituationProps);

  const situations: SecondarySituation[] = useMemo(() => {
    if (!settingsContext?.settings) return [];

    const primarySituation = settingsContext.settings.primarySituations.find(
      ({ key }) => key === primarySituationProps,
    );

    if (!primarySituation || !primarySituation.secondarySituations) return [];

    return primarySituation.secondarySituations;
  }, [settingsContext?.settings?.primarySituations, user?.primarySituation?.key]);

  const [initialValues, setInitialValues] = useState<FormikValues>({
    primarySituation: primarySituationProps,
    secondarySituation: user?.secondarySituation?.key,
    currentGrade: user?.currentGrade,
    currentSchoolDepartment: user?.currentSchoolDepartment || '',
    currentSchoolName: user?.currentSchoolName || '',
    currentSchoolCity: user?.currentSchoolCity || '',
    preparedDiplomaTitle: user?.preparedDiplomaTitle || '',
  });

  useEffect(() => {
    if (primarySituationProps !== initialPrimarySituationRef.current) {
      setInitialValues({
        primarySituation: primarySituationProps,
        secondarySituation: undefined,
        currentGrade: null,
        currentSchoolDepartment: '',
        currentSchoolName: '',
        currentSchoolCity: '',
        preparedDiplomaTitle: '',
      });
    }
  }, [primarySituationProps]);

  const handleCreateSchool = (newSchool: { name: string; communeName: string }) => {
    formik.setFieldValue('currentSchoolName', newSchool.name);
    formik.setFieldValue('currentSchoolCity', newSchool.communeName);
    formik.setSubmitting(false);
    closeModal();
  };

  const formik = useFormik<FormikValues>({
    initialValues: initialValues,
    validationSchema: studentPanelValidationSchema(intl),
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      networkState.loading();
      const { apprentice, ...rest } = values;
      const response = await updateUser({
        ...rest,
        apprentice: apprentice === BinaryResponse.Yes,
      });
      response.errors ? networkState.error() : networkState.succeeded();
    },
  });

  const updateSecondarySituation = useCallback(
    (secondarySituation: SecondarySituationEnum) => {
      const selectedSecondarySituation = situations.find((situation) => situation.key === secondarySituation);
      setAvailableGrades(selectedSecondarySituation?.grades || []);
      formik.setFieldValue('secondarySituation', secondarySituation);
      formik.initialValues.currentGrade = null;
      formik.setFieldValue('currentGrade', null);
      formik.setFieldValue('currentSchoolDepartment', '');
      formik.initialValues.currentSchoolName = '';
      formik.setFieldValue('currentSchoolName', '');
      formik.setFieldValue('preparedDiplomaTitle', '');
      formik.validateForm();
    },
    [situations],
  );

  const onDepartmentChange = (department: string) => {
    formik.setFieldValue('currentSchoolDepartment', department);
    formik.setFieldValue('currentSchoolName', '');
  };

  const isForeignDepartment = !/\d/.test(formik.values.currentSchoolDepartment);
  const shouldShowSchoolEmptyStateMessageForeignDepartment =
    isForeignDepartment && debouncedSchoolSearchValue.length >= 1;
  const shouldShowSchoolEmptyStateMessage =
    shouldShowSchoolEmptyStateMessageForeignDepartment || debouncedSchoolSearchValue.length >= 5;

  const { data: departmentsData, loading: departmentsLoading } = useQuery<
    GetDepartmentsQuery,
    GetDepartmentsQueryVariables
  >(GET_DEPARTMENTS_QUERY, {
    variables: {
      search: departementSearchValue,
    },
  });

  const openCreateSchoolModal = () => {
    openModal({
      title: formatMessage(GouvSchoolCreateModalTranslations.title),
      content: (
        <GouvSchoolCreateModal
          onClose={closeModal}
          onSuccess={handleCreateSchool}
          establishmentType={formik.values.secondarySituation}
          departmentLabel={formik.values.currentSchoolDepartment}
          userId={user?.id}
        />
      ),
    });
  };

  const [loadGouvSchools, { data: schoolData, loading: gouvSchoolLoading }] = useLazyQuery<
    GetGouvSchoolsQuery,
    GetGouvSchoolsQueryVariables
  >(GET_GOUV_SCHOOLS);

  useEffect(() => {
    loadGouvSchools({
      variables: {
        department: formik.values.currentSchoolDepartment,
        establishmentType: formik.values.secondarySituation,
        term: debouncedSchoolSearchValue,
      },
    });
  }, [formik.values.currentSchoolDepartment, debouncedSchoolSearchValue]);

  return (
    <>
      <SelectInput
        name="secondarySituation"
        label={intl.formatMessage(CurrentEducationPanelTranslations.secondarySituation)}
        icon={GraduationHatIcon}
        values={situations.map(({ key, name }) => ({
          value: key,
          translation: name,
        }))}
        initialValue={
          user?.secondarySituation
            ? ({
                value: user!.secondarySituation!.key,
                translation: user!.secondarySituation!.name,
              } as InputValue<SecondarySituationEnum>)
            : undefined
        }
        onChange={updateSecondarySituation}
        error={formikError(formik.touched, formik.errors, 'secondarySituation')}
      />
      {availableGrades.length > 0 && (
        <>
          <SelectInput
            key={formik.values.secondarySituation}
            name="currentGrade"
            label={intl.formatMessage(CurrentEducationPanelTranslations.currentGradeLabel)}
            icon={GraduationHatIcon}
            initialValue={
              formik.initialValues.currentGrade &&
              ({
                value: formik.initialValues.currentGrade,
                translation: intl.formatMessage(GradeEnumTranslations[formik.initialValues.currentGrade]),
              } as InputValue<GradeEnum>)
            }
            values={availableGrades.map((value) => ({
              value,
              translation: intl.formatMessage(GradeEnumTranslations[value]),
            }))}
            onChange={(grade) => formik.setFieldValue('currentGrade', grade)}
            error={formikError(formik.touched, formik.errors, 'currentGrade')}
          />

          {formik.values.secondarySituation !== SecondarySituationEnum.College &&
            formik.values.secondarySituation !== SecondarySituationEnum.LyceeGt && (
              <TextInput
                name="preparedDiplomaTitle"
                label={formatMessage(CurrentEducationPanelTranslations.diplomaLabel)}
                icon={GraduationHatIcon}
                value={formik.values.preparedDiplomaTitle}
                initialValue={formik.initialValues.preparedDiplomaTitle || ''}
                onChange={formik.handleChange}
                error={formikError(formik.touched, formik.errors, 'preparedDiplomaTitle')}
              />
            )}

          <DynamicAutocompleteSelectInput
            name="currentSchoolDepartment"
            label={intl.formatMessage(CurrentEducationPanelTranslations.departmentLabel)}
            values={(departmentsData?.departments || []).map((department) => ({
              value: department,
              translation: department,
            }))}
            value={formik.values.currentSchoolDepartment}
            onSelection={onDepartmentChange}
            onTyping={setDepartementSearchValue}
            loading={departmentsLoading}
            error={formikError(formik.touched, formik.errors, 'currentSchoolDepartment')}
          />
        </>
      )}

      <DynamicAutocompleteCategorizedSelectInput
        key={formik.values.secondarySituation}
        name="currentSchoolName"
        label={intl.formatMessage(CurrentEducationPanelTranslations.currentSchoolNameLabel)}
        placeholder={intl.formatMessage(CurrentEducationPanelTranslations.currentSchoolNamePlaceholder)}
        values={(schoolData?.gouvSchoolSearch || []).map((value) => ({
          value: value,
          categoryKey: value.communeName!,
          translation: value.name!,
        }))}
        initialValue={formik.initialValues.currentSchoolName || ''}
        onSelection={(value) => {
          formik.setFieldValue('currentSchoolName', value.name);
          formik.setFieldValue('currentSchoolCity', value.communeName);
        }}
        onTyping={setSchoolSearchValue}
        loading={isSchoolSearchDebouncing(gouvSchoolLoading)}
        error={formikError(formik.touched, formik.errors, 'currentSchoolName')}
        emptyStateMessage={
          shouldShowSchoolEmptyStateMessage
            ? intl.formatMessage(CurrentEducationPanelTranslations.emptySchoolNameMessage)
            : ''
        }
        onEmptyStateClick={openCreateSchoolModal}
        disabled={formik.values.currentSchoolDepartment === ''}
      />

      {formik.values.secondarySituation && formik.values.secondarySituation !== SecondarySituationEnum.College && (
        <div>
          <RadioInput
            name="apprentice"
            values={Object.values(BinaryResponse).map((resp) => ({
              value: resp,
              translation: intl.formatMessage(BinaryResponseTranslations[resp]),
            }))}
            initialSelectedIndex={user?.apprentice === true ? 0 : 1}
            onSelection={(value) => formik.setFieldValue('apprentice', value)}
            label={intl.formatMessage(CurrentEducationPanelTranslations.labelInputApprentice)}
            inline={true}
          />
        </div>
      )}

      <footer>
        <PrimaryButton
          label={intl.formatMessage(CommonTranslations.save)}
          icon={networkState.iconBasedOnNetworkState}
          onClick={formik.handleSubmit}
          accent
          leftIcon
          state={networkState.state}
          disabled={!formik.dirty}
        />
        <SecondaryButton
          label={intl.formatMessage(CommonTranslations.reset)}
          leftIcon={RefreshIcon}
          onClick={() => formik.resetForm()}
          iconButtonWithText
        />
      </footer>
    </>
  );
}
