import { defineMessages } from 'react-intl';

export const ShareButtonTranslations = defineMessages({
  title: {
    id: 'profile.sidebar.shareButton.title',
    defaultMessage: 'Partager mon expérience',
  },
  content: {
    id: 'profile.sidebar.shareButton.content',
    defaultMessage: `Activez l’option ci dessous pour partager <br />
    votre expérience et rejoindre notre <br /> 
    communauté d’Ambassadeurs`,
  },
  toggleText: {
    id: 'profile.sidebar.shareButton.toggleText',
    defaultMessage: `Je souhaite devenir Ambassadeur`,
  },
});
