import { useContext, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import PhoneIcon from 'assets/component-icons/PhoneIcon';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { phonePanelValidationSchema } from './panels.validation';
import PhoneInput from '../../../components/phone-input/PhoneInput';
import { PhonePanelTranslations } from './panels.translations';
import formikError from '../../../components/formik-helper/formik.helper';
import { CommonTranslations } from 'i18n/common.translations';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import RefreshIcon from 'assets/component-icons/RefreshIcon';
import useUserContextUpdate from 'contexts/user/UserContextUpdate.hook';
import { UserContext } from 'contexts/user/UserContext';
import useFormikNetworkState from 'hooks/use-formik-network-state';

type FormikValues = {
  phoneNumber: string;
};

export default function PhonePanel() {
  const intl = useIntl();

  const { user } = useContext(UserContext);
  const updateUser = useUserContextUpdate();

  const networkState = useFormikNetworkState();

  const initialPhoneNumber = useMemo(() => {
    if (!user?.phoneCountryCode || !user?.phoneWithoutCountryCode) return '';
    return `+${user.phoneCountryCode}${user.phoneWithoutCountryCode}`;
  }, [user?.phoneCountryCode, user?.phoneWithoutCountryCode]);

  const formik = useFormik<FormikValues>({
    initialValues: {
      phoneNumber: initialPhoneNumber,
    },
    validationSchema: phonePanelValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async ({ phoneNumber }) => {
      networkState.loading();
      const response = await updateUser({ normalizedPhone: phoneNumber });
      response.errors ? networkState.error() : networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <PhoneInput
        icon={PhoneIcon}
        name="phoneNumber"
        label={intl.formatMessage(PhonePanelTranslations.phone)}
        value={formik.values.phoneNumber}
        onChange={(phoneNumber) => {
          formik.setFieldValue('phoneNumber', phoneNumber);
        }}
        error={formikError(formik.touched, formik.errors, 'phoneNumber')}
      />

      <footer>
        <PrimaryButton
          label={intl.formatMessage(CommonTranslations.save)}
          icon={networkState.iconBasedOnNetworkState}
          submit
          accent
          leftIcon
          state={networkState.state}
          disabled={!formik.dirty}
        />
        <SecondaryButton
          label={intl.formatMessage(CommonTranslations.reset)}
          leftIcon={RefreshIcon}
          onClick={() => {}}
          iconButtonWithText
        />
      </footer>
    </form>
  );
}
