import { useCallback, useContext, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import BriefcaseIcon from 'assets/component-icons/BriefcaseIcon';
import { useIntl } from 'react-intl';
import {
  JobSeekerSupportOrganizationType,
  JobSeekerSupportOrganizationTypeKeyEnum,
  JobSeekerSupportProgram,
  JobSeekerSupportProgramKeyEnum,
} from '../../../@types/graphql';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import SelectInput from '../../../components/select-input/SelectInput';
import { FranceTravailSupportSituationTranslations } from './panels.translations';
import { CommonTranslations } from 'i18n/common.translations';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import StaticAutocompleteMultiSelectInput from 'components/static-autocomplete-multi-select-input/StaticAutocompleteMultiSelectInput';
import AutocompleteAddableMultiSelectInput from 'components/autocomplete-addable-multi-select-input/AutocompleteAddableMultiSelectInput';
import RemovableTag from 'components/removable-tag/RemovableTag';
import './SchoolCoachSituationPanel.scss';
import TextInput from '../../../components/text-input/TextInput';
import formikError from 'components/formik-helper/formik.helper';
import { JobSeekerSupportProgramKeyEnumTranslations } from 'i18n/programs-ft.translations';
import { franceTravailSupportSituationPanelValidationSchema } from './panels.validation';

type FormikValues = {
  position: string;
  companyName?: string;
  jobSeekerSupportPrograms: string[];
  jobSeekerSupportOrganizationType: string;
};

export default function FranceTravailSupportSituationPanel() {
  const intl = useIntl();
  const { formatMessage } = intl;

  const updateUser = useProfileCompletionUserUpdate();
  const settingsContext = useContext(SettingsContext);

  const networkState = useFormikNetworkState(ArrowRightIcon);

  const [customProgram, setCustomProgram] = useState<string | null>(null);
  const [multiSelectPlaceholder, setMultiSelectPlacholder] = useState<string>('');

  const [customOrganizationTitle, setCustomOrganizationTitle] = useState<string | null>(null);

  const organizationTypes: JobSeekerSupportOrganizationType[] = useMemo(() => {
    if (!settingsContext?.settings) return [];
    return settingsContext.settings.jobSeekerSupportOrganizationTypes || [];
  }, [settingsContext?.settings?.jobSeekerSupportOrganizationTypes]);

  const programs: JobSeekerSupportProgram[] = useMemo(() => {
    if (!settingsContext?.settings) return [];
    return settingsContext.settings.jobSeekerSupportPrograms?.map((program) => program) || [];
  }, [settingsContext?.settings?.jobSeekerSupportPrograms]);

  const formik = useFormik<FormikValues>({
    initialValues: {
      position: '',
      companyName: '',
      jobSeekerSupportPrograms: [],
      jobSeekerSupportOrganizationType: '',
    },
    validationSchema: franceTravailSupportSituationPanelValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async (values) => {
      networkState.loading();
      const response = await updateUser(values);
      networkState.succeeded();
    },
  });

  const addCustomProgram = useCallback(
    (jobSeekerSupportPrograms: string[]) => {
      formik.setFieldValue('jobSeekerSupportPrograms', jobSeekerSupportPrograms);
    },
    [formik.values.jobSeekerSupportPrograms],
  );

  const addProgram = useCallback(
    (program: JobSeekerSupportProgramKeyEnum) => {
      if (program === JobSeekerSupportProgramKeyEnum.Other) {
        setCustomProgram('');
        setMultiSelectPlacholder(
          intl.formatMessage(JobSeekerSupportProgramKeyEnumTranslations[JobSeekerSupportProgramKeyEnum.Other]),
        );
      } else {
        if (!formik.values.jobSeekerSupportPrograms.includes(program)) {
          formik.setFieldValue('jobSeekerSupportPrograms', [...formik.values.jobSeekerSupportPrograms, program]);
        }
        setCustomProgram(null);
        setMultiSelectPlacholder('');
      }
    },
    [formik.values.jobSeekerSupportPrograms],
  );

  const formatError = (touched: any, errors: any, fieldName: string): string | undefined => {
    const error = formikError(touched, errors, fieldName);
    if (typeof error === 'string') {
      return error;
    } else if (error && typeof error === 'object') {
      return JSON.stringify(error);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <SelectInput
        dataCy="organization-type"
        name="jobSeekerSupportOrganizationType"
        label={intl.formatMessage(FranceTravailSupportSituationTranslations.organizationTypeLabel)}
        icon={BriefcaseIcon}
        values={organizationTypes.map(({ key, name }) => ({
          value: key,
          translation: name,
        }))}
        onChange={(organizationType) => {
          if (organizationType === JobSeekerSupportOrganizationTypeKeyEnum.Other) {
            setCustomOrganizationTitle('');
          } else {
            setCustomOrganizationTitle(null);
            formik.setFieldValue('jobSeekerSupportOrganizationType', organizationType);
          }
        }}
        error={formatError(formik.touched, formik.errors, 'jobSeekerSupportOrganizationType')}
      />

      {customOrganizationTitle !== null && (
        <TextInput
          name="jobSeekerSupportOrganizationType"
          label={intl.formatMessage(FranceTravailSupportSituationTranslations.labelOther)}
          value={customOrganizationTitle}
          onChange={(e) => {
            setCustomOrganizationTitle(e.target.value);
            formik.setFieldValue('jobSeekerSupportOrganizationType', e.target.value);
          }}
          error={formatError(formik.touched, formik.errors, 'jobSeekerSupportOrganizationType')}
        />
      )}

      <TextInput
        dataCy="position"
        name="position"
        value={formik.values.position}
        label={intl.formatMessage(FranceTravailSupportSituationTranslations.labelJobTitle)}
        onChange={(e) => {
          formik.setFieldValue('position', e.target.value);
        }}
        error={formatError(formik.touched, formik.errors, 'position')}
      />

      <TextInput
        dataCy="company-name"
        name="companyName"
        value={formik.values.companyName}
        label={intl.formatMessage(FranceTravailSupportSituationTranslations.labelCompanyName)}
        placeholder={intl.formatMessage(FranceTravailSupportSituationTranslations.placeholderCompanyName)}
        onChange={(e) => {
          formik.setFieldValue('companyName', e.target.value);
        }}
        error={formatError(formik.touched, formik.errors, 'companyName')}
      />

      <StaticAutocompleteMultiSelectInput
        dataCy="support-programs"
        name="jobSeekerSupportPrograms"
        label={intl.formatMessage(FranceTravailSupportSituationTranslations.labelPrograms)}
        values={programs.map((value) => ({
          value: value.key,
          translation: value.name,
        }))}
        placeholder={multiSelectPlaceholder}
        selectedValues={formik.values.jobSeekerSupportPrograms || []}
        onChange={(value: string) => addProgram(value as JobSeekerSupportProgramKeyEnum)}
        error={formatError(formik.touched, formik.errors, 'jobSeekerSupportPrograms')}
      />

      {customProgram !== null && (
        <AutocompleteAddableMultiSelectInput
          name="jobSeekerSupportPrograms"
          label={intl.formatMessage(FranceTravailSupportSituationTranslations.labelOther)}
          values={formik.values.jobSeekerSupportPrograms}
          selectedValues={formik.values.jobSeekerSupportPrograms}
          onSelection={addCustomProgram}
          onTyping={setCustomProgram}
        />
      )}

      <div className="coach-panel__tags">
        {formik.values.jobSeekerSupportPrograms.map((program) => {
          const isCustomProgram = !Object.values(JobSeekerSupportProgramKeyEnum).includes(
            program as JobSeekerSupportProgramKeyEnum,
          );
          return (
            <RemovableTag
              key={program}
              value={program}
              name={program}
              translation={
                !isCustomProgram
                  ? JobSeekerSupportProgramKeyEnumTranslations[
                      program as keyof typeof JobSeekerSupportProgramKeyEnumTranslations
                    ]
                  : undefined
              }
              onRemove={() =>
                formik.setFieldValue(
                  'jobSeekerSupportPrograms',
                  formik.values.jobSeekerSupportPrograms.filter((v) => v !== program),
                )
              }
            />
          );
        })}
      </div>

      <PrimaryButton
        label={formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        submit
        disabled={!formik.dirty || !formik.isValid}
      />
    </form>
  );
}
