import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { IntlShape } from 'react-intl';
import { AuthenticationErrorTranslations } from 'scenes/authentication/Authentication.translations';
import { CompanySector, GenderEnum, HrChallengeKeyEnum } from '../../../../@types/graphql';
import { CommonErrorInputFormTranslations } from 'i18n/common.translations';

YupPassword(Yup);
const { array, boolean, date, mixed, number, object, ref, string } = Yup;

const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

export default (intl: IntlShape) =>
	object().shape({
		email: string()
			.email(intl.formatMessage(AuthenticationErrorTranslations.emailInvalid))
			.required(intl.formatMessage(AuthenticationErrorTranslations.emailRequired)),
		password: string()
      .required(intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty))
      .min(8, intl.formatMessage(CommonErrorInputFormTranslations.inputTooShort))
      .minLowercase(1, intl.formatMessage(CommonErrorInputFormTranslations.passwordTooWeak))
      .minUppercase(1, intl.formatMessage(CommonErrorInputFormTranslations.passwordTooWeak))
      .minNumbers(1, intl.formatMessage(CommonErrorInputFormTranslations.passwordTooWeak))
      .minSymbols(1, intl.formatMessage(CommonErrorInputFormTranslations.passwordTooWeak)),
    confirmPassword: string()
      .required(intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty))
      .test('passwords-match', intl.formatMessage(CommonErrorInputFormTranslations.passwordMismatch), function (value) {
        return this.parent.password === value;
      }),
    gender: mixed<GenderEnum>()
      .oneOf(Object.values(GenderEnum), intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty))
      .required(intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty)),
    firstName: string()
			.required(intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty))
      .min(2, intl.formatMessage(CommonErrorInputFormTranslations.inputTooShort)),
    lastName: string()
			.required(intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty))
			.min(1, intl.formatMessage(CommonErrorInputFormTranslations.inputTooShort)),
    jobTitle: string()
			.required(intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty)),
    companyName: string()
			.required(intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty)),
    companySize: number()
			.required(intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty)),
    companySector: mixed<CompanySector>()
      .required(intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty)),
    hrChallenges: array()
      .of(string())
      .min(1, intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty))
      .required(intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty)),
    cgu: boolean()
      .oneOf([true], intl.formatMessage(AuthenticationErrorTranslations.mustAcceptCGU)),
		phoneNumber: string()
      .required(intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty))
      .matches(phoneRegExp, intl.formatMessage(CommonErrorInputFormTranslations.invalidPhoneNumber)),
  });

