import { PropsWithChildren, useState } from 'react';
import {
  AvailableLanguagesEnum,
  GetOtherUserQuery,
  GetOtherUserQueryVariables,
  GetUserIdOfProfessionalIdQuery,
  GetUserIdOfProfessionalIdQueryVariables,
  UserPartsFragment,
} from '../../@types/graphql';
import { useQuery } from '@apollo/client';
import { GET_OTHER_USER_QUERY } from './UserContext.gql';
import { useNavigate, useParams } from 'react-router-dom';
import Routes from 'Routes';
import { useLanguage } from 'hooks/use-languages/useLanguages';
import { GET_USER_ID_OF_PROFESSIONAL_ID_QUERY } from 'gqls/ProfessionalProfile.gql';
import { UserContext } from './UserContext';

export function AdminUserContextProvider({ children }: PropsWithChildren) {
  const params = useParams();
  const language = useLanguage();
  const navigate = useNavigate();

  const [user, setUser] = useState<UserPartsFragment>();

  const { data } = useQuery<GetUserIdOfProfessionalIdQuery, GetUserIdOfProfessionalIdQueryVariables>(
    GET_USER_ID_OF_PROFESSIONAL_ID_QUERY,
    {
      variables: {
        professionalId: params.id || '',
      },
    },
  );
  useQuery<GetOtherUserQuery, GetOtherUserQueryVariables>(GET_OTHER_USER_QUERY, {
    variables: {
      locale: language.language === 'fr' ? AvailableLanguagesEnum.Fr : AvailableLanguagesEnum.En,
      id: data?.professionalProfile?.userId || '',
    },
    onCompleted: (data) => {
      if (!data.getUser) {
        navigate(Routes.home);
        return;
      }
      setUser(data.getUser);
    },
  });

  function updateUser(result: UserPartsFragment) {
    setUser(result);
  }

  return (
    <UserContext.Provider
      value={{
        user,
        updateUser,
        admin: true,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
