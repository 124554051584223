import classNames from 'classnames';
import {
  Hint,
  containsAtLeast8Characters,
  containsAtLeast1UppercaseLetter,
  containsAtLeast1LowercaseLetter,
  containsAtLeast1Number,
  containsAtLeast1SpecialCharacter,
} from './StrengthHints.util';
import './StrengthHints.scss';
import { StrengthHintsTranslations } from './StrengthHints.translations';
import { useIntl } from 'react-intl';
import { motion } from 'framer-motion';

const HINTS: Hint[] = [
  {
    message: StrengthHintsTranslations.charactersLength,
    isValid: containsAtLeast8Characters,
  },
  {
    message: StrengthHintsTranslations.uppercase,
    isValid: containsAtLeast1UppercaseLetter,
  },
  {
    message: StrengthHintsTranslations.lowercase,
    isValid: containsAtLeast1LowercaseLetter,
  },
  {
    message: StrengthHintsTranslations.number,
    isValid: containsAtLeast1Number,
  },
  {
    message: StrengthHintsTranslations.specialCharacter,
    isValid: containsAtLeast1SpecialCharacter,
  },
];

type StrengthHintsProps = {
  password: string;
};

export default function StrengthHints({ password }: StrengthHintsProps) {
  const intl = useIntl();

  return (
    <motion.ul
      initial={{
        transform: 'translate3d(-32px, -50%, 0) scale(0.8)',
        opacity: 0,
      }}
      animate={{ transform: 'translate3d(0, -50%, 0) scale(1)', opacity: 1 }}
      exit={{ transform: 'translate3d(-32px, -50%, 0) scale(0.8)', opacity: 0 }}
      className={classNames('strength-hints')}
    >
      {HINTS.map(({ message, isValid }) => (
        <li
          key={message.id}
          className={classNames('strength-hints__hint', {
            'strength-hints__hint--valid': isValid(password),
          })}
        >
          {intl.formatMessage(message)}
        </li>
      ))}
    </motion.ul>
  );
}
