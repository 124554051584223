import { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { memberPresentationPanelValidationSchema } from './panels.validation';
import { CommonTranslations } from 'i18n/common.translations';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import RefreshIcon from 'assets/component-icons/RefreshIcon';
import useUserContextUpdate from 'contexts/user/UserContextUpdate.hook';
import { UserContext } from 'contexts/user/UserContext';
import TextAreaInput from 'components/text-area-input/TextAreaInput';
import useFormikNetworkState from 'hooks/use-formik-network-state';

type FormikValues = {
  memberPresentation: string;
};

export default function MemberPresentationPanel() {
  const intl = useIntl();

  const { user } = useContext(UserContext);
  const updateUser = useUserContextUpdate();

  const networkState = useFormikNetworkState();

  const formik = useFormik<FormikValues>({
    initialValues: {
      memberPresentation: user?.memberPresentation || '',
    },
    validationSchema: memberPresentationPanelValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async (values) => {
      networkState.loading();
      const response = await updateUser(values);
      response.errors ? networkState.error() : networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextAreaInput
        name="memberPresentation"
        label={user?.presentationTranslations.memberPresentationQuestion}
        value={formik.values.memberPresentation}
        onChange={formik.handleChange}
        info={user?.presentationTranslations.memberPresentationExample}
      />

      <footer>
        <PrimaryButton
          label={intl.formatMessage(CommonTranslations.save)}
          icon={networkState.iconBasedOnNetworkState}
          submit
          accent
          leftIcon
          state={networkState.state}
          disabled={!formik.dirty}
        />
        <SecondaryButton
          label={intl.formatMessage(CommonTranslations.reset)}
          leftIcon={RefreshIcon}
          onClick={() => {}}
          iconButtonWithText
        />
      </footer>
    </form>
  );
}
