import { createContext } from 'react';

interface PageLoaderContextValues {
  isLoading: boolean;
  setLoading: (value: boolean) => void;
}

export default createContext<PageLoaderContextValues>({
  isLoading: false,
  setLoading: () => null,
});
