import { gql } from '@apollo/client';

export const REQUEST_MODERATION_MUTATION = gql`
  mutation RequestModeration($userId: ID!) {
    requestModeration(userId: $userId)
  }
`;

export const UNPUBLISH_MUTATION = gql`
  mutation Unpublish($userId: ID!) {
    unpublish(userId: $userId)
  }
`;

export const PUBLISH_MUTATION = gql`
  mutation Publish($userId: ID!) {
    publish(userId: $userId)
  }
`;
