import ProfileLayout from './ProfileLayout';
import { UserContextProvider } from 'contexts/user/UserContext';
import { AdminUserContextProvider } from 'contexts/user/AdminUserContext';

type ProfileProps = {
  admin?: boolean;
};

export default function Profile({ admin }: ProfileProps) {
  if (admin) {
    return (
      <AdminUserContextProvider>
        <ProfileLayout />
      </AdminUserContextProvider>
    );
  }

  return (
    <UserContextProvider>
      <ProfileLayout />
    </UserContextProvider>
  );
}
