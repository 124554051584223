import { defineMessages } from 'react-intl';

export const CommonTranslations = defineMessages({
  continue: {
    id: 'CommonTranslations.continue',
    defaultMessage: 'Continuer',
  },
  reset: {
    id: 'CommonTranslations.reset',
    defaultMessage: 'Réinitialiser',
  },
  save: {
    id: 'CommonTranslations.save',
    defaultMessage: 'Sauvegarder',
  },
  confirm: {
    id: 'CommonTranslations.confirm',
    defaultMessage: 'Confirmer',
  },
  cancel: {
    id: 'CommonTranslations.cancel',
    defaultMessage: 'Annuler',
  },
  delete: {
    id: 'CommonTranslations.delete',
    defaultMessage: 'Supprimer',
  },
});

export const CommonErrorInputFormTranslations = defineMessages({
  inputEmpty: {
    id: 'CommonErrorInputForm.inputEmpty',
    defaultMessage: 'Ce champ est obligatoire',
  },
  inputTooShort: {
    id: 'CommonErrorInputForm.inputTooShort',
    defaultMessage: 'Ce champ est trop court',
  },
  passwordMismatch: {
    id: 'CommonErrorInputForm.passwordMismatch',
    defaultMessage: 'Les mots de passe ne correspondent pas',
  },
  passwordTooWeak: {
    id: 'CommonErrorInputForm.passwordTooWeak',
    defaultMessage: "Le mot de passe n'est pas assez fort",
  },
  invalidPhoneNumber: {
    id: 'CommonErrorInputForm.invalidPhoneNumber',
    defaultMessage: 'Format de numéro de téléphone invalide',
  },
});

export const CommonInputFormTranslations = defineMessages({
  emailPro: {
    id: 'CommonInputForm.emailPro',
    defaultMessage: 'Adresse email professionnelle',
  },
  email: {
    id: 'CommonInputForm.email',
    defaultMessage: 'Email',
  },
  phone: {
    id: 'CommonInputForm.phone',
    defaultMessage: 'Numéro de téléphone',
  },
  birthdate: {
    id: 'CommonInputForm.birthdate',
    defaultMessage: 'Date de naissance',
  },
  password: {
    id: 'CommonInputForm.password',
    defaultMessage: 'Mot de passe',
  },
  passwordConfirmation: {
    id: 'CommonInputForm.passwordConfirmation',
    defaultMessage: 'Confirmez votre mot de passe',
  },
  gender: {
    id: 'CommonInputForm.gender',
    defaultMessage: 'Genre',
  },
  genderPlaceholder: {
    id: 'CommonInputForm.genderPlaceholder',
    defaultMessage: 'Sélectionnez votre genre',
  },
  firstName: {
    id: 'CommonInputForm.firstName',
    defaultMessage: 'Prénom',
  },
  lastName: {
    id: 'CommonInputForm.lastName',
    defaultMessage: 'Nom',
  },
  job: {
    id: 'CommonInputForm.job',
    defaultMessage: 'Poste actuel',
  },
  companyName: {
    id: 'CommonInputForm.companyName',
    defaultMessage: "Nom de l'entreprise",
  },
  companySector: {
    id: 'CommonInputForm.companySector',
    defaultMessage: "Secteur d'activité de l'entreprise",
  },
  companySize: {
    id: 'CommonInputForm.companySize',
    defaultMessage: "Taille de l'entreprise",
  },
  hrChallenge: {
    id: 'CommonInputForm.hrChallenge',
    defaultMessage: 'Vos problématiques RH (plusieurs choix possibles)',
  },
  createAccount: {
    id: 'CommonInputForm.createAccount',
    defaultMessage: 'Créer mon compte',
  },
  cgu: {
    id: 'CommonInputForm.cgu',
    defaultMessage:
      "J'ai lu et j'accepte les <cguLink>Conditions Générales d'Utilisation</cguLink>, et la <privacyLink>Politique de Protection des Données Personnelles</privacyLink>.",
  },
  companyLogoLabel: {
    id: 'CommonInputForm.companyLogoLabel',
    defaultMessage: 'Le logo de votre entreprise ',
  },
  comesFromLabel: {
    id: 'ConfirmSignup.comesFromLabel',
    defaultMessage: 'Comment nous avez-vous connus ?',
  },
});
