import CheckIcon from 'assets/component-icons/CheckIcon';
import ChevronDownIcon from 'assets/component-icons/ChevronDownIcon';
import { IconProps } from 'assets/component-icons/Icon';
import classNames from 'classnames';
import { ComponentType, useMemo, useState } from 'react';
import { InputValue, sortByTranslation } from '../types/BaseInputValue';
import './SelectInput.scss';

type SelectInputProps<T> = {
  icon?: ComponentType<IconProps>;
  name: string;
  label?: string;
  values: InputValue<T>[];
  initialValue?: InputValue<T> | null;
  placeholder?: string;
  onChange: (value: T) => void;
  error?: string;
  sort?: boolean;
  disabled?: boolean;
  dataCy?: string;
};

export default function SelectInput<T extends string>({
  icon: Icon,
  name,
  label,
  placeholder,
  error,
  values: paramValues,
  sort,
  disabled,
  onChange,
  initialValue,
  dataCy,
}: SelectInputProps<T>) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<InputValue<T> | undefined | null>(initialValue);

  const values: InputValue<T>[] = useMemo(
    () => (sort ? sortByTranslation(paramValues) : paramValues),
    [sort, paramValues],
  );

  function handleComponentClick() {
    if (disabled) {
      return;
    }

    setDropdownOpen((prev) => {
      const mirrorValue = !prev;

      return mirrorValue;
    });
  }

  function handleSelection(value: InputValue<T>) {
    onChange(value.value);
    setSelectedValue(value);
    setDropdownOpen(false);
  }

  return (
    <div className="select-input">
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error: error })}
        >
          {label}
        </label>
      )}

      <div
        data-cy={dataCy}
        className={classNames(
          'select-input__main',
          { 'select-input__main--focused': isDropdownOpen },
          { 'select-input__main--disabled': disabled },
          { 'select-input__main--error': error },
        )}
        onClick={handleComponentClick}
      >
        {Icon && <Icon className="select-input__main__icon" />}

        {selectedValue ? <div>{selectedValue.translation}</div> : <div className="placeholder">{placeholder}</div>}

        <label htmlFor={name}>
          <ChevronDownIcon
            className={classNames('select-input__main__icon', {
              'select-input__main__icon--turn': isDropdownOpen,
            })}
          />
        </label>
      </div>

      {isDropdownOpen && (
        <ul className="select-input__dropdown" data-cy={dataCy && `${dataCy}__dropdown`}>
          {(sort ? sortByTranslation(values) : values).map((value) => (
            <li
              key={value.value}
              className={classNames('select-input__dropdown__option', {
                'select-input__dropdown__option--selected': value.value === selectedValue?.value,
              })}
            >
              <button
                data-cy={dataCy && `${dataCy}__item`}
                type="button"
                onClick={() => handleSelection(value)}
              >
                <CheckIcon />
                {value.translation}
              </button>
            </li>
          ))}
        </ul>
      )}

      {error && <span className="select-input__error">{error}</span>}
    </div>
  );
}
