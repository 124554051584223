import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ConfirmNewPassword.title',
    defaultMessage: 'Mot de passe oublié ?',
  },
  description: {
    id: 'ConfirmNewPassword.description',
    defaultMessage:
      "Nous avons envoyé un lien à l'adresse mail suivante : <boldThis>{email}</boldThis> <breakline></breakline><breakline></breakline> Cliquez sur le lien contenu dans l'email afin de réinitialiser votre mot de passe.",
  },
  resendEmailButton: {
    id: 'ConfirmNewPassword.resendEmailButton',
    defaultMessage: 'Envoyer un autre email',
  },
  confirmation: {
    id: 'ConfirmNewPassword.confirmation',
    defaultMessage: 'Lien envoyé !',
  },
});
