import { ChangeEvent, ComponentType, useEffect, useMemo, useRef, useState } from 'react';
import './AutocompleteAddableMultiSelectInput.scss';
import { IconProps } from 'assets/component-icons/Icon';
import classNames from 'classnames';
import ChevronDownIcon from 'assets/component-icons/ChevronDownIcon';
import CheckIcon from 'assets/component-icons/CheckIcon';
import PlusIcon from 'assets/component-icons/PlusIcon';
import LoadingIcon from 'assets/component-icons/LoadingIcon';

type AutocompleteAddableMultiSelectInputProps = {
  icon?: ComponentType<IconProps>;
  name: string;
  label?: string;
  values: string[];
  placeholder?: string;
  selectedValues?: string[];
  onSelection: (selectedValues: string[]) => void;
  onTyping: (value: string) => void;
  error?: string;
  loading?: boolean;
  dataCy?: string;
};

export default function AutocompleteAddableMultiSelectInput({
  icon: Icon,
  name,
  label,
  placeholder,
  error,
  values,
  onSelection,
  onTyping,
  selectedValues = [],
  loading,
  dataCy,
}: AutocompleteAddableMultiSelectInputProps) {
  const componentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [autocompleteValue, setAutocompleteValue] = useState('');

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleComponentClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleTyping = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setAutocompleteValue(newValue);
    onTyping(newValue);
    setDropdownOpen(true);
  };

  const handleSelection = (value: string) => {
    const updatedValues = selectedValues.includes(value)
      ? selectedValues.filter((item) => item !== value)
      : [...selectedValues, value];
    onSelection(updatedValues);
  };

  useEffect(() => {
    const handleClickOutside = (ev: MouseEvent) => {
      if (componentRef.current && !componentRef.current.contains(ev.target as Node)) {
        setDropdownOpen(false);
      }
    };

    const handleKeydown = (ev: KeyboardEvent) => {
      if (isDropdownOpen) {
        switch (ev.key) {
          case 'Escape':
            ev.preventDefault();
            setDropdownOpen(false);
            break;
          case 'Enter':
            ev.preventDefault();
            if (autocompleteValue.length > 2) {
              handleSelection(autocompleteValue);
              setDropdownOpen(false);
              setAutocompleteValue('');
            }
            break;
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [isDropdownOpen, values, autocompleteValue]);

  return (
    <div
      ref={componentRef}
      className="select-input"
    >
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error: error })}
        >
          {label}
        </label>
      )}

      <div
        data-cy={dataCy}
        className={classNames(
          'select-input__main',
          { 'select-input__main--focused': isDropdownOpen },
          { 'select-input__main--error': error },
        )}
        onClick={handleComponentClick}
      >
        {Icon && <Icon className="select-input__main__icon" />}

        <input
          data-cy={dataCy && `${dataCy}__input`}
          ref={inputRef}
          value={autocompleteValue}
          onChange={handleTyping}
          placeholder={placeholder}
        />

        <label htmlFor={name}>
          {loading ? (
            <LoadingIcon className="select-input__main__icon" />
          ) : (
            <ChevronDownIcon
              className={classNames('select-input__main__icon', {
                'select-input__main__icon--turn': isDropdownOpen,
              })}
            />
          )}
        </label>
      </div>

      {isDropdownOpen && (
        <ul className="select-input__dropdown">
          <li
            className={classNames(
              'select-input__dropdown__add-option',
              'autocomplete-addable-select-input__dropdown__option--typed-value',
            )}
          >
            <button
              type="button"
              onClick={() => autocompleteValue.length > 2 && handleSelection(autocompleteValue)}
            >
              <PlusIcon />
              {autocompleteValue}
            </button>
          </li>
          {values.map((value) => (
            <li
              data-cy={dataCy && `${dataCy}__item`}
              key={value}
              className={classNames('select-input__dropdown__option', {
                'select-input__dropdown__option--selected': selectedValues.includes(value),
              })}
            >
              <button
                type="button"
                onClick={() => handleSelection(value)}
              >
                <CheckIcon />
                {value}
              </button>
            </li>
          ))}
        </ul>
      )}

      {error && <span className="select-input__error">{error}</span>}
    </div>
  );
}
