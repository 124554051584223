import { useMutation } from '@apollo/client';
import { useSnackbar } from '@myjobglasses/account-ui';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useMatch } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { Request_New_PasswordMutation, Request_New_PasswordMutationVariables } from '../../../@types/graphql';
import { REQUEST_NEW_PASSWORD_ERRORS } from '../../../@types/graphql-errors';
import Routes from '../../../Routes';
import { handleGraphqlErrorsOnFormik } from '../../../utils/formik';
import PageLoaderContext from '../../../contexts/pageLoader';
import { beforeMutation } from '../../../hooks/use-submit-mutation-wrapper/useSubmitMutationWrapper';
import { errorTranslations } from '../pages/request-new-password/RequestNewPassword.translations';
import confirmNewPasswordTranslations from '../pages/confirm-new-password/ConfirmNewPassword.translations';
import { REQUEST_NEW_PASSWORD } from '../gql/RequestNewPassword.gql';
import { ErrorsTranslations } from 'i18n/errors.translations';

interface FormikFields {
  email: string;
}
export function useRequestNewPassword() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { success } = useSnackbar();
  const isRequestNewPassword = useMatch({
    path: Routes.requestNewPassword,
  });
  const { setLoading } = useContext(PageLoaderContext);

  const [requestNewPassword, { loading }] = useMutation<
    Request_New_PasswordMutation,
    Request_New_PasswordMutationVariables
  >(REQUEST_NEW_PASSWORD);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return function onSubmit(values: FormikFields, formikBag: FormikHelpers<FormikFields>) {
    return beforeMutation<FormikFields>(
      formikBag,
      requestNewPassword({
        variables: {
          email: values.email.toLowerCase(),
        },
      })
        .then(() => {
          if (isRequestNewPassword) {
            navigate(Routes.confirmNewPassword, {
              state: {
                email: values.email.toLowerCase(),
              },
            });
          } else {
            success(intl.formatMessage(confirmNewPasswordTranslations.confirmation), {
              icon: 'check_circle',
            });
          }
        })
        .catch((err) =>
          handleGraphqlErrorsOnFormik<FormikFields, Request_New_PasswordMutationVariables, REQUEST_NEW_PASSWORD_ERRORS>(
            err,
            formikBag,
            {
              translations: {
                default: intl.formatMessage(ErrorsTranslations.default),
                network: intl.formatMessage(ErrorsTranslations.network),
                unknown: intl.formatMessage(ErrorsTranslations.technical),
                global: {
                  [REQUEST_NEW_PASSWORD_ERRORS.NOT_FOUND]: intl.formatMessage(errorTranslations.NOT_FOUND),
                },
              },
            },
          ),
        ),
    );
  };
}
