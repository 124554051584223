import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

export enum MJGGraphQLErrorCode {
  'BAD_USER_INPUT' = 'BAD_USER_INPUT',
}

export interface MJGValidationError<GraphQLInputFields extends { [key: string]: any }, ERRORS extends string | number>
  extends GraphQLError {
  extensions: {
    code: MJGGraphQLErrorCode.BAD_USER_INPUT;
    validationErrors: {
      [fieldName in keyof GraphQLInputFields]: ERRORS[];
    };
  };
}

export interface MJGClassicError<ERRORS extends string | number> extends GraphQLError {
  extensions: {
    code: Exclude<ERRORS, MJGGraphQLErrorCode.BAD_USER_INPUT>;
  };
}

export interface MJGApolloError<GraphQLInputFields extends { [key: string]: any }, ERRORS extends string | number>
  extends ApolloError {
  graphQLErrors: Array<MJGValidationError<GraphQLInputFields, ERRORS> | MJGClassicError<ERRORS>>;
}

export enum CHECK_INVITATION_TOKEN_ERRORS {
  INVITATION_TOKEN_ALREADY_CLAIMED = 'INVITATION_TOKEN_ALREADY_CLAIMED',
  INVITATION_TOKEN_INVALID = 'INVITATION_TOKEN_INVALID',
}

export enum SIGNUP_ERRORS {
  COMPANY_IS_CLIENT = 'COMPANY_IS_CLIENT',
  INVITATION_TOKEN_ALREADY_CLAIMED = 'INVITATION_TOKEN_ALREADY_CLAIMED',
  USER_PASSWORD_FORMAT_INVALID = 'USER_PASSWORD_FORMAT_INVALID',
  EMAIL_TAKEN = 'EMAIL_TAKEN',
  USER_EMAIL_FORBIDDEN_EMAIL = 'USER_EMAIL_FORBIDDEN_EMAIL',
  MUST_PROVIDE_TUTOR_EMAIL = 'MUST_PROVIDE_TUTOR_EMAIL',
  EMAIL_AND_TUTOR_EMAIL_MUST_BE_DIFFERENT = 'EMAIL_AND_TUTOR_EMAIL_MUST_BE_DIFFERENT',
  TOO_YOUNG_TO_SINGUP_ERROR = 'TOO_YOUNG_TO_SINGUP_ERROR',
}

export enum REQUEST_NEW_PASSWORD_ERRORS {
  NOT_FOUND = 'NOT_FOUND',
}

export enum NEW_PASSWORD_ERRORS {
  EXPIRED_RESET_PASSWORD_TOKEN = 'EXPIRED_RESET_PASSWORD_TOKEN',
  USER_PASSWORD_FORMAT_INVALID = 'USER_PASSWORD_FORMAT_INVALID',
}

export enum SIGNIN_ERRORS {
  NOT_FOUND = 'NOT_FOUND',
  USER_SOFT_DELETED = 'USER_SOFT_DELETED',
  USER_ACCOUNT_RESTRICTED = 'USER_ACCOUNT_RESTRICTED',
  USER_MUST_CONFIRM_EMAIL = 'USER_MUST_CONFIRM_EMAIL',
  TUTOR_EMAIL_UNCONFIRMED = 'TUTOR_EMAIL_UNCONFIRMED',
}

export enum SIGNIN_FROM_LINKEDIN_ERRORS {
  NOT_FOUND = 'NOT_FOUND',
  OAUTH_EMAIL_ALREADY_USED = 'OAUTH_EMAIL_ALREADY_USED',
  USER_SOFT_DELETED = 'USER_SOFT_DELETED',
}

export enum SIGNUP_FROM_LINKEDIN_ERRORS {
  EMAIL_ALREADY_USED = 'EMAIL_ALREADY_USED',
  IDENTITY_NOT_FOUND = 'IDENTITY_NOT_FOUND',
  IDENTITY_ALREADY_LINKED_TO_ANOTHER_ACCOUNT = 'IDENTITY_ALREADY_LINKED_TO_ANOTHER_ACCOUNT',
  EMAIL_BELONGS_TO_SOFT_DELETED_USER = 'EMAIL_BELONGS_TO_SOFT_DELETED_USER',
  COMPANY_IS_CLIENT = 'COMPANY_IS_CLIENT',
}

export enum CURRENT_USER_ERRORS {
  NOT_FOUND = 'NOT_FOUND',
  USER_MUST_CONFIRM_EMAIL = 'USER_MUST_CONFIRM_EMAIL',
}

export enum SIGNOUT_ERRORS {
  SIGNOUT_UNAUTHORIZED = 'SIGNOUT_UNAUTHORIZED',
}

export enum CONFIRM_EMAIL_ERRORS {
  ALREADY_CONFIRMED = 'ALREADY_CONFIRMED',
  NOT_FOUND = 'NOT_FOUND',
}
