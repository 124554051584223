import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { errorTranslations } from './RequestNewPasswordForm.translations';

export default (intl: IntlShape) =>
  Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage(errorTranslations.emailRequired))
      .ensure()
      .required(intl.formatMessage(errorTranslations.emailRequired)),
  });
