import { ComponentType } from 'react';
import './SelectBlock.scss';
import classNames from 'classnames';
import { IconProps } from 'assets/component-icons/Icon';

type SelectBlockProps = {
  id?: string;
  icon?: ComponentType<IconProps>;
  title: string;
  description?: string;
  selected?: boolean;
  onClick: () => void;
};

export default function SelectBlock({ id, icon: Icon, title, description, selected, onClick }: SelectBlockProps) {
  return (
    <button
      data-cy={`select-block-${id}`}
      type="button"
      className={classNames('select-block', {
        'select-block--selected': selected,
      })}
      onClick={onClick}
    >
      <div
        className={classNames('select-block__title', {
          'select-block__title--selected': selected,
        })}
      >
        {Icon && <Icon className="select-block__title__icon" />}
        {title}
      </div>
      <p className="select-block__description">{description}</p>
    </button>
  );
}
