import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import LoadingIcon from 'assets/component-icons/LoadingIcon';
import MailIcon from 'assets/component-icons/MailIcon';
import Layout, { LayoutHeader } from 'components/layout/Layout';
import TextInput from 'components/text-input/TextInput';
import { useIntl } from 'react-intl';
import { ConfirmSignupTranslations } from 'scenes/confirm-signup/ConfirmSignup.translations';
import { ADMIN_RH_INVITATION_SIGN_UP_MUTATION } from './HrAdminInvitationSignup.gql';
import {
  AdminHrInvitationSignupMutation,
  AdminHrInvitationSignupMutationVariables,
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityQueryVariables,
  SignupErrorEnum,
} from '../../../../@types/graphql';
import useEmailFromInvitationToken from 'scenes/confirm-signup/hooks/useEmailFromInvitationToken.hook';
import CalendarIcon from 'assets/component-icons/CalendarIcon';
import formikError from 'components/formik-helper/formik.helper';
import PasswordInput from 'components/password-input/PasswordInput';
import LockIcon from 'assets/component-icons/LockIcon';
import PhoneInput from 'components/phone-input/PhoneInput';
import PhoneIcon from 'assets/component-icons/PhoneIcon';
import CheckboxInput from 'components/checkbox-input/CheckboxInput';
import { ReactNode, useMemo } from 'react';
import ButtonsGroup from 'components/buttons-group/ButtonsGroup';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import {
  CommonErrorInputFormTranslations,
  CommonInputFormTranslations,
  CommonTranslations,
} from 'i18n/common.translations';
import { useFormik } from 'formik';
import { CHECK_EMAIL_AVAILABILITY_QUERY } from 'scenes/authentication/gql/CheckEmailAvailability.gql';
import { SignupErrorTranslations } from 'scenes/signup/Signup.translations';
import { useSnackbar } from '@myjobglasses/account-ui';
import { AuthenticationErrorTranslations } from 'scenes/authentication/Authentication.translations';
import validationSchema from './HrAdminInvitationSignup.validations';
import { useLocation, useNavigate } from 'react-router-dom';
import { goToHRAdminConnectWithId, goToSchoolConnectWithId } from 'utils/redirections';
import Routes from 'Routes';

type HrAdminInvitationSignupProps = {
  invitationToken?: string;
};

type FormikFields = {
  email: string;
  phoneNumber: string;
  birthdate: string;
  password: string;
  confirmPassword: string;
  jobTitle: string;
  cgu: boolean;
};

export default function HrAdminInvitationSignup({ invitationToken }: HrAdminInvitationSignupProps) {
  const intl = useIntl();
  const snackbar = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [emailFromInvitation, { loading: emailFromInvitationLoading }] = useEmailFromInvitationToken({
    invitationToken,
  });
  const emailFromParams = location.state?.email || searchParams.get('email');

  const email = emailFromInvitation || emailFromParams;

  const [adminRhInvitationSignup] = useMutation<
    AdminHrInvitationSignupMutation,
    AdminHrInvitationSignupMutationVariables
  >(ADMIN_RH_INVITATION_SIGN_UP_MUTATION);

  const [checkEmailAvailability] = useLazyQuery<CheckEmailAvailabilityQuery, CheckEmailAvailabilityQueryVariables>(
    CHECK_EMAIL_AVAILABILITY_QUERY,
  );

  const formik = useFormik<FormikFields>({
    enableReinitialize: true,
    initialValues: {
      email: email,
      phoneNumber: '',
      birthdate: '',
      password: '',
      confirmPassword: '',
      jobTitle: '',
      cgu: false,
    },
    validationSchema: validationSchema(intl),
    validateOnMount: true,
    onSubmit: async (values) => {
      const { email, phoneNumber, birthdate, password, jobTitle } = values;

      const checkEmail = await checkEmailAvailability({
        variables: {
          email,
        },
      });

      if (!checkEmail.data?.checkEmailAvailability?.available) {
        snackbar.error(intl.formatMessage(SignupErrorTranslations.emailTaken));
      } else {
        try {
          const response = await adminRhInvitationSignup({
            variables: {
              email,
              phone: phoneNumber,
              birthdate,
              password,
              jobTitle,
              invitationToken,
            },
          });
          if (response.data?.signup?.companyAdminProfiles?.length) {
            goToHRAdminConnectWithId({ companyId: response.data.signup.companyAdminProfiles[0].company.id })
          }
          if (response.data?.signup?.schoolAdminProfiles?.length) {
            goToSchoolConnectWithId({ schoolId: response.data.signup.schoolAdminProfiles[0].company.id })
          }
        } catch (error) {
          const errorCodes = (error as ApolloError).graphQLErrors.map(({ extensions }) => extensions?.code);
          const emailState = { state: { email } };

          switch (true) {
            case errorCodes.includes(SignupErrorEnum.EmailInvalid):
              snackbar.error(intl.formatMessage(AuthenticationErrorTranslations.emailInvalid));
              break;
            case errorCodes.includes(SignupErrorEnum.EmailForbiddenDomain):
              snackbar.error(intl.formatMessage(AuthenticationErrorTranslations.emailForbiddenDomain));
              break;
            case errorCodes.includes(SignupErrorEnum.EmailUnconfirmed):
              navigate(Routes.mustConfirmEmail, emailState);
              break;
            default:
              break;
          }
        }
      }
    },
  });
  return (
    <Layout withAsideImage>
      <LayoutHeader>
        <h1>{intl.formatMessage(ConfirmSignupTranslations.title)}</h1>
        <p>{intl.formatMessage(ConfirmSignupTranslations.subtitle)}</p>
      </LayoutHeader>

      <main>
        <form onSubmit={formik.handleSubmit}>
          <TextInput
            icon={emailFromInvitationLoading ? LoadingIcon : MailIcon}
            name="email"
            label={intl.formatMessage(CommonInputFormTranslations.email)}
            value={formik.values.email}
            error={formikError(formik.touched, formik.errors, 'email') as string}
            disabled
          />

          <PhoneInput
            icon={PhoneIcon}
            name="phoneNumber"
            label={intl.formatMessage(CommonInputFormTranslations.phone)}
            value={formik.values.phoneNumber}
            onChange={(phoneNumber) => {
              formik.setFieldValue('phoneNumber', phoneNumber);
            }}
            error={formikError(formik.touched, formik.errors, 'phoneNumber') as string}
          />

          <TextInput
            dataCy="birthdate"
            icon={CalendarIcon}
            label={intl.formatMessage(CommonInputFormTranslations.birthdate)}
            name="birthdate"
            type="date"
            value={formik.values.birthdate}
            onChange={formik.handleChange}
            error={formikError(formik.touched, formik.errors, 'birthdate') as string}
          />

          <PasswordInput
            dataCy="password"
            strengthHints
            name="password"
            label={intl.formatMessage(CommonInputFormTranslations.password)}
            placeholder="••••••••••••"
            icon={LockIcon}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formikError(formik.touched, formik.errors, 'password') as string}
          />

          <PasswordInput
            dataCy="confirm-password"
            name="confirmPassword"
            label={intl.formatMessage(CommonInputFormTranslations.passwordConfirmation)}
            placeholder="••••••••••••"
            icon={LockIcon}
            value={formik.values.confirmPassword}
            confirm
            passwordToConfirm={formik.values.password}
            onChange={formik.handleChange}
            error={formikError(formik.touched, formik.errors, 'confirmPassword') as string}
          />

          <TextInput
            dataCy="job-title"
            name="jobTitle"
            label={intl.formatMessage(CommonInputFormTranslations.job)}
            value={formik.values.jobTitle}
            onChange={formik.handleChange}
            error={formikError(formik.touched, formik.errors, 'jobTitle') as string}
          />

          <CheckboxInput
            name="cgu"
            label={intl.formatMessage<ReactNode>(CommonInputFormTranslations.cgu, {
              cguLink: (text) => (
                <a
                  href="https://www.myjobglasses.com/conditions-generales-dutilisation"
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </a>
              ),
              cguLinkMinor: (text) => (
                <a
                  href="https://assets.myjobglasses.com/cgu/2024-04-confidentialite-mineurs-numeriques.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </a>
              ),
              privacyLink: (text) => (
                <a
                  href="https://www.myjobglasses.com/confidentialite-communaute-connect"
                  target="_blank"
                  rel="noreferrer"
                >
                  {text}
                </a>
              ),
            })}
            checked={formik.values.cgu}
            onChange={formik.handleChange}
            error={formikError(formik.touched, formik.errors, 'cgu') as string}
          />

          <ButtonsGroup>
            <PrimaryButton
              label={intl.formatMessage(CommonTranslations.continue)}
              accent
              submit
              disabled={!formik.dirty}
            />
          </ButtonsGroup>
        </form>
      </main>
    </Layout>
  );
}
