import { ComponentType, useEffect, useRef, useState } from 'react';
import './AutocompleteAddableSelectInput.scss';
import { IconProps } from 'assets/component-icons/Icon';
import classNames from 'classnames';
import ChevronDownIcon from 'assets/component-icons/ChevronDownIcon';
import CheckIcon from 'assets/component-icons/CheckIcon';
import PlusIcon from 'assets/component-icons/PlusIcon';

type AutocompleteAddableSelectInputProps = {
  icon?: ComponentType<IconProps>;
  name: string;
  label?: string;
  placeholder?: string;
  value: string;
  values: string[];
  onChange: (value: string) => void;
  error?: string;
  onFetch: () => void;
  dataCy?: string;
};

export default function AutocompleteAddableSelectInput({
  icon: Icon,
  name,
  label,
  placeholder,
  error,
  onChange,
  value,
  values,
  onFetch,
  dataCy,
}: AutocompleteAddableSelectInputProps) {
  const componentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [selectedIndex, setSelectedIndex] = useState<number>();

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (isDropdownOpen && value.length > 2) {
      onFetch();
    }
  }, [isDropdownOpen, value]);

  function handleComponentClick() {
    setDropdownOpen((prev) => {
      const mirrorValue = !prev;

      if (mirrorValue === true) {
        inputRef.current?.focus();
      } else {
        inputRef.current?.blur();
      }

      return mirrorValue;
    });
  }

  function handleSelection(value: string) {
    onChange(value);
    setDropdownOpen(false);
  }

  useEffect(() => {
    document.addEventListener('mousedown', (ev) => {
      if (componentRef.current && !componentRef.current.contains(ev.target as Node)) {
        setDropdownOpen(false);
      }
    });

    document.addEventListener('mousemove', () => {
      if (selectedIndex) {
        setSelectedIndex(undefined);
      }
    });

    document.addEventListener('keydown', (ev) => {
      if (isDropdownOpen) {
        switch (ev.key) {
          case 'Escape':
            ev.preventDefault();
            setDropdownOpen(false);
            break;
          case 'Enter':
            ev.preventDefault();
            if (value.length > 2) {
              handleSelection(value);
              setDropdownOpen(false);
            }
            break;
        }
      }
    });
  }, [isDropdownOpen, values, value]);

  return (
    <div
      ref={componentRef}
      className="select-input"
    >
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error: error })}
        >
          {label}
        </label>
      )}

      <div
        data-cy={dataCy}
        className={classNames(
          'select-input__main',
          { 'select-input__main--focused': isDropdownOpen },
          { 'select-input__main--error': error },
        )}
        onClick={handleComponentClick}
      >
        {Icon && <Icon className="select-input__main__icon" />}

        <input
          data-cy={dataCy && `${dataCy}__input`}
          ref={inputRef}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          placeholder={placeholder}
        />

        <label htmlFor={name}>
          <ChevronDownIcon
            className={classNames('autocomplete-addable-select-input__main__icon', {
              'autocomplete-addable-select-input__main__icon--turn': isDropdownOpen,
            })}
          />
        </label>
      </div>

      {isDropdownOpen && (
        <ul className="select-input__dropdown">
          <li
            className={classNames(
              'select-input__dropdown__option',
              'autocomplete-addable-select-input__dropdown__option--typed-value',
            )}
          >
            <button
              type="button"
              onClick={() => value.length > 2 && handleSelection(value)}
            >
              <PlusIcon />
              {value}
            </button>
          </li>
          {values.map((v) => (
            <li
              data-cy={dataCy && `${dataCy}__item`}
              key={v}
              className="select-input__dropdown__option"
            >
              <button
                type="button"
                onClick={() => handleSelection(v)}
              >
                <CheckIcon />
                {v}
              </button>
            </li>
          ))}
        </ul>
      )}

      {error && <span className="select-input__error">{error}</span>}
    </div>
  );
}
