import { defineMessages } from 'react-intl';

export const HrAdminSignupFormTranslations = defineMessages({
  title: {
    id: 'HrAdminSignupFormTranslations.title',
    defaultMessage: "Créez gratuitement votre compte entreprise",
  },
  subtitle: {
    id: 'HrAdminSignupFormTranslations.subtitle',
    defaultMessage:
      'Remplissez ce formulaire pour créer votre compte entreprise. Vous recevrez ensuite un email pour valider votre demande.',
  },
});
