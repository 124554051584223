import { gql } from '@apollo/client';

export const ADMIN_RH_SIGN_UP_MUTATION = gql`
  mutation AdminHrSignup(
    $email: String!
    $password: String!
    $firstName: String
    $lastName: String
    $jobTitle: String
    $gender: GenderEnum
    $companyName: String!
    $companySize: Int!
    $companySector: ID!
    $hrChallenges: [HrChallengeKeyEnum!]!
    $companyLogoUploadId: String!
    $phone: String
  ) {
    signup(
      email: $email
      firstName: $firstName
      lastName: $lastName
      jobTitle: $jobTitle
      gender: $gender
      password: $password
      phone: $phone
      companyCreationRequest: {
        companyName: $companyName
        companySize: $companySize
        companySectorId: $companySector
        hrChallenges: $hrChallenges
        companyLogoUploadId: $companyLogoUploadId
      }
    ) {
      email
    }
  }
`;


export const GET_COMPANY_SECTORS_QUERY = gql`
  query GET_COMPANY_SECTORS {
    companySectors {
      id
      name
    }
  }
`;

