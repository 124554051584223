import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query GET_CURRENT_USER {
    currentUserV2 {
      isCoach
      companyCreationRequest {
        id
        statusCd
      }
      companyAdminProfiles {
        id
        company {
          id
        }
      }
      schoolAdminProfiles {
        id
        company {
          id
        }
      }
    }
  }
`;
