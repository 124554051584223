import Storage from '../enums/storage';

export function getStorageItem(key: Storage, defaultValue: string | undefined) {
  try {
    return window.localStorage?.getItem?.(key) || defaultValue;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return defaultValue;
  }
}

export function setStorageItem(key: Storage, value: string) {
  try {
    window.localStorage?.setItem(key, value);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export function removeStorageItem(key: Storage) {
  try {
    window.localStorage?.removeItem(key);
  } catch (e) {
    /* istanbul ignore next */
    // eslint-disable-next-line no-console
    console.error(e);
  }
}
