import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { PaidAmbassadorUnpublicationModalTranslations } from './PaidAmbassadorUnpublicationModal.translations';
import ButtonsGroup from 'components/buttons-group/ButtonsGroup';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import { goToMarketplaceSettings } from 'utils/redirections';
import { UserContext } from 'contexts/user/UserContext';

const mjgContact = window.__RUNTIME_CONFIG__.REACT_APP_MJG_CS_CONTACT;

type PaidAmbassadorUnpublicationModalProps = {
  mainAdminEmail: string;
};

export default function PaidAmbassadorUnpublicationModal({ mainAdminEmail }: PaidAmbassadorUnpublicationModalProps) {
  const { formatMessage } = useIntl();
  const { user } = useContext(UserContext);

  const handleContactHR = () => {
    const contactEmail = mainAdminEmail ?? mjgContact;
    window.location.href = `mailto:${contactEmail}`;
  };

  return (
    <div>
      <p
        dangerouslySetInnerHTML={{
          __html: formatMessage(PaidAmbassadorUnpublicationModalTranslations.subtitle),
        }}
      />
      <p
        dangerouslySetInnerHTML={{
          __html: formatMessage(PaidAmbassadorUnpublicationModalTranslations.content),
        }}
      />

      <ButtonsGroup>
        <SecondaryButton
          label={formatMessage(PaidAmbassadorUnpublicationModalTranslations.contactHR)}
          onClick={handleContactHR}
        />
        <PrimaryButton
          label={formatMessage(PaidAmbassadorUnpublicationModalTranslations.hibernate)}
          onClick={goToMarketplaceSettings}
        />
      </ButtonsGroup>
    </div>
  );
}
