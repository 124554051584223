import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { IntlShape } from 'react-intl';
import { AuthenticationErrorTranslations } from 'scenes/authentication/Authentication.translations';
import { hasMaximumAge, hasMinimumAge } from 'scenes/confirm-signup/utils/check-date.util';
import { CommonErrorInputFormTranslations } from 'i18n/common.translations';

YupPassword(Yup);
const { boolean, date, object, string } = Yup;

const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

export default (intl: IntlShape) =>
	object().shape({
		email: string()
			.email(intl.formatMessage(AuthenticationErrorTranslations.emailInvalid))
			.required(intl.formatMessage(AuthenticationErrorTranslations.emailRequired)),
		password: string()
      .required(intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty))
      .min(8, intl.formatMessage(CommonErrorInputFormTranslations.inputTooShort))
      .minLowercase(1, intl.formatMessage(CommonErrorInputFormTranslations.passwordTooWeak))
      .minUppercase(1, intl.formatMessage(CommonErrorInputFormTranslations.passwordTooWeak))
      .minNumbers(1, intl.formatMessage(CommonErrorInputFormTranslations.passwordTooWeak))
      .minSymbols(1, intl.formatMessage(CommonErrorInputFormTranslations.passwordTooWeak)),
    confirmPassword: string()
      .required(intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty))
      .test('passwords-match', intl.formatMessage(CommonErrorInputFormTranslations.passwordMismatch), function (value) {
        return this.parent.password === value;
      }),
    jobTitle: string()
			.required(intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty)),
    cgu: boolean()
      .oneOf([true], intl.formatMessage(AuthenticationErrorTranslations.mustAcceptCGU)),
		phoneNumber: string()
      .required(intl.formatMessage(CommonErrorInputFormTranslations.inputEmpty))
      .matches(phoneRegExp, intl.formatMessage(CommonErrorInputFormTranslations.invalidPhoneNumber)),
    birthdate: date()
      // eslint-disable-next-line max-len
      .required(intl.formatMessage(AuthenticationErrorTranslations.birthdateRequired))
      .test({
        name: 'age specs',
        exclusive: true,
        test: (value) => hasMinimumAge(value),
        message: intl.formatMessage(AuthenticationErrorTranslations.minimumAgeRequired),
      })
      .test({
        name: 'age specs',
        exclusive: true,
        test: hasMaximumAge,
        message: intl.formatMessage(AuthenticationErrorTranslations.cannotBeOlderThan),
      }),
  });

