import { ComponentType, useContext } from 'react';
import { IconProps } from 'assets/component-icons/Icon';
import { MessageDescriptor, useIntl } from 'react-intl';
import { Panel } from 'scenes/profile/panels';
import './SidebarCategory.scss';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { UserContext } from 'contexts/user/UserContext';

type SidebarCategoryProps = {
  icon: ComponentType<IconProps>;
  title: MessageDescriptor;
  panels: Panel[];
};

export default function SidebarCategory({ icon: Icon, title, panels }: SidebarCategoryProps) {
  const { formatMessage } = useIntl();
  const location = useLocation();

  const { user } = useContext(UserContext);

  if (!user) return null;

  return (
    <div className="sidebar-category">
      <h2 className="sidebar-category__title">
        <Icon />
        {formatMessage(title)}
      </h2>

      <div className="sidebar-category__panels">
        {panels.map(
          (panel) =>
            panel.isRendered(user.editionCompletionStatus?.fieldsCompletion || []) && (
              <Link
                key={panel.path}
                className={classNames(
                  'sidebar-category__panels__item',
                  {
                    'sidebar-category__panels__item--active': location.pathname.includes(panel.path),
                  },
                  {
                    'sidebar-category__panels__item--complete': panel.isComplete(
                      user.editionCompletionStatus?.fieldsCompletion || [],
                    ),
                  },
                )}
                to={`.${panel.path}`}
              >
                {formatMessage(panel.title!)}
              </Link>
            ),
        )}
      </div>
    </div>
  );
}
