import { defineMessages } from 'react-intl';

export const ConfirmSignupTranslations = defineMessages({
  title: {
    id: 'ConfirmSignup.title',
    defaultMessage: "Confirmation d'inscription",
  },
  subtitle: {
    id: 'ConfirmSignup.subtitle',
    defaultMessage: 'Renseignez les informations suivantes afin de confirmer votre inscription.',
  },
});

export const InvitationTranslations = defineMessages({
  invitationTokenInvalid: {
    id: 'InvitationToken.invitationTokenInvalid',
    defaultMessage: "Cette invitation n'est pas valide",
  },
  invitationTokenAlreadyUsed: {
    id: 'InvitationToken.invitationTokenAlreadyUsed',
    defaultMessage: 'Cette invitation est déjà utilisée',
  },
});
