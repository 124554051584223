import CounterInput from 'components/counter-input/CounterInput';
import { MaxMeetingsPanelTranslations } from './panels.translations';
import { DEFAULT_MAX_MEETINGS_PER_MONTH, MAX_MEETINGS_PER_MONTH_RANGE } from 'constants/profile.constant';
import { useIntl } from 'react-intl';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { useFormik } from 'formik';
import { maxMeetingPanelValidationSchema } from './panels.validation';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import { CommonTranslations } from 'i18n/common.translations';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import './WantToSharePanel.scss';
import useFormikNetworkState from 'hooks/use-formik-network-state';

type FormikValues = {
  maxMeetingsPerMonth: number;
};

export default function MaxMeetingPanel() {
  const intl = useIntl();

  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikValues>({
    initialValues: {
      maxMeetingsPerMonth: DEFAULT_MAX_MEETINGS_PER_MONTH,
    },
    validationSchema: maxMeetingPanelValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async ({ maxMeetingsPerMonth }) => {
      networkState.loading();
      await updateUser({ maxMeetingsPerMonth });
      networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <CounterInput
        name="maxMeetingsPerMonth"
        label={intl.formatMessage(MaxMeetingsPanelTranslations.quota)}
        value={formik.values.maxMeetingsPerMonth}
        min={MAX_MEETINGS_PER_MONTH_RANGE.min}
        max={MAX_MEETINGS_PER_MONTH_RANGE.max}
        onChange={(value) => formik.setFieldValue('maxMeetingsPerMonth', value)}
      />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        onClick={formik.handleSubmit}
        disabled={!formik.isValid}
      />
    </form>
  );
}
