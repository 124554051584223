import { defineMessages } from 'react-intl';

export const GouvSchoolCreateModalTranslations = defineMessages({
  title: {
    id: 'GouvSchoolCreateModal.title',
    defaultMessage: 'Ajouter mon établissement',
  },
  nameLabel: {
    id: 'GouvSchoolCreateModal.nameLabel',
    defaultMessage: 'Nom de votre établissement',
  },
  cityLabel: {
    id: 'GouvSchoolCreateModal.cityLabel',
    defaultMessage: 'Ville',
  },
  create: {
    id: 'GouvSchoolCreateModal.create',
    defaultMessage: 'Valider',
  },
  nameRequired: {
    id: 'GouvSchoolCreateModal.nameRequired',
    defaultMessage: 'Nom requis',
  },
  cityRequired: {
    id: 'GouvSchoolCreateModal.cityRequired',
    defaultMessage: 'Ville requise',
  },
});
