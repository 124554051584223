import { ComponentType } from 'react';
import { ReactNode } from 'react';
import { MessageDescriptor } from 'react-intl';
import IdentityPanel from './IdentityPanel';
import { FieldCompletionName, FieldCompletionStatus } from '../../../@types/graphql';
import {
  AmbassadorPresentationPanelTranslations,
  GalleryPanelTranslations,
  IdentityPanelTranslations,
  LanguagesPanelTranslations,
  MaxMeetingsPerMonthPanelTranslations,
  MeetingPlacePanelTranslations,
  MeetingPreferencesPanelTranslations,
  MemberPresentationPanelTranslations,
  OrientationPanelTranslations,
  PanelCategoriesTranslations,
  PastEducationsPanelTranslations,
  PhonePanelTranslations,
  PrimarySituationPanelTranslations,
} from './panels.translations';
import { IconProps } from 'assets/component-icons/Icon';
import GlassesIcon from 'assets/component-icons/GlassesIcon';
import HeartHandIcon from 'assets/component-icons/HeartHandIcon';
import PhonePanel from './PhonePanel';
import SituationPanel from './SituationPanel';
import MeetingPlacePanel from './MeetingPlacePanel';
import LanguagesPanel from './LanguagesPanel';
import MeetingPreferencesPanel from './MeetingPreferencesPanel';
import MaxMeetingsPerMonthPanel from './MaxMeetingsPerMonthPanel';
import UserIcon from 'assets/component-icons/UserIcon';
import Rows3Icon from 'assets/component-icons/Rows3Icon';
import FileSearchIcon from 'assets/component-icons/FileSearchIcon';
import MemberPresentationPanel from './MemberPresentationPanel';
import PhoneIcon from 'assets/component-icons/PhoneIcon';
import MarkerPinIcon from 'assets/component-icons/MarkerPinIcon';
import AmbassadorPresentationPanel from './AmbassadorPresentationPanel';
import PastEducationsPanel from './PastEducationsPanel';
import GalleryPanel from './GalleryPanel';
import OrientationPanel from './OrientationPanel';

type PanelOptions = {
  icon?: ComponentType<IconProps>;
  title?: MessageDescriptor;
  subtitle?: MessageDescriptor;
  component: ReactNode;
  fields?: FieldCompletionName[];
};

export class Panel {
  readonly path: string;
  readonly icon?: ComponentType<IconProps>;
  readonly title?: MessageDescriptor;
  readonly subtitle?: MessageDescriptor;
  readonly component: ReactNode;
  readonly fields?: FieldCompletionName[];

  constructor(path: string, options: PanelOptions) {
    this.path = path;
    this.icon = options.icon;
    this.title = options.title;
    this.subtitle = options.subtitle;
    this.component = options.component;
    this.fields = options.fields;
  }

  isRendered(fieldsNeeded: FieldCompletionStatus[]): boolean {
    if (!this.fields) {
      return true;
    }
    return this.fields.some((f) => fieldsNeeded.some((fn) => fn.name === f));
  }

  isComplete(fieldsNeeded: FieldCompletionStatus[]): boolean {
    if (!this.fields) {
      return false;
    }
    const fieldsToCheck = fieldsNeeded.filter((f) => this.fields!.includes(f.name));
    return fieldsToCheck.every((f) => f.complete);
  }
}

type CategorizedPanels = {
  title: MessageDescriptor;
  icon: ComponentType<IconProps>;
  ambassadorCategory: boolean;
  panels: Panel[];
};

const PERSONAL_INFORMATIONS_CATEGORY: CategorizedPanels = {
  title: PanelCategoriesTranslations.personalInformations,
  icon: GlassesIcon,
  ambassadorCategory: false,
  panels: [
    new Panel('/identity', {
      icon: UserIcon,
      title: IdentityPanelTranslations.title,
      subtitle: IdentityPanelTranslations.subtitle,
      component: <IdentityPanel />,
      fields: [
        FieldCompletionName.Avatar,
        FieldCompletionName.Gender,
        FieldCompletionName.FirstName,
        FieldCompletionName.LastName,
      ],
    }),
    new Panel('/situation', {
      icon: Rows3Icon,
      title: PrimarySituationPanelTranslations.title,
      subtitle: PrimarySituationPanelTranslations.subtitle,
      component: <SituationPanel />,
      fields: [
        FieldCompletionName.PrimarySituation,
        FieldCompletionName.SecondarySituation,
        FieldCompletionName.JobTitle,
        FieldCompletionName.CompanyName,
        FieldCompletionName.YearsOfExperience,
        FieldCompletionName.CurrentScholarityInfos,
      ],
    }),
    new Panel('/member-presentation', {
      icon: FileSearchIcon,
      title: MemberPresentationPanelTranslations.title,
      subtitle: MemberPresentationPanelTranslations.subtitle,
      component: <MemberPresentationPanel />,
      fields: [FieldCompletionName.EditionMemberPresentation],
    }),
    new Panel('/past-educations', {
      icon: FileSearchIcon,
      title: PastEducationsPanelTranslations.title,
      subtitle: PastEducationsPanelTranslations.subtitle,
      component: <PastEducationsPanel />,
      fields: [FieldCompletionName.AcquiredDiploma],
    }),
    new Panel('/phone', {
      icon: PhoneIcon,
      title: PhonePanelTranslations.title,
      subtitle: PhonePanelTranslations.subtitle,
      component: <PhonePanel />,
      fields: [FieldCompletionName.Phone],
    }),
    new Panel('/languages', {
      title: LanguagesPanelTranslations.title,
      subtitle: LanguagesPanelTranslations.subtitle,
      component: <LanguagesPanel />,
      fields: [FieldCompletionName.Languages],
    }),
    new Panel('/meeting-place', {
      icon: MarkerPinIcon,
      title: MeetingPlacePanelTranslations.title,
      subtitle: MeetingPlacePanelTranslations.subtitle,
      component: <MeetingPlacePanel />,
      fields: [FieldCompletionName.MeetingPlace],
    }),
  ],
};

const AMBASSADOR_INFORMATIONS_CATEGORY: CategorizedPanels = {
  title: PanelCategoriesTranslations.ambassadorInformations,
  icon: HeartHandIcon,
  ambassadorCategory: true,
  panels: [
    new Panel('/max-meetings-per-month', {
      title: MaxMeetingsPerMonthPanelTranslations.title,
      subtitle: MaxMeetingsPerMonthPanelTranslations.subtitle,
      component: <MaxMeetingsPerMonthPanel />,
      fields: [FieldCompletionName.MaxMeetingPerMonth],
    }),
    new Panel('/ambassador-presentation', {
      icon: FileSearchIcon,
      title: AmbassadorPresentationPanelTranslations.title,
      subtitle: AmbassadorPresentationPanelTranslations.subtitle,
      component: <AmbassadorPresentationPanel />,
      fields: [
        FieldCompletionName.AmbassadorPresentation,
        FieldCompletionName.InterestsPresentation,
        FieldCompletionName.ExpertisePresentation,
        FieldCompletionName.AspirationPresentation,
      ],
    }),
    new Panel('/meeting-preferences', {
      title: MeetingPreferencesPanelTranslations.title,
      subtitle: MeetingPreferencesPanelTranslations.subtitle,
      component: <MeetingPreferencesPanel />,
      fields: [FieldCompletionName.MeetingPreferences],
    }),
    new Panel('/gallery', {
      title: GalleryPanelTranslations.title,
      subtitle: GalleryPanelTranslations.subtitle,
      component: <GalleryPanel />,
      fields: [FieldCompletionName.Languages],
    }),
    new Panel('/orientation', {
      title: OrientationPanelTranslations.title,
      subtitle: OrientationPanelTranslations.subtitle,
      component: <OrientationPanel />,
      fields: [FieldCompletionName.Orientation],
    }),
  ],
};

export const CATEGORIZED_PANELS: CategorizedPanels[] = [
  PERSONAL_INFORMATIONS_CATEGORY,
  AMBASSADOR_INFORMATIONS_CATEGORY,
];
