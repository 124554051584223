import classNames from 'classnames';
import { CSSProperties, PropsWithChildren } from 'react';
import './ButtonsGroup.scss';

type ButtonsGroupsProps = {
  style?: CSSProperties;
  className?: string;
  fluid?: boolean;
};

export default function ButtonsGroup({ children, className, style, fluid }: PropsWithChildren<ButtonsGroupsProps>) {
  return (
    <div
      className={classNames('buttons-group', className,
        { 'buttons-group--fluid': fluid })}
      style={style}
    >
      {children}
    </div>
  );
}
