import { useEffect, useState } from 'react';
import Storage from '../../enums/storage';
import { getStorageItem } from '../../utils/storage';

export type Languages = 'fr' | 'en';

/* istanbul ignore next */
function loadLocaleData(locale: string) {
  switch (locale) {
    case 'en':
      return import('../../i18n/en.json');
    default:
      return import('../../i18n/fr.json');
  }
}

export function useLanguage() {
  const [language, setLanguage] = useState<Languages>((getStorageItem(Storage.LANGUAGE, 'fr') as Languages) || 'fr'); // no choice
  const [loading, setLoading] = useState(true);
  const [translations, setTranslations] = useState({});
  useEffect(() => {
    async function load() {
      if (process.env.NODE_ENV === 'test') return; // avoid useless action on test
      setTranslations(await loadLocaleData(language));
      setLoading(false);
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    load();
  }, [language]);
  return {
    loading,
    translations,
    language: ['fr', 'en'].includes(language) ? language : 'fr',
    setLanguage,
  };
}
