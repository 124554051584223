import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { errorTracker } from '../error-tracker/error-tracker';

const opts = {
  uri: `${window.__RUNTIME_CONFIG__.REACT_APP_HERMES_URL || ''}/graphql`,
  credentials: 'include',
};

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: BatchHttpLink.from([
    errorTracker.getNetworkErrorHandler(),
    new ApolloLink((operation, forward) => {
      const definition = operation.query.definitions[0] as any;
      errorTracker.addNetworkBreadcrumb(
        `Called ${operation.operationName}`,
        definition.operation === 'mutation' ? 'mutation' : 'query',
      );
      return forward(operation);
    }),
    createUploadLink(opts) as unknown as ApolloLink,
    new BatchHttpLink(opts),
  ]),
});

export default client;
