import { useLocation } from 'react-router-dom';
import { Location } from 'history';
import { useContext } from 'react';
import { SettingsContext } from 'contexts/settings/SettingsContext';

enum SearchParamsKeys {
  schoolName = 'school_name',
  schoolTown = 'school_town',
  schoolType = 'school_type',
  schoolZipCode = 'school_zip_code',
}

function getSearchParams(location: Location) {
  const searchParams = new URLSearchParams(location.search);

  const invitationToken = searchParams.get('invitationToken');
  const schoolName = searchParams.get(SearchParamsKeys.schoolName);
  const schoolTown = searchParams.get(SearchParamsKeys.schoolTown);
  const schoolType = searchParams.get(SearchParamsKeys.schoolType);
  const schoolZipCode = searchParams.get(SearchParamsKeys.schoolZipCode);

  return {
    invitationToken,
    schoolName,
    schoolTown,
    schoolType,
    schoolZipCode,
  };
}

export default function useOAuth() {
  const location = useLocation();
  const params = getSearchParams(location);

  const settingsContext = useContext(SettingsContext);

  return {
    signInWithLinkedIn: () => {
      if (settingsContext?.settings) {
        const { settings } = settingsContext;

        const url = new URL(settings.linkedinAuthorizationUrl);
        const redirectUri = new URL(url.searchParams.get('redirect_uri')!);
        const state = redirectUri.searchParams.get('state');
        localStorage.setItem(state!, JSON.stringify(params));
        window.location.href = settings.linkedinAuthorizationUrl;
      }
    },
    signInWithFranceTravail: () => {
      if (settingsContext?.settings) {
        const { settings } = settingsContext;

        const url = new URL(settings.franceTravailAuthorizationUrl);
        const state = url.searchParams.get('state');

        localStorage.setItem(state!, JSON.stringify(params));

        window.location.href = settings.franceTravailAuthorizationUrl;
      }
    },
  };
}
