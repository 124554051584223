import { defineMessages } from 'react-intl';
import { BinaryResponse } from '../models/BinaryResponse.enum';

export const BinaryResponseTranslations = defineMessages({
  [BinaryResponse.Yes]: {
    id: 'BinaryResponse.yes',
    defaultMessage: 'Oui',
  },
  [BinaryResponse.No]: {
    id: 'BinaryResponse.no',
    defaultMessage: 'Non',
  },
});
