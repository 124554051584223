import { useMutation } from '@apollo/client';
import { useSnackbar } from '@myjobglasses/account-ui';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import { Confirm_EmailMutation, Confirm_EmailMutationVariables, ConfirmEmailErrorEnum } from '../../../@types/graphql';
import PageLoaderContext from '../../../contexts/pageLoader';
import Routes from '../../../Routes';
import translations from './ConfirmEmail.translations';
import { CONFIRM_EMAIL } from './ConfirmEmail.gql';
import { useTokens } from '../../../hooks/use-tokens/useTokens';
import { ApolloError, GraphQLErrors } from '@apollo/client/errors';
import ConfirmedEmail from 'scenes/confirmed-email/ConfirmedEmail';

function ConfirmEmail() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { success, error: errorSnackbar } = useSnackbar();
  const { setLoading } = useContext(PageLoaderContext);
  const location = useLocation();
  const { confirmationToken } = useTokens(location?.search);

  const [confirmEmail, { loading }] = useMutation<Confirm_EmailMutation, Confirm_EmailMutationVariables>(
    CONFIRM_EMAIL,
    {
      variables: {
        confirmationToken: confirmationToken || '',
      },
    },
  );

  useEffect(() => {
    confirmEmail()
      .then((data) => {
        if (data.data?.confirmEmail?.signedUpAsHrAdmin) {
          navigate(Routes.confirmedEmail);
          window.location.reload()
        } else {
          success(intl.formatMessage(translations.success), {
            icon: 'check_circle',
          });
          navigate(Routes.home);
          window.location.reload();
        }
      })
      .catch((error: ApolloError) => {
        const errorCodes = error.graphQLErrors.map(({ extensions }) => extensions?.code);

        if (errorCodes.includes(ConfirmEmailErrorEnum.AlreadyConfirmed)) {
          errorSnackbar(intl.formatMessage(translations.alreadyConfirmed), {
            time: 20000,
            closeButtonContent: intl.formatMessage(translations.close),
          });
        } else {
          errorSnackbar(intl.formatMessage(translations.error));
        }
        navigate(Routes.home);
      });
  }, []);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return null;
}

export default ConfirmEmail;
