import { FormikErrors, FormikTouched, FormikValues } from 'formik';

export default function formikError<T extends FormikValues>(
  touched: FormikTouched<T>,
  errors: FormikErrors<T>,
  value: keyof T,
) {
  if (touched[value] && errors[value]) {
    return errors[value];
  }
  return undefined;
}
