import { useContext } from 'react';
import { useFormik } from 'formik';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import TextInput from '../../../components/text-input/TextInput';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import MonthPicker from 'components/month-picker/MonthPicker';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { PastEducationPanelTranslations } from './panels.translations';
import { pastEducationPanelValidationSchema } from './panels.validation';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { DiplomaEnum } from '../../../@types/graphql';
import SelectInput from 'components/select-input/SelectInput';
import GraduationHatIcon from 'assets/component-icons/GraduationHatIcon';
import { CommonTranslations } from 'i18n/common.translations';
import formikError from 'components/formik-helper/formik.helper';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import SchoolNameAutocompleteSelectInput from 'components/autocomplete-addable-select-input/contextuals/SchoolNameAutocompleteSelectInput';
import CalendarIcon from 'assets/component-icons/CalendarIcon';
import NumberInput from 'components/number-input/NumberInput';

const USE_MJG_SCHOOL_USE_MAP = [
  DiplomaEnum.Bac_2,
  DiplomaEnum.Bac_3,
  DiplomaEnum.Bac_4,
  DiplomaEnum.Bac_5,
  DiplomaEnum.BacGt,
  DiplomaEnum.BacPro,
  DiplomaEnum.Bep,
  DiplomaEnum.Brevet,
  DiplomaEnum.Cap,
  DiplomaEnum.Cep,
  DiplomaEnum.Doctorat,
];

type FormikValues = {
  acquiredDiplomaLevel?: DiplomaEnum;
  acquiredDiplomaTitle: string;
  acquiredDiplomaSchoolName: string;
  endDate?: number;
};

export default function PastEducationPanel() {
  const intl = useIntl();
  const { formatMessage } = intl;
  const updateUser = useProfileCompletionUserUpdate();
  const settingsContext = useContext(SettingsContext);
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const shouldShowDiplomaTitleInput = (level: DiplomaEnum | undefined) => {
    return (
      level !== DiplomaEnum.None && level !== DiplomaEnum.Cep && level !== DiplomaEnum.Brevet && level !== undefined
    );
  };

  const formik = useFormik<FormikValues>({
    initialValues: {
      acquiredDiplomaLevel: undefined,
      acquiredDiplomaTitle: '',
      acquiredDiplomaSchoolName: '',
      endDate: 0,
    },
    validationSchema: pastEducationPanelValidationSchema(intl),
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: async ({ endDate, acquiredDiplomaLevel, acquiredDiplomaSchoolName, acquiredDiplomaTitle }) => {
      networkState.loading();
      await updateUser({
        acquiredDiplomaLevel,
        acquiredDiplomaTitle,
        acquiredDiplomaSchoolName,
        acquiredDiplomaAcquisitionYear: endDate,
      });
      networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <SelectInput
        dataCy="acquired-diploma-level"
        name="acquiredDiplomaLevel"
        label={intl.formatMessage(PastEducationPanelTranslations.pastEducationLevel)}
        values={(settingsContext?.settings?.diplomas || []).map(({ key, name }) => ({
          value: key,
          translation: name,
        }))}
        icon={GraduationHatIcon}
        onChange={(acquiredDiplomaLevel) => formik.setFieldValue('acquiredDiplomaLevel', acquiredDiplomaLevel)}
        error={formikError(formik.touched, formik.errors, 'acquiredDiplomaLevel')}
      />
      {shouldShowDiplomaTitleInput(formik.values.acquiredDiplomaLevel) && (
        <TextInput
          dataCy="acquired-diploma-title"
          name="acquiredDiplomaTitle"
          label={formatMessage(PastEducationPanelTranslations.pastDiplomaLabel)}
          icon={GraduationHatIcon}
          value={formik.values.acquiredDiplomaTitle}
          onChange={formik.handleChange}
          error={formikError(formik.touched, formik.errors, 'acquiredDiplomaTitle')}
        />
      )}

      {formik.values.acquiredDiplomaLevel && USE_MJG_SCHOOL_USE_MAP.includes(formik.values.acquiredDiplomaLevel) && (
        <SchoolNameAutocompleteSelectInput
          dataCy="acquired-diploma-school-name"
          name="acquiredDiplomaSchoolName"
          label={intl.formatMessage(PastEducationPanelTranslations.pastSchoolNameLabel)}
          value={formik.values.acquiredDiplomaSchoolName}
          onChange={(schoolName) => formik.setFieldValue('acquiredDiplomaSchoolName', schoolName)}
        />
      )}

      {formik.values.acquiredDiplomaLevel !== DiplomaEnum.None && formik.values.acquiredDiplomaLevel !== undefined && (
        <div className="row">
          <NumberInput 
            icon={CalendarIcon}
            label={formatMessage(PastEducationPanelTranslations.periodEnd)}
            placeholder='YYYY'
            name="endDate"
            min="1900"
            max="2099"
            step="1"
            onChange={formik.handleChange}
            error={formikError(formik.touched, formik.errors, 'endDate')}
          />
        </div>
      )}

      <PrimaryButton
        label={formatMessage(CommonTranslations.confirm)}
        icon={networkState.iconBasedOnNetworkState}
        onClick={formik.handleSubmit}
        submit
        disabled={!formik.isValid || !formik.dirty}
      />
    </form>
  );
}
