import { useFormik } from 'formik';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import { useIntl } from 'react-intl';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { meetingPlacePanelValidationSchema } from './panels.validation';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import { CommonTranslations } from 'i18n/common.translations';
import GooglePlaceInput from '../../../components/google-place-input/GooglePlaceInput';
import MarkerPinIcon from 'assets/component-icons/MarkerPinIcon';
import useFormikNetworkState from 'hooks/use-formik-network-state';

type FormikValues = {
  googlePlace?: {
    googlePlaceId: string;
    meetingPlace: string;
  };
};

export default function MeetingPlacePanel() {
  const intl = useIntl();

  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikValues>({
    initialValues: {},
    validationSchema: meetingPlacePanelValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async ({ googlePlace }) => {
      networkState.loading();
      if (!googlePlace) return;

      const { googlePlaceId, meetingPlace } = googlePlace;
      await updateUser({
        googlePlaceId,
        meetingPlace,
      });
      networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <GooglePlaceInput
        name="googlePlace"
        icon={MarkerPinIcon}
        onChange={(e) => {
          formik.setFieldValue('googlePlace', {
            googlePlaceId: e.place_id,
            meetingPlace: e.description,
          });
        }}
      />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        onClick={formik.handleSubmit}
        disabled={!formik.dirty}
      />
    </form>
  );
}
