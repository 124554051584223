import './Gallery.scss';
import { UserPartsFragment } from '../../../../@types/graphql';
import GalleryNewImageButton from './gallery-new-image-button/GalleryNewImageButton';
import GalleryImage from './gallery-image/GalleryImage';

type GalleryProps = {
  images: UserPartsFragment['galleryImages'];
  loading: boolean;
  onImageUpload: (file: File) => void;
  onImageDeletion: (imageId: string) => void;
};

export default function Gallery({ images, loading, onImageUpload, onImageDeletion }: GalleryProps) {
  return (
    <div className="gallery">
      {images.map((image) => (
        <GalleryImage
          key={image.id}
          {...{ image, onImageDeletion }}
        />
      ))}

      <GalleryNewImageButton
        onFileSelection={onImageUpload}
        loading={loading}
      />
    </div>
  );
}
