import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { UserContext } from './UserContext';
import { UPDATE_USER } from './UserContext.gql';
import { AvailableLanguagesEnum, UpdateUserMutation, UpdateUserMutationVariables } from '../../@types/graphql';
import { useLanguage } from 'hooks/use-languages/useLanguages';

type UserContextUpdateOptions = {
  dry?: boolean;
};

export default function useUserContextUpdate(options?: UserContextUpdateOptions) {
  const language = useLanguage();

  const { user, updateUser } = useContext(UserContext);
  const [updateUserMutation] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UPDATE_USER, {
    onCompleted: (data) => {
      if (!data.updateUser || !updateUser || options?.dry) return;
      updateUser(data.updateUser);
    },
  });
  return (variables: Omit<UpdateUserMutationVariables, 'userId' | 'locale'>) => {
    return updateUserMutation({
      variables: {
        locale: language.language === 'fr' ? AvailableLanguagesEnum.Fr : AvailableLanguagesEnum.En,
        userId: user?.id || '',

        ...variables,
      },
    });
  };
}
