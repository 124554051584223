import { gql } from '@apollo/client';
import { PROFILE_COMPLETION_USER_PARTS_FRAGMENT } from 'scenes/profile-completion/ProfileCompletion.gql';

export const UPDATE_USER_PROFILE_COMPLETION = gql`
  ${PROFILE_COMPLETION_USER_PARTS_FRAGMENT}
  mutation UpdateUserProfileCompletion(
    $completionContext: CompletionContextEnum!
    $locale: AvailableLanguagesEnum
    $birthdate: ISO8601Date
    $gender: GenderEnum
    $firstName: String
    $lastName: String
    $aspirationPresentation: String
    $avatar: Upload
    $yearsOfExperience: Int
    $companyName: String
    $previousPosition: String
    $previousCompanyName: String
    $previousPositionYearsOfExperiences: Int
    $neverWorked: Boolean
    $currentGrade: GradeEnum
    $currentSchoolCity: String
    $currentSchoolName: String
    $currentSchoolDepartment: String
    $expertisePresentation: String
    $googlePlaceId: String
    $interestsPresentation: String
    $languages: [Language!]
    $maxMeetingsPerMonth: Int
    $meetingPlace: String
    $meetingPreferences: [MeetingPreferenceInput!]
    $normalizedPhone: String
    $password: String
    $position: String
    $primarySituation: PrimarySituationEnum
    $secondarySituation: SecondarySituationEnum
    $memberPresentation: String
    $ambassadorPresentation: String
    $showWelcomePanel: Boolean
    $userId: ID!
    $wantToShare: Boolean
    $showCongratulationsPanel: Boolean
    $showVocationPanel: Boolean
    $programParticipationCode: String
    $apprentice: Boolean
    $acquiredDiplomaAcquisitionYear: Int
    $acquiredDiplomaBeginYear: Int
    $acquiredDiplomaLevel: DiplomaEnum
    $acquiredDiplomaSchoolName: String
    $acquiredDiplomaTitle: String
    $preparedDiplomaTitle: String
    $headTeacher: Boolean
    $teachedSchoolSubjects: [String!]
    $accompaniedStudyLevels: [GradeEnum!]
    $jobSeekerSupportOrganizationType: String
    $jobSeekerSupportPrograms: [String!]
    $vocationCoachSignup: Boolean
    $acceptGroupConversations: Boolean
  ) {
    updateUser(
      birthdate: $birthdate
      aspirationPresentation: $aspirationPresentation
      avatar: $avatar
      yearsOfExperience: $yearsOfExperience
      companyName: $companyName
      previousPosition: $previousPosition
      previousCompanyName: $previousCompanyName
      previousPositionYearsOfExperiences: $previousPositionYearsOfExperiences
      neverWorked: $neverWorked
      currentGrade: $currentGrade
      currentSchoolCity: $currentSchoolCity
      currentSchoolName: $currentSchoolName
      currentSchoolDepartment: $currentSchoolDepartment
      expertisePresentation: $expertisePresentation
      firstName: $firstName
      gender: $gender
      googlePlaceId: $googlePlaceId
      interestsPresentation: $interestsPresentation
      languages: $languages
      lastName: $lastName
      maxMeetingsPerMonth: $maxMeetingsPerMonth
      meetingPlace: $meetingPlace
      meetingPreferences: $meetingPreferences
      normalizedPhone: $normalizedPhone
      password: $password
      position: $position
      primarySituation: $primarySituation
      secondarySituation: $secondarySituation
      memberPresentation: $memberPresentation
      ambassadorPresentation: $ambassadorPresentation
      showWelcomePanel: $showWelcomePanel
      userId: $userId
      wantToShare: $wantToShare
      showCongratulationsPanel: $showCongratulationsPanel
      programParticipationCode: $programParticipationCode
      showVocationPanel: $showVocationPanel
      apprentice: $apprentice
      acquiredDiplomaAcquisitionYear: $acquiredDiplomaAcquisitionYear
      acquiredDiplomaBeginYear: $acquiredDiplomaBeginYear
      acquiredDiplomaLevel: $acquiredDiplomaLevel
      acquiredDiplomaSchoolName: $acquiredDiplomaSchoolName
      acquiredDiplomaTitle: $acquiredDiplomaTitle
      preparedDiplomaTitle: $preparedDiplomaTitle
      headTeacher: $headTeacher
      teachedSchoolSubjects: $teachedSchoolSubjects
      accompaniedStudyLevels: $accompaniedStudyLevels
      jobSeekerSupportOrganizationType: $jobSeekerSupportOrganizationType
      jobSeekerSupportPrograms: $jobSeekerSupportPrograms
      vocationCoachSignup: $vocationCoachSignup
      acceptGroupConversations: $acceptGroupConversations
    ) {
      ...ProfileCompletionUserParts
    }
  }
`;
