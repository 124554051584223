import { defineMessages } from 'react-intl';
import { GenderEnum } from '../@types/graphql';

export const GenderTranslations = defineMessages({
  [GenderEnum.Male]: {
    id: 'GenderTranslations.male',
    defaultMessage: 'Homme',
  },
  [GenderEnum.Female]: {
    id: 'GenderTranslations.female',
    defaultMessage: 'Femme',
  },
  [GenderEnum.Unspecified]: {
    id: 'GenderTranslations.unspecified',
    defaultMessage: 'Autre',
  },
});
