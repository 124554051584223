import { useMutation } from '@apollo/client';
import { useSnackbar } from '@myjobglasses/account-ui';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { New_PasswordMutation, New_PasswordMutationVariables } from '../../../../../../@types/graphql';
import { NEW_PASSWORD_ERRORS } from '../../../../../../@types/graphql-errors';
import Routes from '../../../../../../Routes';
import { handleGraphqlErrorsOnFormik } from '../../../../../../utils/formik';
import PageLoaderContext from '../../../../../../contexts/pageLoader';
import { NEW_PASSWORD } from './NewPasswordForm.gql';
import translations, { errorTranslations } from './NewPasswordForm.translations';
import { ErrorsTranslations } from 'i18n/errors.translations';

interface FormikFields {
  password: string;
  token: string;
}
export function useNewPassword() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { success } = useSnackbar();
  const { setLoading } = useContext(PageLoaderContext);

  const [newPassword, { loading }] = useMutation<New_PasswordMutation, New_PasswordMutationVariables>(NEW_PASSWORD);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return function onSubmit(values: FormikFields, formikBag: FormikHelpers<FormikFields>) {
    return newPassword({
      variables: {
        password: values.password,
        token: values.token,
      },
    })
      .then(() => {
        success(intl.formatMessage(translations.confirmation), {
          icon: 'check_circle',
        });
        navigate(Routes.home);
      })
      .catch((err) => {
        handleGraphqlErrorsOnFormik<FormikFields, New_PasswordMutationVariables, NEW_PASSWORD_ERRORS>(err, formikBag, {
          translations: {
            default: intl.formatMessage(ErrorsTranslations.default),
            network: intl.formatMessage(ErrorsTranslations.network),
            unknown: intl.formatMessage(ErrorsTranslations.technical),
            global: {
              [NEW_PASSWORD_ERRORS.EXPIRED_RESET_PASSWORD_TOKEN]: intl.formatMessage(
                errorTranslations.EXPIRED_RESET_PASSWORD_TOKEN,
              ),
              [NEW_PASSWORD_ERRORS.USER_PASSWORD_FORMAT_INVALID]: intl.formatMessage(
                errorTranslations.USER_PASSWORD_FORMAT_INVALID,
              ),
            },
          },
        });
      });
  };
}
