import styles from './RTFMailTo.module.scss';

export default function RTFMailTo({ email }: { email: React.ReactNode[] }): React.ReactNode {
  return (
    <a
      className={styles.link}
      href={`mailto:${email.join(',')}`}
    >
      {email}
    </a>
  );
}
