import { useContext, useMemo } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { meetingPlacePanelValidationSchema } from './panels.validation';
import { CommonTranslations } from 'i18n/common.translations';
import GooglePlaceInput from '../../../components/google-place-input/GooglePlaceInput';
import MarkerPinIcon from 'assets/component-icons/MarkerPinIcon';
import useUserContextUpdate from 'contexts/user/UserContextUpdate.hook';
import SaveIcon from 'assets/component-icons/SaveIcon';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import RefreshIcon from 'assets/component-icons/RefreshIcon';
import { UserContext } from 'contexts/user/UserContext';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import { MeetingPlacePanelTranslations } from './panels.translations';
import useFormikNetworkState from 'hooks/use-formik-network-state';

type FormikValues = {
  googlePlace?: {
    googlePlaceId: string;
    meetingPlace: string;
  };
};

export default function MeetingPlacePanel() {
  const intl = useIntl();
  const { formatMessage } = intl;

  const { user } = useContext(UserContext);
  const updateUser = useUserContextUpdate();

  const networkState = useFormikNetworkState();

  const initialGooglePlace = useMemo(() => {
    if (!user?.googlePlaceId || !user?.meetingPlace) return undefined;
    return {
      googlePlaceId: user.googlePlaceId,
      meetingPlace: user.meetingPlace,
    };
  }, [user?.googlePlaceId, user?.meetingPlace]);

  const formik = useFormik<FormikValues>({
    initialValues: {
      googlePlace: initialGooglePlace,
    },
    validationSchema: meetingPlacePanelValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async ({ googlePlace }) => {
      if (!googlePlace) return;
      networkState.loading();
      const { googlePlaceId, meetingPlace } = googlePlace;
      const response = await updateUser({
        googlePlaceId,
        meetingPlace,
      });
      response.errors ? networkState.error() : networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <GooglePlaceInput
        name="googlePlace"
        icon={MarkerPinIcon}
        label={formatMessage(MeetingPlacePanelTranslations.address)}
        initialValue={initialGooglePlace?.meetingPlace}
        onChange={(e) => {
          formik.setFieldValue('googlePlace', {
            googlePlaceId: e.place_id,
            meetingPlace: e.description,
          });
        }}
      />

      <footer>
        <PrimaryButton
          label={formatMessage(CommonTranslations.save)}
          icon={networkState.iconBasedOnNetworkState}
          submit
          accent
          leftIcon
          state={networkState.state}
          disabled={!formik.isValid}
        />
        <SecondaryButton
          label={formatMessage(CommonTranslations.reset)}
          leftIcon={RefreshIcon}
          onClick={() => formik.resetForm()}
          iconButtonWithText
        />
      </footer>
    </form>
  );
}
