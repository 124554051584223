import { useContext } from 'react';
import { useFormik } from 'formik';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import CheckboxInput from '../../../components/checkbox-input/CheckboxInput';
import { MeetingPreference } from '../../../@types/graphql';
import { UserContext } from 'contexts/user/UserContext';
import useUserContextUpdate from 'contexts/user/UserContextUpdate.hook';
import { useIntl } from 'react-intl';
import { CommonTranslations } from 'i18n/common.translations';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import RefreshIcon from 'assets/component-icons/RefreshIcon';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { MeetingPreferencesPanelTranslations } from './panels.translations';

type FormikFields = {
  meetingPreferences: MeetingPreference[];
  acceptGroupConversations: boolean;
};

export default function MeetingPreferencesPanel() {
  const intl = useIntl();

  const { user } = useContext(UserContext);
  const updateUser = useUserContextUpdate();

  const networkState = useFormikNetworkState();

  const formik = useFormik<FormikFields>({
    initialValues: {
      meetingPreferences: user?.meetingPreferences || [],
      acceptGroupConversations: user?.acceptGroupConversations || false,
    },
    onSubmit: async ({ meetingPreferences, acceptGroupConversations }) => {
      networkState.loading();
      const response = await updateUser({
        meetingPreferences: meetingPreferences.map(({ key, value }) => ({
          key,
          accept: value || false,
        })),
        acceptGroupConversations,
      });
      response.errors ? networkState.error() : networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {formik.values.meetingPreferences?.map(({ key, name, value }, i) => (
        <CheckboxInput
          key={key}
          name={key}
          label={name}
          checked={value || false}
          onChange={(v) => formik.setFieldValue(`meetingPreferences[${i}].value`, v.target.checked)}
        />
      ))}
      <CheckboxInput
        name={intl.formatMessage(MeetingPreferencesPanelTranslations.acceptGroupConversation)}
        label={intl.formatMessage(MeetingPreferencesPanelTranslations.acceptGroupConversation)}
        checked={formik.values.acceptGroupConversations || false}
        onChange={(v) => formik.setFieldValue('acceptGroupConversations', v.target.checked)}
      />

      <footer>
        <PrimaryButton
          label={intl.formatMessage(CommonTranslations.save)}
          icon={networkState.iconBasedOnNetworkState}
          submit
          accent
          leftIcon
          state={networkState.state}
          disabled={!formik.isValid}
        />
        <SecondaryButton
          label={intl.formatMessage(CommonTranslations.reset)}
          leftIcon={RefreshIcon}
          onClick={formik.resetForm}
          iconButtonWithText
        />
      </footer>
    </form>
  );
}
