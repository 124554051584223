import { useIntl } from 'react-intl';
import { LayoutHeader } from 'components/layout/Layout';

import './HeaderAdminSignup.scss';
import ButtonsGroup from 'components/buttons-group/ButtonsGroup';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import classNames from 'classnames';
import { goToConnectDiscoverOffers } from 'utils/redirections';
import ahoy from 'utils/ahoy_api';

const translations = {
  title: {
    id: 'HeaderAdminSignup.title',
    defaultMessage: 'Nos offres entreprise',
  },
  subtitle: {
    id: 'HeaderAdminSignup.subtitle',
    defaultMessage:
      'Faites découvrir vos métiers et votre entreprise grâce aux témoignages de vos collaborateurs. My Job Glasses vous propose différentes offres, adaptées à la taille et aux besoins de votre entreprise.',
  },
  freeButton: {
    id: 'HeaderAdminSignup.freeButton',
    defaultMessage: 'Commencer avec la version gratuite',
  },
  premiumButton: {
    id: 'HeaderAdminSignup.premiumButton',
    defaultMessage: 'Découvrir nos offres Premium',
  },
};

type HeaderAdminSignupProps = {
  mobileView?: boolean;
	onFreeButtonClick: () => void;
};

export default function HeaderAdminSignup({mobileView, onFreeButtonClick}: HeaderAdminSignupProps) {
  const intl = useIntl();

  return (
    <div className="header-container">
      <LayoutHeader>
        <div className="header-container__title">
          <h1>{intl.formatMessage(translations.title)}</h1>
        </div>
      </LayoutHeader>
      <div className="header-container__subtitle">
        <h2>{intl.formatMessage(translations.subtitle)}</h2>
      </div>

      <div className={classNames(
				'header-container__button',
				{'header-container__button--mobile': mobileView})}>

        <ButtonsGroup>
          <SecondaryButton
            label={intl.formatMessage(translations.freeButton)}
            onClick={onFreeButtonClick}
            smaller
            outlined
          />
          <SecondaryButton
            label={intl.formatMessage(translations.premiumButton)}
            onClick={() => {
              ahoy.track('b2b_company_signup_clicked_discover_premium_offer', {})
              goToConnectDiscoverOffers();
            }}
            smaller
          />
        </ButtonsGroup>
      </div>
      {!mobileView && (
        <div className="header-container__img">
          <img
            src="https://assets.myjobglasses.com/account/admrh-signup.png"
            alt="placeholder"
          />
        </div>
      )}
    </div>
  );
}
