import ArrowLeftIcon from 'assets/component-icons/ArrowLeftIcon';
import Layout, { LayoutHeader } from 'components/layout/Layout';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import { UserContext } from 'contexts/user/UserContext';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom';
import { getMarketplaceDashboardUrl } from 'utils/redirections';
import Sidebar from './components/sidebar/Sidebar';
import { CATEGORIZED_PANELS } from './panels';
import { ProfileTranslations } from './profile.translations';
import './ProfileLayout.scss';

export default function ProfileLayout() {
  const { formatMessage } = useIntl();

  const { user } = useContext(UserContext);
  const settingsContext = useContext(SettingsContext);

  if (!user || !settingsContext?.settings) return null;

  return (
    <Layout>
      <LayoutHeader>
        <a
          className="back-link"
          href={getMarketplaceDashboardUrl()}
        >
          <ArrowLeftIcon />
          {formatMessage(ProfileTranslations.backToMarketplace)}
        </a>

        <h1>Profil</h1>
      </LayoutHeader>

      <div className="profile-layout">
        <Sidebar />

        <main>
          <RouterRoutes>
            {CATEGORIZED_PANELS.map(({ panels }) =>
              panels.map((panel) => (
                <Route
                  key={panel.path}
                  path={panel.path}
                  element={
                    <>
                      <header>
                        <h2>
                          {panel.icon && <panel.icon />}
                          {formatMessage(panel.title!)}
                        </h2>
                        {panel.subtitle && <p>{formatMessage(panel.subtitle)}</p>}
                      </header>
                      {panel.component}
                    </>
                  }
                />
              )),
            )}
            <Route
              path="*"
              element={<Navigate to={`.${CATEGORIZED_PANELS[0].panels[0].path}`} />}
            />
          </RouterRoutes>
        </main>
      </div>
    </Layout>
  );
}
