import { useState } from 'react';
import AutocompleteAddableSelectInput from '../AutocompleteAddableSelectInput';

const FETCH_URL = `${window.__RUNTIME_CONFIG__.REACT_APP_HERMES_URL}/api/v1/authenticated/autocompletion/schools`;

type SchoolNameAutocompleteSelectInputProps = {
  name: string;
  label?: string;
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  error?: string;
  dataCy?: string;
};

export default function SchoolNameAutocompleteSelectInput({
  name,
  label,
  value,
  placeholder,
  onChange,
  error,
  dataCy,
}: SchoolNameAutocompleteSelectInputProps) {
  const [schoolsNames, setSchoolsNames] = useState<string[]>([]);

  async function onFetch() {
    if (value.length < 3) return;
    const response = await fetch(`${FETCH_URL}?filter[term]=${value}`).catch(() => {
      setSchoolsNames(['no data found']);
    });
    if (!response) return;
    const { data } = await response.json();
    const schools = data.map(({ attributes }: { attributes: { name: string } }) => attributes.name);
    setSchoolsNames(schools);
  }

  return (
    <AutocompleteAddableSelectInput
      dataCy={dataCy}
      label={label}
      name={name}
      placeholder={placeholder}
      value={value}
      values={schoolsNames}
      onChange={onChange}
      onFetch={onFetch}
      error={error}
    />
  );
}
