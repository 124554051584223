import { useMutation } from '@apollo/client';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';
import { UPDATE_USER_PROFILE_COMPLETION } from './gql/ProfileCompletionUserUpdate.gql';
import {
  CompletionContextEnum,
  AvailableLanguagesEnum,
  UpdateUserProfileCompletionMutation,
  UpdateUserProfileCompletionMutationVariables,
} from '../../../@types/graphql';
import { useLanguage } from 'hooks/use-languages/useLanguages';

type UseProfileCompletionUserUpdateOptions = {
  dry?: boolean;
};

export default function useProfileCompletionUserUpdate(options?: UseProfileCompletionUserUpdateOptions) {
  const language = useLanguage();

  const { user, updateUserCompletion } = useProfileCompletionContext();
  const [updateUser] = useMutation<UpdateUserProfileCompletionMutation, UpdateUserProfileCompletionMutationVariables>(
    UPDATE_USER_PROFILE_COMPLETION,
    {
      onCompleted: (data) => {
        if (!data.updateUser || options?.dry) return;
        updateUserCompletion(data.updateUser);
      },
    },
  );

  const context = localStorage.getItem('completion_context');
  return (variables: Omit<UpdateUserProfileCompletionMutationVariables, 'userId' | 'completionContext'>) =>
    updateUser({
      variables: {
        completionContext:
          context === 'contact' ? CompletionContextEnum.ContactAmbassador : CompletionContextEnum.StepByStep,
        locale: language.language === 'fr' ? AvailableLanguagesEnum.Fr : AvailableLanguagesEnum.En,
        userId: user?.id || '',
        ...variables,
      },
    });
}
