import { defineMessages } from 'react-intl';
import { NEW_PASSWORD_ERRORS } from '../../../../../../@types/graphql-errors';

export const errorTranslations = defineMessages({
  [NEW_PASSWORD_ERRORS.EXPIRED_RESET_PASSWORD_TOKEN]: {
    id: 'NewPassword.expiredResetPasswordToken',
    defaultMessage: 'Une erreur est survenue, veuillez nous contacter.',
  },
  [NEW_PASSWORD_ERRORS.USER_PASSWORD_FORMAT_INVALID]: {
    id: 'NewPassword.passwordInvalid',
    defaultMessage: 'Une erreur est survenue, veuillez nous contacter.',
  },
});

export default defineMessages({
  confirmation: {
    id: 'NewPassword.confirmation',
    defaultMessage: 'Votre mot de passe a été modifié !',
  },
});
