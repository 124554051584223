import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import TextAreaInput from 'components/text-area-input/TextAreaInput';
import { useFormik } from 'formik';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import { CommonTranslations } from 'i18n/common.translations';
import { useIntl } from 'react-intl';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { useProfileCompletionContext } from '../ProfileCompletionContext.hook';
import { aspirationPresentationValidationSchema } from './panels.validation';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';

type FormikValues = {
  aspirationPresentation: string;
};

export default function AspirationPresentationPanel() {
  const intl = useIntl();

  const { user } = useProfileCompletionContext();
  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikValues>({
    initialValues: {
      aspirationPresentation: '',
    },
    validationSchema: aspirationPresentationValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async ({ aspirationPresentation }) => {
      networkState.loading();
      await updateUser({ aspirationPresentation });
      networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextAreaInput
        dataCy="aspiration-presentation"
        name="aspirationPresentation"
        label={user?.presentationTranslations.aspirationsQuestion}
        value={formik.values.aspirationPresentation}
        onChange={formik.handleChange}
        info={user?.presentationTranslations.aspirationsExample}
      />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        submit
        disabled={!formik.isValid}
      />
    </form>
  );
}
