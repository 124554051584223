import { useIntl } from 'react-intl';
import IconLinkedInConnect from './icon-linkedin-connect.svg';
import { AuthenticationTranslations } from 'scenes/authentication/Authentication.translations';
import styles from './LinkedinButton.module.scss';

type LinkedinButtonProps = {
  onClick: () => void;
};

export default function LinkedinButton({ onClick }: LinkedinButtonProps) {
  const intl = useIntl();
  return (
    <button
      onClick={onClick}
      className={styles.linkedinButton}
      type="button"
    >
      <img
        src={IconLinkedInConnect}
        alt="Ic&ocirc;ne LinkedIn"
        width="34"
        height="31"
      />
      <span
        data-testid="linkedin-button-text"
        className={styles.text}
      >
        {intl.formatMessage(AuthenticationTranslations.linkedIn)}
      </span>
    </button>
  );
}
