import { defineMessages } from 'react-intl';

export const AvatarInputTranslations = defineMessages({
  modalTitle: {
    id: 'AvatarInputTranslations.modalTitle',
    defaultMessage: 'Recadrer votre avatar',
  },
  modalSubtitle: {
    id: 'AvatarInputTranslations.modalSubtitle',
    defaultMessage: 'Recadrer votre photo pour centrer votre visage.',
  },
  chooseAvatarButton: {
    id: 'AvatarInputTranslations.chooseAvatarButton',
    defaultMessage: 'Choisir une photo',
  },
  resetAvatarButton: {
    id: 'AvatarInputTranslations.resetAvatarButton',
    defaultMessage: 'Changer de photo',
  },
});
