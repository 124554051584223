import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'NewPassword.title',
    defaultMessage: 'Modifier votre mot de passe',
  },
  description: {
    id: 'NewPassword.description',
    defaultMessage: 'Saisissez votre nouveau mot de passe dans le champ ci-dessous.',
  },
});
