import { gql } from '@apollo/client';

export const UPLOAD_GALLERY_IMAGE_MUTATION = gql`
  mutation UploadGalleryImage($image: Upload!, $userId: ID!) {
    uploadGalleryImage(image: $image, userId: $userId) {
      id
      imageUrl
    }
  }
`;

export const REMOVE_GALLERY_IMAGE_MUTATION = gql`
  mutation RemoveGalleryImage($imageId: ID!, $userId: ID!) {
    removeGalleryImage(imageId: $imageId, userId: $userId) {
      id
    }
  }
`;
