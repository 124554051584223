import { IconProps } from './Icon';

export default function BriefcaseIcon({ className }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M13.3332 5.83333C13.3332 5.05836 13.3332 4.67087 13.248 4.35295C13.0168 3.49022 12.3429 2.81635 11.4802 2.58519C11.1623 2.5 10.7748 2.5 9.99984 2.5C9.22486 2.5 8.83737 2.5 8.51946 2.58519C7.65673 2.81635 6.98286 3.49022 6.75169 4.35295C6.6665 4.67087 6.6665 5.05836 6.6665 5.83333M4.33317 17.5H15.6665C16.5999 17.5 17.0666 17.5 17.4232 17.3183C17.7368 17.1586 17.9917 16.9036 18.1515 16.59C18.3332 16.2335 18.3332 15.7668 18.3332 14.8333V8.5C18.3332 7.56658 18.3332 7.09987 18.1515 6.74335C17.9917 6.42975 17.7368 6.17478 17.4232 6.01499C17.0666 5.83333 16.5999 5.83333 15.6665 5.83333H4.33317C3.39975 5.83333 2.93304 5.83333 2.57652 6.01499C2.26292 6.17478 2.00795 6.42975 1.84816 6.74335C1.6665 7.09987 1.6665 7.56658 1.6665 8.5V14.8333C1.6665 15.7668 1.6665 16.2335 1.84816 16.59C2.00795 16.9036 2.26292 17.1586 2.57652 17.3183C2.93304 17.5 3.39975 17.5 4.33317 17.5Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
