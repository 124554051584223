import { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import formikError from '../../../components/formik-helper/formik.helper';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { ambassadorPresentationPanelValidationSchema } from './panels.validation';
import { CommonTranslations } from 'i18n/common.translations';
import SecondaryButton from 'components/secondary-button/SecondaryButton';
import RefreshIcon from 'assets/component-icons/RefreshIcon';
import useUserContextUpdate from 'contexts/user/UserContextUpdate.hook';
import { UserContext } from 'contexts/user/UserContext';
import TextAreaInput from 'components/text-area-input/TextAreaInput';
import useFormikNetworkState from 'hooks/use-formik-network-state';

type FormikValues = {
  ambassadorPresentation: string;
  expertisePresentation: string;
  aspirationPresentation: string;
  interestsPresentation: string;
};

export default function AmbassadorPresentationPanel() {
  const intl = useIntl();

  const { user } = useContext(UserContext);
  const updateUser = useUserContextUpdate();

  const networkState = useFormikNetworkState();

  const formik = useFormik<FormikValues>({
    initialValues: {
      ambassadorPresentation: user?.ambassadorPresentation || '',
      expertisePresentation: user?.expertisePresentation || '',
      aspirationPresentation: user?.aspirationPresentation || '',
      interestsPresentation: user?.interestsPresentation || '',
    },
    validationSchema: ambassadorPresentationPanelValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async (values) => {
      networkState.loading();
      const response = await updateUser(values);
      response.errors ? networkState.error() : networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextAreaInput
        name="ambassadorPresentation"
        label={user?.presentationTranslations.ambassadorPresentationQuestion}
        value={formik.values.ambassadorPresentation}
        onChange={formik.handleChange}
        info={user?.presentationTranslations.ambassadorPresentationExample}
        error={formikError(formik.touched, formik.errors, 'ambassadorPresentation')}
      />

      <TextAreaInput
        name="interestsPresentation"
        label={user?.presentationTranslations.interestsQuestion}
        value={formik.values.interestsPresentation}
        onChange={formik.handleChange}
        info={user?.presentationTranslations.interestsExample}
        error={formikError(formik.touched, formik.errors, 'interestsPresentation')}
      />

      <TextAreaInput
        name="aspirationPresentation"
        label={user?.presentationTranslations.aspirationsQuestion}
        value={formik.values.aspirationPresentation}
        onChange={formik.handleChange}
        info={user?.presentationTranslations.aspirationsExample}
        error={formikError(formik.touched, formik.errors, 'aspirationPresentation')}
      />

      <TextAreaInput
        name="expertisePresentation"
        label={user?.presentationTranslations.expertisesQuestion}
        value={formik.values.expertisePresentation}
        onChange={formik.handleChange}
        info={user?.presentationTranslations.expertisesExample}
        error={formikError(formik.touched, formik.errors, 'expertisePresentation')}
      />

      <footer>
        <PrimaryButton
          label={intl.formatMessage(CommonTranslations.save)}
          icon={networkState.iconBasedOnNetworkState}
          submit
          accent
          leftIcon
          state={networkState.state}
          disabled={!formik.dirty}
        />
        <SecondaryButton
          label={intl.formatMessage(CommonTranslations.reset)}
          leftIcon={RefreshIcon}
          onClick={() => {}}
          iconButtonWithText
        />
      </footer>
    </form>
  );
}
