import { defineMessages } from 'react-intl';

export const PremiumOfferTranslations = defineMessages({
	mention1: {
		id: 'PremiumOfferTranslations.mention1',
		defaultMessage: 'Vos collaborateurs disponibles sur la plateforme sans limite de nombre',
	},
	mention2: {
		id: 'PremiumOfferTranslations.mention2',
		defaultMessage: 'Rendez-vous avec les membres de My Job Glasses',
	},
	mention3: {
		id: 'PremiumOfferTranslations.mention3',
		defaultMessage: 'Votre logo sur les profils de vos collaborateurs',
	},
	mention4: {
		id: 'PremiumOfferTranslations.mention4',
		defaultMessage: 'Kit de communication standard et QR code d\'inscription à partager',
	},
	mention5: {
		id: 'PremiumOfferTranslations.mention5',
		defaultMessage: 'Vos Ambassadeurs priorisés dans le moteur de recherche',
	},
	mention6: {
		id: 'PremiumOfferTranslations.mention6',
		defaultMessage: 'Cartes et bannières des profils de vos Ambassadeurs personnalisées',
	},
	mention7: {
		id: 'PremiumOfferTranslations.mention7',
		defaultMessage: 'Pilotage de vos Ambassadeurs',
	},
	mention8: {
		id: 'PremiumOfferTranslations.mention8',
		defaultMessage: 'Donnés sur l\'activité de vos Ambassadeurs',
	},
	mention9: {
		id: 'PremiumOfferTranslations.mention9',
		defaultMessage: 'Données sur les membres rencontrés et sur leur projection dans l’entreprise',
	},
	mention10: {
		id: 'PremiumOfferTranslations.mention10',
		defaultMessage: 'Le widget My Job Glasses, avec vos Ambassadeurs sur votre site carrière',
	}
});
