import { useFormik } from 'formik';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import { useIntl } from 'react-intl';
import PhoneIcon from 'assets/component-icons/PhoneIcon';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { phonePanelValidationSchema } from './panels.validation';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import PhoneInput from '../../../components/phone-input/PhoneInput';
import { PhonePanelTranslations } from './panels.translations';
import formikError from '../../../components/formik-helper/formik.helper';
import { CommonTranslations } from 'i18n/common.translations';
import useFormikNetworkState from 'hooks/use-formik-network-state';

type FormikValues = {
  phoneNumber: string;
};

export default function PhonePanel() {
  const intl = useIntl();

  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikValues>({
    initialValues: {
      phoneNumber: '',
    },
    validationSchema: phonePanelValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async ({ phoneNumber }) => {
      networkState.loading();
      await updateUser({ normalizedPhone: phoneNumber });
      networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <PhoneInput
        icon={PhoneIcon}
        name="phoneNumber"
        label={intl.formatMessage(PhonePanelTranslations.phone)}
        value={formik.values.phoneNumber}
        onChange={(phoneNumber) => {
          formik.setFieldValue('phoneNumber', phoneNumber);
        }}
        error={formikError(formik.touched, formik.errors, 'phoneNumber')}
      />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        icon={networkState.iconBasedOnNetworkState}
        onClick={formik.handleSubmit}
        disabled={!formik.dirty}
      />
    </form>
  );
}
