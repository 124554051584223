import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from '@myjobglasses/account-ui';
import MailIcon from 'assets/component-icons/MailIcon';
import formikError from 'components/formik-helper/formik.helper';
import { LayoutHeader } from 'components/layout/Layout';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import TextInput from 'components/text-input/TextInput';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import {
  AdminHrSignupMutation,
  AdminHrSignupMutationVariables,
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityQueryVariables,
  GenderEnum,
  Get_Company_SectorsQuery,
  Get_Company_SectorsQueryVariables,
  HrChallengeKeyEnum,
  SignupErrorEnum,
} from '../../../../@types/graphql';
import { CHECK_EMAIL_AVAILABILITY_QUERY } from '../../../authentication/gql/CheckEmailAvailability.gql';
import { ReactNode, useCallback, useContext } from 'react';
import { SignupErrorTranslations } from 'scenes/signup/Signup.translations';
import { HrAdminSignupFormTranslations } from './HrAdminSignupForm.translations';
import PasswordInput from 'components/password-input/PasswordInput';
import LockIcon from 'assets/component-icons/LockIcon';
import SelectInput from 'components/select-input/SelectInput';
import { GenderTranslations } from 'i18n/gender.translations';
import CheckboxInput from 'components/checkbox-input/CheckboxInput';
import { ADMIN_RH_SIGN_UP_MUTATION, GET_COMPANY_SECTORS_QUERY } from './HrAdminSignup.gql';
import { SettingsContext } from 'contexts/settings/SettingsContext';
import validationSchema from './HrAdminSignupForm.validations';
import Routes from 'Routes';
import PhoneInput from 'components/phone-input/PhoneInput';
import PhoneIcon from 'assets/component-icons/PhoneIcon';
import { AuthenticationErrorTranslations } from 'scenes/authentication/Authentication.translations';
import { useNavigate } from 'react-router-dom';
import UploadLogoInput from 'components/upload-logo-input/UploadLogoInput';
import StaticAutocompleteMultiSelectInput from 'components/static-autocomplete-multi-select-input/StaticAutocompleteMultiSelectInput';
import RemovableTag from 'components/removable-tag/RemovableTag';
import { CommonInputFormTranslations } from 'i18n/common.translations';

import './HrAdminSignupForm.scss';

type FormikFields = {
  email: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  gender?: GenderEnum | null;
  firstName: string;
  lastName: string;
  jobTitle: string;
  companyName: string;
  companySize: number | null;
  companySector: string;
  hrChallenges: HrChallengeKeyEnum[];
  companyLogoUploadId: string;
  cgu: boolean;
};

export default function HrAdminSignupForm() {
  const snackbar = useSnackbar();
  const intl = useIntl();
  const settingsContext = useContext(SettingsContext);
  const navigate = useNavigate();

  const [checkEmailAvailability] = useLazyQuery<CheckEmailAvailabilityQuery, CheckEmailAvailabilityQueryVariables>(
    CHECK_EMAIL_AVAILABILITY_QUERY,
  );

  const [adminRhSignup] = useMutation<AdminHrSignupMutation, AdminHrSignupMutationVariables>(ADMIN_RH_SIGN_UP_MUTATION);
  const { data: companySectors } = useQuery<Get_Company_SectorsQuery, Get_Company_SectorsQueryVariables>(
    GET_COMPANY_SECTORS_QUERY,
  );

  const formik = useFormik<FormikFields>({
    initialValues: {
      email: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
      gender: null,
      firstName: '',
      lastName: '',
      jobTitle: '',
      companyName: '',
      companySize: null,
      companySector: '',
      hrChallenges: [],
      companyLogoUploadId: '',
      cgu: false,
    },
    validationSchema: validationSchema(intl),
    validateOnMount: true,
    onSubmit: async (values) => {
      const {
        email,
        phoneNumber,
        password,
        gender,
        firstName,
        lastName,
        jobTitle,
        companyName,
        companySize,
        companySector,
        hrChallenges,
        companyLogoUploadId,
      } = values;

      const checkEmail = await checkEmailAvailability({
        variables: {
          email,
        },
      });

      if (!checkEmail.data?.checkEmailAvailability?.available) {
        snackbar.error(intl.formatMessage(SignupErrorTranslations.emailTaken));
      } else {
        try {
          await adminRhSignup({
            variables: {
              email,
              password,
              gender,
              firstName,
              lastName,
              jobTitle,
              companyName,
              companySize: companySize! as number,
              companySector,
              companyLogoUploadId,
              hrChallenges,
              phone: phoneNumber,
            },
          });
        } catch (error) {
          const errorCodes = (error as ApolloError).graphQLErrors.map(({ extensions }) => extensions?.code);
          const emailState = { state: { email } };

          switch (true) {
            case errorCodes.includes(SignupErrorEnum.EmailInvalid):
              snackbar.error(intl.formatMessage(AuthenticationErrorTranslations.emailInvalid));
              break;
            case errorCodes.includes(SignupErrorEnum.EmailForbiddenDomain):
              snackbar.error(intl.formatMessage(AuthenticationErrorTranslations.emailForbiddenDomain));
              break;
            case errorCodes.includes(SignupErrorEnum.EmailUnconfirmed):
              navigate(Routes.mustConfirmEmail, emailState);
              break;
            default:
              break;
          }
        }
      }
    },
  });

  const addHrChallenges = useCallback(
    (hrChallenge: HrChallengeKeyEnum) => {
      if (!formik.values.hrChallenges.includes(hrChallenge))
        formik.setFieldValue('hrChallenges', [...formik.values.hrChallenges, hrChallenge]);
    },
    [formik.values.hrChallenges],
  );

  const removeHrChallenges = useCallback(
    (hrChallenge: HrChallengeKeyEnum) => {
      formik.setFieldValue(
        'hrChallenges',
        formik.values.hrChallenges.filter((v) => v !== hrChallenge),
      );
    },
    [formik.values.hrChallenges],
  );

  return (
    <div className="form-section">
      <LayoutHeader>
        <h1>{intl.formatMessage(HrAdminSignupFormTranslations.title)}</h1>
        <p>{intl.formatMessage(HrAdminSignupFormTranslations.subtitle)}</p>
      </LayoutHeader>
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          dataCy="email"
          name="email"
          label={intl.formatMessage(CommonInputFormTranslations.emailPro)}
          value={formik.values.email}
          onChange={formik.handleChange}
          icon={MailIcon}
          error={formikError(formik.touched, formik.errors, 'email') as string}
        />

        <PhoneInput
          icon={PhoneIcon}
          name="phoneNumber"
          label={intl.formatMessage(CommonInputFormTranslations.phone)}
          value={formik.values.phoneNumber}
          onChange={(phoneNumber) => {
            formik.setFieldValue('phoneNumber', phoneNumber);
          }}
          error={formikError(formik.touched, formik.errors, 'phoneNumber') as string}
        />

        <PasswordInput
          dataCy="password"
          strengthHints
          name="password"
          label={intl.formatMessage(CommonInputFormTranslations.password)}
          placeholder="••••••••••••"
          icon={LockIcon}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formikError(formik.touched, formik.errors, 'password') as string}
        />

        <PasswordInput
          dataCy="confirm-password"
          name="confirmPassword"
          label={intl.formatMessage(CommonInputFormTranslations.passwordConfirmation)}
          placeholder="••••••••••••"
          icon={LockIcon}
          value={formik.values.confirmPassword}
          confirm
          passwordToConfirm={formik.values.password}
          onChange={formik.handleChange}
          error={formikError(formik.touched, formik.errors, 'confirmPassword') as string}
        />

        <SelectInput
          dataCy="gender-select"
          name="gender"
          label={intl.formatMessage(CommonInputFormTranslations.gender)}
          values={Object.values(GenderEnum).map((gender) => ({
            value: gender,
            translation: intl.formatMessage(GenderTranslations[gender]),
          }))}
          onChange={(gender) => formik.setFieldValue('gender', gender)}
          placeholder={intl.formatMessage(CommonInputFormTranslations.genderPlaceholder)}
          error={formikError(formik.touched, formik.errors, 'gender') as GenderEnum}
        />

        <TextInput
          dataCy="first-name"
          name="firstName"
          label={intl.formatMessage(CommonInputFormTranslations.firstName)}
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formikError(formik.touched, formik.errors, 'firstName') as string}
        />

        <TextInput
          dataCy="last-name"
          name="lastName"
          label={intl.formatMessage(CommonInputFormTranslations.lastName)}
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formikError(formik.touched, formik.errors, 'lastName') as string}
        />

        <TextInput
          dataCy="job-title"
          name="jobTitle"
          label={intl.formatMessage(CommonInputFormTranslations.job)}
          value={formik.values.jobTitle}
          onChange={formik.handleChange}
          error={formikError(formik.touched, formik.errors, 'jobTitle') as string}
        />

        <TextInput
          dataCy="company-name"
          name="companyName"
          label={intl.formatMessage(CommonInputFormTranslations.companyName)}
          value={formik.values.companyName}
          onChange={formik.handleChange}
          error={formikError(formik.touched, formik.errors, 'companyName') as string}
        />

        <TextInput
          dataCy="company-size"
          name="companySize"
          label={intl.formatMessage(CommonInputFormTranslations.companySize)}
          value={formik.values.companySize ?? ''}
          type="number"
          onChange={formik.handleChange}
          error={formikError(formik.touched, formik.errors, 'companySize') as string}
        />

        <SelectInput
          dataCy="company-sector"
          name="companySector"
          label={intl.formatMessage(CommonInputFormTranslations.companySector)}
          values={(companySectors?.companySectors || []).map(({ id, name }) => ({
            value: id,
            translation: name,
          }))}
          onChange={(companySector) => formik.setFieldValue('companySector', companySector)}
          error={formikError(formik.touched, formik.errors, 'companySector') as string}
        />

        <StaticAutocompleteMultiSelectInput
          dataCy="hr-challenge"
          name="hrChallenges"
          label={intl.formatMessage(CommonInputFormTranslations.hrChallenge)}
          values={(settingsContext?.settings?.hrChallenges || []).map(({ key, name }) => ({
            value: key,
            translation: name,
          }))}
          selectedValues={formik.values.hrChallenges || []}
          onChange={(value: string) => addHrChallenges(value as HrChallengeKeyEnum)}
          error={formikError(formik.touched, formik.errors, 'hrChallenges') as string}
        />
        <div className="hrChallenges__tags">
          {formik.values.hrChallenges.map((hrChallenge) => (
            <RemovableTag
              key={hrChallenge}
              value={hrChallenge}
              name={settingsContext?.settings?.hrChallenges.find(({ key }) => key === hrChallenge)?.name || ''}
              onRemove={removeHrChallenges}
            />
          ))}
        </div>

        <UploadLogoInput
          onChange={(id) => formik.setFieldValue('companyLogoUploadId', id)}
          label={intl.formatMessage(CommonInputFormTranslations.companyLogoLabel)}
        />

        <CheckboxInput
          name="cgu"
          label={intl.formatMessage<ReactNode>(CommonInputFormTranslations.cgu, {
            cguLink: (text) => (
              <a
                href="https://www.myjobglasses.com/conditions-generales-dutilisation"
                target="_blank"
                rel="noreferrer"
              >
                {text}
              </a>
            ),
            privacyLink: (text) => (
              <a
                href="https://www.myjobglasses.com/confidentialite-communaute-connect"
                target="_blank"
                rel="noreferrer"
              >
                {text}
              </a>
            ),
          })}
          checked={formik.values.cgu}
          onChange={formik.handleChange}
          error={formikError(formik.touched, formik.errors, 'cgu') as string}
        />

        <PrimaryButton
          label={intl.formatMessage(CommonInputFormTranslations.createAccount)}
          disabled={!formik.dirty}
          submit
        />
      </form>
    </div>
  );
}
