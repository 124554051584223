import { gql } from '@apollo/client';

export const OAUTH_MUTATION = gql`
  mutation OAUTH($authorizationCode: String!, $provider: OauthProviderEnum!, $redirectUri: String!) {
    oauth(authorizationCode: $authorizationCode, provider: $provider, redirectUri: $redirectUri) {
      email
      firstName
      lastName
      nonce
    }
  }
`;
