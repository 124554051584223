import { useMemo } from 'react';
import './StrengthProgressBar.scss';
import CheckIcon from 'assets/component-icons/CheckIcon';
import classNames from 'classnames';
import XIcon from 'assets/component-icons/XIcon';

type ConfirmProgressBarProps = {
  password: string;
  passwordToConfirm?: string;
};

export default function ConfirmProgressBar({ password, passwordToConfirm }: ConfirmProgressBarProps) {
  const passwordEquals = useMemo(() => password === passwordToConfirm, [password, passwordToConfirm]);

  const Icon = useMemo(() => {
    if (password.length === 0) {
      return null;
    }
    if (passwordEquals) {
      return <CheckIcon className="strength-progress-bar__icon" />;
    }
    return <XIcon className={classNames('strength-progress-bar__icon', 'strength-progress-bar__icon--error')} />;
  }, [password, passwordToConfirm, passwordEquals]);

  return (
    <div className="strength-progress-bar">
      {Icon}
      <div className="strength-progress-bar__progress">
        <div
          className={classNames('strength-progress-bar__progress__inner', {
            'strength-progress-bar__progress__inner--strong': passwordEquals,
          })}
          style={{ width: password.length > 0 ? '100%' : 0 }}
        />
      </div>
    </div>
  );
}
