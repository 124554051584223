import { useContext } from 'react';
import { ModalContext, ModalState } from './Modal.context';

export default function useModal(): [(modal: Omit<ModalState, 'isVisible'>) => void, () => void] {
  const dispatch = useContext(ModalContext);

  function openModal(modal: Omit<ModalState, 'isVisible'>) {
    dispatch?.({ type: 'show', payload: modal });
  }

  function closeModal() {
    dispatch?.({ type: 'close', payload: {} });
  }

  return [openModal, closeModal];
}
