import * as Yup from 'yup';
import {
  containsDigit,
  containsLowercase,
  containsSpecialCharacter,
  containsUppercase,
  haveMinimumLength,
} from '../../../../../../components/check-password/utils/password.util';

export default () =>
  Yup.object().shape({
    password: Yup.string()
      .ensure()
      .test({
        name: 'password specs',
        exclusive: true,
        test: (value) =>
          containsUppercase(value) &&
          containsLowercase(value) &&
          containsDigit(value) &&
          containsSpecialCharacter(value) &&
          haveMinimumLength(value),
      })
      .required(),
  });
