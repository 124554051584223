import LockIcon from 'assets/component-icons/LockIcon';
import UserIcon from 'assets/component-icons/UserIcon';
import Layout, { LayoutHeader } from 'components/layout/Layout';
import SelectBlock from 'components/select-block/SelectBlock';
import { useIntl } from 'react-intl';
import { Location, useLocation } from 'react-router-dom';
import { goToHRAdmin, goToMarketplace, goToVocation, goToHRAdminConnect, goToSchoolConnectWithId } from 'utils/redirections';
import './Segmentation.scss';
import { SegmentationTranslations } from './Segmentation.translations';
import UsersIcon from 'assets/component-icons/UsersIcon';
import useModal from 'components/modal/Modal.hook';
import { useQuery } from '@apollo/client';
import { Get_Current_UserQuery, Get_Current_UserQueryVariables } from '../../../@types/graphql';
import { GET_CURRENT_USER } from '../gql/CurrentUser.gql';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import ButtonsGroup from 'components/buttons-group/ButtonsGroup';
import BuildingIcon from 'assets/component-icons/BuildingIcon';

interface LocationState {
  isCoach?: boolean;
  isCompanyHRAdmin?: boolean;
  isSchoolHRAdmin?: boolean;
}

export default function Segmentation() {
  const { formatMessage } = useIntl();
  const [openModal, closeModal] = useModal();
  const {
    state: { isCoach, isCompanyHRAdmin, isSchoolHRAdmin },
  }: Location<LocationState> = useLocation();

  const user = useQuery<Get_Current_UserQuery, Get_Current_UserQueryVariables>(GET_CURRENT_USER, {});
                                                                                                                                                  
  const handlegoToHRAdmin = () => {
    if (user?.data?.currentUserV2?.companyCreationRequest?.statusCd === 'pending') {
      openModal({
        title: formatMessage(SegmentationTranslations.hrAdminTitle),
        content: (
          <>
            <p>{formatMessage(SegmentationTranslations.moderationPendingModal)}</p>
            <ButtonsGroup fluid>
              <PrimaryButton
                onClick={closeModal}
                label={formatMessage(SegmentationTranslations.closeModal)}
              />
            </ButtonsGroup>
          </>
        ),
      });
    } else {
      goToHRAdminConnect();
    }
  };
  return (
    <Layout withAsideImage>
      <LayoutHeader>
        <h1>{formatMessage(SegmentationTranslations.title)}</h1>
        <p>{formatMessage(SegmentationTranslations.subtitle)}</p>
      </LayoutHeader>

      <div className="segmentation-page">
        {isSchoolHRAdmin && (
          <SelectBlock
            id="hr-admin"
            icon={LockIcon}
            title={formatMessage(SegmentationTranslations.schoolHrAdminTitle)}
            description={formatMessage(SegmentationTranslations.schoolHrAdminDescription)}
            onClick={() => {
              const schoolId = user?.data?.currentUserV2?.schoolAdminProfiles?.[0]?.company.id;
              if (schoolId) {
                goToSchoolConnectWithId({ schoolId });
              }
            }}
          />
        )}

        {isCompanyHRAdmin && (
          <SelectBlock
            id="hr-admin"
            icon={BuildingIcon}
            title={formatMessage(SegmentationTranslations.hrAdminTitle)}
            description={formatMessage(SegmentationTranslations.hrAdminDescription)}
            onClick={handlegoToHRAdmin}
          />
        )}

        {isCoach && (
          <SelectBlock
            id="coach"
            icon={UsersIcon}
            title={formatMessage(SegmentationTranslations.coachTitle)}
            description={formatMessage(SegmentationTranslations.coachDescription)}
            onClick={goToVocation}
          />
        )}

        <SelectBlock
          id="marketplace"
          icon={UserIcon}
          title={formatMessage(SegmentationTranslations.mjgTitle)}
          description={formatMessage(SegmentationTranslations.mjgDescription)}
          onClick={goToMarketplace}
        />
      </div>
    </Layout>
  );
}
