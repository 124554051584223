import { useEffect } from 'react';
import './EducationBlock.scss';
import { DiplomaEnum, UserPartsFragment } from '../../../../@types/graphql';
import { useIntl } from 'react-intl';
import { DiplomaTypeTranslations } from 'i18n/diploma-type.translations';
import moment from 'moment';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';
import PencilIcon from 'assets/component-icons/PencilIcon';
import 'moment/locale/fr'; // Importer la locale française

type EducationBlockProps = {
  education: UserPartsFragment['educations'][0];
  onModificationModalRequest: (education: UserPartsFragment['educations'][0]) => void;
};

export default function EducationBlock({ education, onModificationModalRequest }: EducationBlockProps) {
  const { formatMessage, locale } = useIntl();

  useEffect(() => {
    moment.locale(locale);
  }, [locale]);

  return (
    <li className="education-block">
      <button
        className="education-block__edit-button"
        onClick={(e) => {
          e.preventDefault();
          onModificationModalRequest(education);
        }}
      >
        <PencilIcon />
      </button>
      <div className="education-block__infos">
        <div className="education-block__infos__diploma">
          {formatMessage(DiplomaTypeTranslations[education.diplomaType || DiplomaEnum.None])} - {education.diplomaTitle}
        </div>
        <div className="education-block__infos__school-name">{education.schoolName}</div>
      </div>
      <div className="education-block__period">
      {education.startedAt && (
        <>
          {moment(education.startedAt).format('MMMM YYYY')}
          <ArrowRightIcon />
        </>
      )}
        {education.endedAt && moment(education.endedAt).format('YYYY')}
      </div>
    </li>
  );
}
