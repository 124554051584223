import { useIntl } from 'react-intl';
import classnames from 'classnames';
import {
  containsUppercase,
  containsLowercase,
  containsDigit,
  containsSpecialCharacter,
  haveMinimumLength,
  haveOptimalLength,
} from '../../utils/password.util';
import translations from './PasswordStrength.translations';

import styles from './PasswordStrength.module.scss';

interface PasswordStrengthProps {
  password: string;
  doCheck: boolean;
}

enum PasswordStrengthEnum {
  TooWeak = 0,
  Strong = 1,
  VeryStrong = 2,
}

function PasswordStrength({ password, doCheck }: PasswordStrengthProps) {
  const intl = useIntl();
  let currentPasswordStrength = 0;
  let text = intl.formatMessage(translations.tooWeak);

  if (
    containsUppercase(password) &&
    containsLowercase(password) &&
    containsDigit(password) &&
    containsSpecialCharacter(password) &&
    haveMinimumLength(password)
  ) {
    currentPasswordStrength = 1;
    text = intl.formatMessage(translations.strong);
  }
  if (
    containsUppercase(password) &&
    containsLowercase(password) &&
    containsDigit(password) &&
    containsSpecialCharacter(password) &&
    haveOptimalLength(password)
  ) {
    currentPasswordStrength = 2;
    text = intl.formatMessage(translations.veryStrong);
  }

  return (
    <div
      data-testid="password-strength"
      className={styles.PasswordStrengthContainer}
    >
      <div
        className={classnames(styles.strengthIndicator, {
          [styles.green]: currentPasswordStrength > PasswordStrengthEnum.TooWeak,
          [styles.red]: doCheck && currentPasswordStrength === PasswordStrengthEnum.TooWeak,
        })}
      />
      <div
        className={classnames(styles.strengthIndicator, {
          [styles.green]: currentPasswordStrength > PasswordStrengthEnum.TooWeak,
        })}
      />
      <div
        className={classnames(styles.strengthIndicator, {
          [styles.green]: currentPasswordStrength > PasswordStrengthEnum.Strong,
        })}
      />
      {doCheck && (
        <p
          className={classnames(styles.strengthText, {
            [styles.green]: currentPasswordStrength > PasswordStrengthEnum.TooWeak,
            [styles.red]: currentPasswordStrength === PasswordStrengthEnum.TooWeak,
          })}
        >
          {text}
        </p>
      )}
    </div>
  );
}

export default PasswordStrength;
