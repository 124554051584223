import { useIntl } from 'react-intl';
import { ContactAppointmentTranslations } from './ContactAppointment.translations';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import { goToConnectDiscoverOffers } from 'utils/redirections';

import './ContactAppointment.scss';
import ahoy from 'utils/ahoy_api';

export default function ContactAppointment() {
  const intl = useIntl();

  return (
    <div className="contact-container">
      <h2>{intl.formatMessage(ContactAppointmentTranslations.title)}</h2>

      <PrimaryButton
        label={intl.formatMessage(ContactAppointmentTranslations.takeAppointment)}
        outlined
        onClick={() => {
          ahoy.track('b2b_company_signup_clicked_ask_demo', {});
          goToConnectDiscoverOffers()
        }}
        smaller
      />
    </div>
  );
}
