import { defineMessages } from 'react-intl';

export default defineMessages({
  success: {
    id: 'ConfirmEmail.success',
    defaultMessage: 'Félicitations, votre adresse email a bien été confirmée.',
  },
  error: {
    id: 'ConfirmEmail.error',
    defaultMessage: 'Une erreur est survenue, veuillez nous contacter.',
  },
  alreadyConfirmed: {
    id: 'ConfirmEmail.alreadyConfirmed',
    defaultMessage:
      "Votre email a déjà été confirmé. Merci de vous connecter. Si vous n'aviez pas encore créé votre mot de passe vous pouvez cliquer sur 'Mot de passe oublié'.",
  },
  close: {
    id: 'ConfirmEmail.close',
    defaultMessage: 'X',
  },
});
