import { IconProps } from './Icon';
import './LoadingIcon.scss';
import classNames from 'classnames';

export default function LoadingIcon({ className }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={classNames('loading-icon', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="General">
        <g id="loading-02">
          <path
            id="Icon"
            d="M12 2V6M12 18V22M6 12H2M22 12H18M19.0784 19.0784L16.25 16.25M19.0784 4.99994L16.25 7.82837M4.92157 19.0784L7.75 16.25M4.92157 4.99994L7.75 7.82837"
            stroke="inherit"
            strokeWidth="inherit"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}
