import { gql } from '@apollo/client';

export const CHECK_EMAIL_AVAILABILITY_QUERY = gql`
  query CheckEmailAvailability($email: String!) {
    checkEmailAvailability(email: $email) {
      available
      isConfirmed
    }
  }
`;
