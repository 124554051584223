import { ComponentType } from 'react';
import './RangeInput.scss';
import { IconProps } from 'assets/component-icons/Icon';
import classNames from 'classnames';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

// eslint-disable-next-line max-len
type RangeInputProps = {
  icon?: ComponentType<IconProps>;
  name?: string;
  label?: string;
  suffix?: string;
  error?: string;
  value: number;
  onChange: (v: number | number[]) => void;
  min: number;
  max: number;
};

export default function RangeInput({
  icon: Icon,
  name,
  label,
  value,
  onChange,
  error,
  min,
  max,
  suffix,
}: RangeInputProps) {
  return (
    <div className="range-input">
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error: error })}
        >
          {label}
        </label>
      )}

      <div
        className={classNames('range-input__main', {
          'range-input__main--error': error,
        })}
      >
        {Icon && <Icon className="range-input__main__icon" />}

        <Slider
          {...{
            value,
            min,
            max,
            onChange,
          }}
          className="range-input__main__input"
          dots={true}
        />

        <p className="range-input__main__suffix">
          <span className="range-input__main__suffix__value">{value}</span>
          <span>{suffix}</span>
        </p>
      </div>

      {error && <span className="range-input__error">{error}</span>}
    </div>
  );
}
