import { defineMessages } from 'react-intl';
import { Language } from '../@types/graphql';

export const RecommendedQualificationsTranslations = defineMessages({
  bac_gt: {
    id: 'RecommendedQualificationsTranslations.bac_gt',
    defaultMessage: 'Bac Général',
  },
  bac_pro: {
    id: 'RecommendedQualificationsTranslations.bac_pro',
    defaultMessage: 'Bac Professionnel',
  },
  bac_2: {
    id: 'RecommendedQualificationsTranslations.bac_2',
    defaultMessage: 'Bac +2',
  },
  bac_3: {
    id: 'RecommendedQualificationsTranslations.bac_3',
    defaultMessage: 'Bac +3',
  },
  bac_4: {
    id: 'RecommendedQualificationsTranslations.bac_4',
    defaultMessage: 'Bac +4',
  },
  bac_5: {
    id: 'RecommendedQualificationsTranslations.bac_5',
    defaultMessage: 'Bac +5',
  },
  bac_superior_5: {
    id: 'RecommendedQualificationsTranslations.bac_superior_5',
    defaultMessage: 'Bac >+5',
  },
  bep_cap: {
    id: 'RecommendedQualificationsTranslations.bep_cap',
    defaultMessage: 'CAP/BEP',
  },
  specific_formation: {
    id: 'RecommendedQualificationsTranslations.specific_formation',
    defaultMessage: 'Formation spécifique',
  },
  none: {
    id: 'RecommendedQualificationsTranslations.none',
    defaultMessage: 'Sans formation particulière',
  },
});
