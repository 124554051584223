import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'MustProvideTutorEmail.title',
    defaultMessage: "Vous n'avez pas la majorité numérique.",
  },
  subtitle: {
    id: 'MustProvideTutorEmail.description',
    defaultMessage: `Vous pouvez tout à fait créer un compte et utiliser les services de My Job Glasses.
Cependant, vous devez au préalable faire confirmer cette inscription par l’un de vos parents ou représentant légal pour activer votre compte.
Un email lui sera envoyé avec un lien cliquable lui permettant de confirmer votre inscription.`,
  },
  emailLabel: {
    id: 'MustProvideTutorEmail.emailLabel',
    defaultMessage: 'Email du parent ou responsable légal',
  },
  buttonText: {
    id: 'MustProvideTutorEmail.buttonText',
    defaultMessage: 'Envoyer la demande de validation',
  },
});
