import Layout, { LayoutHeader } from 'components/layout/Layout';
import { useIntl } from 'react-intl';
import translations from './ConfirmedEmail.translations';
import PrimaryButton from 'components/primary-button/PrimaryButton';
import './ConfirmedEmail.scss';
import { goToConnectWebsite } from 'utils/redirections';

export default function ConfirmedEmail() {
  const intl = useIntl();

  return (
    <Layout withAsideImage>
      <LayoutHeader>
        <h1>{intl.formatMessage(translations.title)}</h1>
        <p>{intl.formatMessage(translations.subtitle)}</p>
      </LayoutHeader>
      <div className="confirmed-email">
        <PrimaryButton
          label={intl.formatMessage(translations.goToConnect)}
          onClick={goToConnectWebsite}
        />
      </div>
    </Layout>
  );
}
