import { ChangeEvent, useRef } from 'react';
import './GalleryNewImageButton.scss';
import PlusIcon from 'assets/component-icons/PlusIcon';
import LoadingIcon from 'assets/component-icons/LoadingIcon';

type GalleryNewImageButtonProps = {
  loading: boolean;
  onFileSelection: (file: File) => void;
};

export default function GalleryNewImageButton({
  loading,
  onFileSelection: onFileSelectionCallback,
}: GalleryNewImageButtonProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  function onButtonClick() {
    inputRef.current?.click();
  }

  function onFileSelection(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length === 1) {
      onFileSelectionCallback(event.target.files[0]);
    }
  }

  return (
    <button
      type="button"
      className="gallery-new-image-button"
      onClick={onButtonClick}
    >
      <input
        ref={inputRef}
        type="file"
        onChange={onFileSelection}
      />

      {loading ? <LoadingIcon /> : <PlusIcon />}
    </button>
  );
}
