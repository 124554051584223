import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { avatarPanelValidationSchema } from './panels.validation';
import useProfileCompletionUserUpdate from './ProfileCompletionUserUpdate.hook';
import AvatarInput from '../../../components/avatar-input/AvatarInput';
import PrimaryButton from '../../../components/primary-button/PrimaryButton';
import { CommonTranslations } from 'i18n/common.translations';
import { Area } from 'react-easy-crop';
import useFormikNetworkState from 'hooks/use-formik-network-state';
import ArrowRightIcon from 'assets/component-icons/ArrowRightIcon';

type FormikValues = {
  avatar?: File;
  cropArea?: Area;
};

export default function AvatarPanel() {
  const intl = useIntl();

  const updateUser = useProfileCompletionUserUpdate();
  const networkState = useFormikNetworkState(ArrowRightIcon);

  const formik = useFormik<FormikValues>({
    initialValues: {},
    validationSchema: avatarPanelValidationSchema(intl),
    validateOnMount: true,
    onSubmit: async ({ avatar }) => {
      networkState.loading();
      await updateUser({ avatar });
      networkState.succeeded();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <AvatarInput onChange={(file) => formik.setFieldValue('avatar', file)} />

      <PrimaryButton
        label={intl.formatMessage(CommonTranslations.continue)}
        submit
        disabled={!formik.isValid}
        icon={networkState.iconBasedOnNetworkState}
      />
    </form>
  );
}
