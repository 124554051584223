import { defineMessages } from 'react-intl';

export default defineMessages({
  tooWeak: {
    id: 'PasswordStrength.tooWeak',
    defaultMessage: 'Trop faible',
    description: 'mdp trop faible',
  },
  strong: {
    id: 'PasswordStrength.strong',
    defaultMessage: 'Fort',
    description: 'mdp fort',
  },
  veryStrong: {
    id: 'PasswordStrength.veryStrong',
    defaultMessage: 'Très fort',
    description: 'mdp très fort',
  },
});
