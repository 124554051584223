import { PasswordField } from '@myjobglasses/account-ui';
import { Field, Formik } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import CheckPassword from '../../../../../../components/check-password/CheckPassword';
import { useTokens } from '../../../../../../hooks/use-tokens/useTokens';
import { useNewPassword } from './NewPasswordForm.hooks';
import validation from './NewPasswordForm.validation';

import { CommonTranslations } from 'i18n/common.translations';
import styles from './NewPasswordForm.module.scss';

function NewPasswordForm() {
  const intl = useIntl();
  const location = useLocation();
  const { resetPasswordToken } = useTokens(location?.search);

  const [showPasswordIndications, setShowPasswordIndications] = useState(false);

  const onSubmit = useNewPassword();

  return (
    <Formik
      initialValues={{
        password: '',
        token: resetPasswordToken || '',
      }}
      validateOnMount
      validateOnChange
      validationSchema={validation}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({
        isValid,
        handleSubmit,
        // eslint-disable-next-line arrow-body-style
      }) => {
        return (
          <form
            className={styles.form}
            onSubmit={handleSubmit}
          >
            <div style={{ position: 'relative' }}>
              <Field
                className={styles.password}
                name="password"
                type="password"
                label="Mot de passe"
                component={PasswordField}
                onFocus={() => setShowPasswordIndications(true)}
                hideError
              />
              {showPasswordIndications && <CheckPassword name="password" />}
            </div>
            <button
              id="new-password-form-submit"
              data-testid="submit-button"
              type="submit"
              className={styles.submitButton}
              disabled={!isValid}
            >
              {intl.formatMessage(CommonTranslations.continue)}
            </button>
          </form>
        );
      }}
    </Formik>
  );
}

export default NewPasswordForm;
