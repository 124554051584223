import { useIntl } from 'react-intl';
import { Icon } from '@myjobglasses/account-ui';
import Routes from 'Routes';
import { Link } from 'react-router-dom';
import RequestNewPasswordForm from './components/request-new-password-form/RequestNewPasswordForm';
import translations from './RequestNewPassword.translations';

import styles from './RequestNewPassword.module.scss';

function RequestNewPassword() {
  const intl = useIntl();

  return (
    <div className={styles.requestNewPasswordView}>
      <div className={styles.requestNewPassword}>
        <div className={styles.iconHeader}>
          <Icon
            name="lock_open"
            className={styles.icon}
          />
        </div>
        <h1 className={styles.title}>{intl.formatMessage(translations.title)}</h1>
        <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
        <RequestNewPasswordForm />
        <Link
          to={Routes.home}
          className={styles.link}
        >
          {intl.formatMessage(translations.backToSignin)}
        </Link>
      </div>
    </div>
  );
}

export default RequestNewPassword;
