export function containsDigit(text: string) {
  return /\d/.test(text);
}

export function containsLowercase(text: string) {
  return /[a-z]/.test(text);
}

export function containsUppercase(text: string) {
  return /[A-Z]/.test(text);
}

export function containsSpecialCharacter(text: string) {
  return /\W/.test(text);
}

export function haveMinimumLength(text: string) {
  return /\S{8,}/.test(text);
}

export function haveOptimalLength(text: string) {
  return /\S{12,}/.test(text);
}
