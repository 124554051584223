import { defineMessages } from 'react-intl';
import { Language } from '../@types/graphql';

export const LanguagesTranslations = defineMessages({
  [Language.Afrikaans]: {
    id: 'LanguaesTranslations.afrikaans',
    defaultMessage: 'Afrikaans',
  },
  [Language.Albanian]: {
    id: 'LanguagesTranslations.albanian',
    defaultMessage: 'Albanais',
  },
  [Language.Arabic]: {
    id: 'LanguagesTranslations.arabic',
    defaultMessage: 'Arabe',
  },
  [Language.Armenian]: {
    id: 'LanguagesTranslations.armenian',
    defaultMessage: 'Arménien',
  },
  [Language.Azeri]: {
    id: 'LanguagesTranslations.azeri',
    defaultMessage: 'Azerbaïdjanais',
  },
  [Language.Basque]: {
    id: 'LanguagesTranslations.basque',
    defaultMessage: 'Basque',
  },
  [Language.Belarusian]: {
    id: 'LanguagesTranslations.belarusian',
    defaultMessage: 'Biélorusse',
  },
  [Language.Bengali]: {
    id: 'LanguagesTranslations.bengali',
    defaultMessage: 'Bengali',
  },
  [Language.Bosnian]: {
    id: 'LanguagesTranslations.bosnian',
    defaultMessage: 'Bosniaque',
  },
  [Language.Bulgarian]: {
    id: 'LanguagesTranslations.bulgarian',
    defaultMessage: 'Bulgare',
  },
  [Language.Burmese]: {
    id: 'LanguagesTranslations.burmese',
    defaultMessage: 'Birman',
  },
  [Language.Catalan]: {
    id: 'LanguagesTranslations.catalan',
    defaultMessage: 'Catalan',
  },
  [Language.Chinese]: {
    id: 'LanguagesTranslations.chinese',
    defaultMessage: 'Chinois',
  },
  [Language.Croatian]: {
    id: 'LanguagesTranslations.croatian',
    defaultMessage: 'Croate',
  },
  [Language.Czech]: {
    id: 'LanguagesTranslations.czech',
    defaultMessage: 'Tchèque',
  },
  [Language.Danish]: {
    id: 'LanguagesTranslations.danish',
    defaultMessage: 'Danois',
  },
  [Language.Dutch]: {
    id: 'LanguagesTranslations.dutch',
    defaultMessage: 'Néerlandais',
  },
  [Language.English]: {
    id: 'LanguagesTranslations.english',
    defaultMessage: 'Anglais',
  },
  [Language.Estonian]: {
    id: 'LanguagesTranslations.estonian',
    defaultMessage: 'Estonien',
  },
  [Language.Filipino]: {
    id: 'LanguagesTranslations.filipino',
    defaultMessage: 'Philippin',
  },
  [Language.Finnish]: {
    id: 'LanguagesTranslations.finnish',
    defaultMessage: 'Finnois',
  },
  [Language.French]: {
    id: 'LanguagesTranslations.french',
    defaultMessage: 'Français',
  },
  [Language.Galician]: {
    id: 'LanguagesTranslations.galician',
    defaultMessage: 'Galicien',
  },
  [Language.Georgian]: {
    id: 'LanguagesTranslations.georgian',
    defaultMessage: 'Géorgien',
  },
  [Language.German]: {
    id: 'LanguagesTranslations.german',
    defaultMessage: 'Allemand',
  },
  [Language.Greek]: {
    id: 'LanguagesTranslations.greek',
    defaultMessage: 'Grec',
  },
  [Language.Guarani]: {
    id: 'LanguagesTranslations.guarani',
    defaultMessage: 'Guarani',
  },
  [Language.Gujarati]: {
    id: 'LanguagesTranslations.gujarati',
    defaultMessage: 'Gujarati',
  },
  [Language.Hebrew]: {
    id: 'LanguagesTranslations.hebrew',
    defaultMessage: 'Hébreu',
  },
  [Language.Hindi]: {
    id: 'LanguagesTranslations.hindi',
    defaultMessage: 'Hindi',
  },
  [Language.Hungarian]: {
    id: 'LanguagesTranslations.hungarian',
    defaultMessage: 'Hongrois',
  },
  [Language.Icelandic]: {
    id: 'LanguagesTranslations.icelandic',
    defaultMessage: 'Islandais',
  },
  [Language.Indonesian]: {
    id: 'LanguagesTranslations.indonesian',
    defaultMessage: 'Indonésien',
  },
  [Language.Italian]: {
    id: 'LanguagesTranslations.italian',
    defaultMessage: 'Italien',
  },
  [Language.Japanese]: {
    id: 'LanguagesTranslations.japanese',
    defaultMessage: 'Japonais',
  },
  [Language.Javanese]: {
    id: 'LanguagesTranslations.javanese',
    defaultMessage: 'Javanais',
  },
  [Language.Kannada]: {
    id: 'LanguagesTranslations.kannada',
    defaultMessage: 'Kannada',
  },
  [Language.Kazakh]: {
    id: 'LanguagesTranslations.kazakh',
    defaultMessage: 'Kazakh',
  },
  [Language.Khmer]: {
    id: 'LanguagesTranslations.khmer',
    defaultMessage: 'Khmer',
  },
  [Language.Kinyarwanda]: {
    id: 'LanguagesTranslations.kinyarwanda',
    defaultMessage: 'Kinyarwanda',
  },
  [Language.Korean]: {
    id: 'LanguagesTranslations.korean',
    defaultMessage: 'Coréen',
  },
  [Language.Kurdish]: {
    id: 'LanguagesTranslations.kurdish',
    defaultMessage: 'Kurde',
  },
  [Language.Latvian]: {
    id: 'LanguagesTranslations.latvian',
    defaultMessage: 'Letton',
  },
  [Language.Lithuanian]: {
    id: 'LanguagesTranslations.lithuanian',
    defaultMessage: 'Lituanien',
  },
  [Language.Macedonian]: {
    id: 'LanguagesTranslations.macedonian',
    defaultMessage: 'Macédonien',
  },
  [Language.Malay]: {
    id: 'LanguagesTranslations.malay',
    defaultMessage: 'Malais',
  },
  [Language.Malayalam]: {
    id: 'LanguagesTranslations.malayalam',
    defaultMessage: 'Malayalam',
  },
  [Language.Marathi]: {
    id: 'LanguagesTranslations.marathi',
    defaultMessage: 'Marathi',
  },
  [Language.Mongolian]: {
    id: 'LanguagesTranslations.mongolian',
    defaultMessage: 'Mongol',
  },
  [Language.Nepalese]: {
    id: 'LanguagesTranslations.nepalese',
    defaultMessage: 'Népalais',
  },
  [Language.Norwegian]: {
    id: 'LanguagesTranslations.norwegian',
    defaultMessage: 'Norvégien',
  },
  [Language.Oriya]: {
    id: 'LanguagesTranslations.oriya',
    defaultMessage: 'Oriya',
  },
  [Language.Pashto]: {
    id: 'LanguagesTranslations.pashto',
    defaultMessage: 'Pachto',
  },
  [Language.Persian]: {
    id: 'LanguagesTranslations.persian',
    defaultMessage: 'Persan',
  },
  [Language.Polish]: {
    id: 'LanguagesTranslations.polish',
    defaultMessage: 'Polonais',
  },
  [Language.Portuguese]: {
    id: 'LanguagesTranslations.portuguese',
    defaultMessage: 'Portugais',
  },
  [Language.Punjabi]: {
    id: 'LanguagesTranslations.punjabi',
    defaultMessage: 'Pendjabi',
  },
  [Language.Romanian]: {
    id: 'LanguagesTranslations.romanian',
    defaultMessage: 'Roumain',
  },
  [Language.Russian]: {
    id: 'LanguagesTranslations.russian',
    defaultMessage: 'Russe',
  },
  [Language.Serbian]: {
    id: 'LanguagesTranslations.serbian',
    defaultMessage: 'Serbe',
  },
  [Language.Sinhalese]: {
    id: 'LanguagesTranslations.sinhalese',
    defaultMessage: 'Singhalais',
  },
  [Language.Slovak]: {
    id: 'LanguagesTranslations.slovak',
    defaultMessage: 'Slovaque',
  },
  [Language.Slovenian]: {
    id: 'LanguagesTranslations.slovenian',
    defaultMessage: 'Slovène',
  },
  [Language.Spanish]: {
    id: 'LanguagesTranslations.spanish',
    defaultMessage: 'Espagnol',
  },
  [Language.Swahili]: {
    id: 'LanguagesTranslations.swahili',
    defaultMessage: 'Swahili',
  },
  [Language.Swedish]: {
    id: 'LanguagesTranslations.swedish',
    defaultMessage: 'Suédois',
  },
  [Language.Tajik]: {
    id: 'LanguagesTranslations.tajik',
    defaultMessage: 'Tadjik',
  },
  [Language.Tamazight]: {
    id: 'LanguagesTranslations.tamazight',
    defaultMessage: 'Tamazight',
  },
  [Language.Tamil]: {
    id: 'LanguagesTranslations.tamil',
    defaultMessage: 'Tamoul',
  },
  [Language.Thai]: {
    id: 'LanguagesTranslations.thai',
    defaultMessage: 'Thaï',
  },
  [Language.Turkish]: {
    id: 'LanguagesTranslations.turkish',
    defaultMessage: 'Turc',
  },
  [Language.Ukrainian]: {
    id: 'LanguagesTranslations.ukrainian',
    defaultMessage: 'Ukrainien',
  },
  [Language.Urdu]: {
    id: 'LanguagesTranslations.urdu',
    defaultMessage: 'Ourdou',
  },
  [Language.Uzbek]: {
    id: 'LanguagesTranslations.uzbek',
    defaultMessage: 'Ouzbek',
  },
  [Language.Vietnamese]: {
    id: 'LanguagesTranslations.vietnamese',
    defaultMessage: 'Vietnamien',
  },
  [Language.Visayan]: {
    id: 'LanguagesTranslations.visayan',
    defaultMessage: 'Visayan',
  },
  [Language.Welsh]: {
    id: 'LanguagesTranslations.welsh',
    defaultMessage: 'Gallois',
  },
  other: {
    id: 'LanguagesTranslations.other',
    defaultMessage: 'Autre',
  },
});
