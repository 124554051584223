import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ConfirmedEmail.title',
    defaultMessage: 'Votre adresse email est validée',
  },
  subtitle: {
    id: 'ConfirmedEmail.subtitle',
    defaultMessage: 'Votre profil est en cours de modération par nos équipes. Vous recevrez un email sous 2 jours ouvrés, dès qu’il sera validé. En attendant, nous vous proposons de découvrir les différents types d’offre entreprise disponibles avec My Job Glasses.',
  },
	goToConnect: {
		id: 'ConfirmedEmail.goToConnect',
		defaultMessage: 'Découvrir nos offres',
	},
});