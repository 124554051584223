import { DiplomaEnum } from '../../../../@types/graphql';
import { IntlShape } from 'react-intl';
import { PanelErrorTranslations } from 'scenes/profile-completion/panels/panels.translations';
import { number, mixed, object, string } from 'yup';

export const educationModalValidationSchema = (intl: IntlShape) =>
  object({
    diplomaType: mixed<DiplomaEnum>().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    diplomaTitle: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    schoolName: string().required(intl.formatMessage(PanelErrorTranslations.inputEmpty)),
    endedAt: number()
      .required(intl.formatMessage(PanelErrorTranslations.inputEmpty))
      .max(2099, intl.formatMessage(PanelErrorTranslations.endDateInFuture))
  });
