import { defineMessages } from 'react-intl';

export const SegmentationTranslations = defineMessages({
  title: {
    id: 'Segmentation.title',
    defaultMessage: 'Bienvenue !',
  },
  subtitle: {
    id: 'Segmentation.subtitle',
    defaultMessage: 'À quelle interface souhaitez-vous accéder ?',
  },

  hrAdminTitle: {
    id: 'Segmentation.hrAdminTitle',
    defaultMessage: 'Espace Entreprise',
  },
  schoolHrAdminTitle: {
    id: 'Segmentation.schoolHrAdminTitle',
    defaultMessage: 'Espace Administrateur',
  },
  hrAdminDescription: {
    id: 'Segmentation.hrAdminDescription',
    defaultMessage: 'Afin de gérer le programme Ambassadeurs de votre entreprise.',
  },
  schoolHrAdminDescription: {
    id: 'Segmentation.schoolHrAdminDescription',
    defaultMessage: 'Afin de gérer le programme Ambassadeurs de votre établissement.',
  },

  mjgTitle: {
    id: 'Segmentation.mjgTitle',
    defaultMessage: 'My Job Glasses',
  },
  mjgDescription: {
    id: 'Segmentation.mjgDescription',
    defaultMessage: "Afin d'utiliser la plateforme en tant que Membre ou Ambassadeur.",
  },

  coachTitle: {
    id: 'Segmentation.coachTitle',
    defaultMessage: 'Espace vocation',
  },
  coachDescription: {
    id: 'Segmentation.coachDescription',
    defaultMessage: "Afin de gérer vos programmes d'accompagnement.",
  },

  moderationPendingModal: {
    id: 'Segmentation.moderationPendingModal',
    defaultMessage: 'Votre entreprise n’a pas encore été validée par notre équipe, nous vous enverrons un email lorsque celle ci sera vérifiée. Pour toute question, vous pouvez contacter le support à support@myjobglasses.com',
  },
  closeModal: {
    id: 'Segmentation.closeModal',
    defaultMessage: 'Fermer',
  },
});
