import { gql } from '@apollo/client';

export const CONFIRM_EMAIL = gql`
  mutation CONFIRM_EMAIL($confirmationToken: String!) {
    confirmEmail(confirmationToken: $confirmationToken) {
      id
      signedUpAsHrAdmin
    }
  }
`;
