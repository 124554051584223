import { PropsWithChildren, useEffect } from 'react';
import './Modal.scss';
import XIcon from 'assets/component-icons/XIcon';
import useModal from './Modal.hook';
import { motion } from 'framer-motion';

type ModalProps = PropsWithChildren<{
  title?: string;
}>;

export default function Modal({ title, children }: ModalProps) {
  const [, closeModal] = useModal();

  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <motion.div
      className="modal"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <button
        className="modal__overlay"
        onClick={closeModal}
      />

      <div className="modal__container">
        <header className="modal__container__header">
          {title && <h2>{title}</h2>}
          <button
            className="modal__container__header__close-button"
            onClick={closeModal}
          >
            <XIcon />
          </button>
        </header>
        <div className="modal__container__content">{children}</div>
      </div>
    </motion.div>
  );
}
