import { gql } from '@apollo/client';

export const CREATE_UPLOAD_LINK_MUTATION = gql`
  mutation CREATE_UPLOAD_LINK($kind: UploadLinkKindEnum!, $format: UploadFormatEnum!) {
    createUploadLink(kind: $kind, format: $format) {
      id
      awsUrl
      expiresAt
      kind
    }
  }
`;