import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'MustConfirmEmail.title',
    defaultMessage: 'Confirmez votre adresse email',
  },
  titleTutor: {
    id: 'MustConfirmEmail.titleTutor',
    defaultMessage: 'Confirmer l’adresse email de mon parent ou responsable légal',
  },
  description: {
    id: 'MustConfirmEmail.description',
    defaultMessage:
      'Afin de confirmer la création de votre compte, veuillez cliquer sur le lien de validation qui vous a été envoyé à l’adresse email <boldThis>{email}</boldThis>.',
  },
  descriptionTutor: {
    id: 'MustConfirmEmail.descriptionTutor',
    defaultMessage:
      'Pour confirmer votre inscription, votre parent ou responsable légal doit cliquer sur le lien qui a été envoyé par email à l’adresse suivante : <boldThis>{email}</boldThis>.',
  },
  resendEmail: {
    id: 'MustConfirmEmail.resendEmail',
    defaultMessage: 'Envoyer un autre email',
  },
  emailSent: {
    id: 'MustConfirmEmail.emailSent',
    defaultMessage: 'email envoyé',
  },
});
