import { useContext, useState } from 'react';
import { UserContext } from 'contexts/user/UserContext';
import { useMutation } from '@apollo/client';
import { REMOVE_GALLERY_IMAGE_MUTATION, UPLOAD_GALLERY_IMAGE_MUTATION } from 'gqls/GalleryImage.gql';
import {
  RemoveGalleryImageMutation,
  RemoveGalleryImageMutationVariables,
  UploadGalleryImageMutation,
  UploadGalleryImageMutationVariables,
} from '../../../@types/graphql';
import Gallery from '../components/gallery/Gallery';

export default function GalleryPanel() {
  const { user, updateUser } = useContext(UserContext);
  const [uploadGalleryImageMutation] = useMutation<UploadGalleryImageMutation, UploadGalleryImageMutationVariables>(
    UPLOAD_GALLERY_IMAGE_MUTATION,
  );
  const [removeGalleryImageMutation] = useMutation<RemoveGalleryImageMutation, RemoveGalleryImageMutationVariables>(
    REMOVE_GALLERY_IMAGE_MUTATION,
  );
  const [isLoading, setLoadingState] = useState(false);

  async function onImageUpload(file: File) {
    setLoadingState(true);
    if (!user) return;
    const result = await uploadGalleryImageMutation({
      variables: {
        userId: user.id || '',
        image: file,
      },
    });
    if (!result.data?.uploadGalleryImage) return;

    updateUser?.({
      ...user,
      galleryImages: [...user.galleryImages, result.data.uploadGalleryImage],
    });
    setLoadingState(false);
  }

  async function onImageDeletion(imageId: string) {
    setLoadingState(true);
    if (!user) return;
    const result = await removeGalleryImageMutation({
      variables: {
        userId: user?.id || '',
        imageId,
      },
    });

    if (result.errors) return;
    updateUser?.({
      ...user,
      galleryImages: user.galleryImages.filter(({ id }) => id !== imageId),
    });
    setLoadingState(false);
  }

  return (
    <form>
      <Gallery
        images={user?.galleryImages || []}
        loading={isLoading}
        onImageUpload={onImageUpload}
        onImageDeletion={onImageDeletion}
      />
    </form>
  );
}
