import { Icon } from '@myjobglasses/account-ui';
import { useIntl } from 'react-intl';
import NewPasswordForm from './components/new-password-form/NewPasswordForm';
import translations from './NewPassword.translations';

import styles from './NewPassword.module.scss';

function NewPassword() {
  const intl = useIntl();

  return (
    <div className={styles.newPasswordView}>
      <div className={styles.newPassword}>
        <div className={styles.iconHeader}>
          <Icon
            name="lock_open"
            className={styles.icon}
          />
        </div>
        <h1 className={styles.title}>{intl.formatMessage(translations.title)}</h1>
        <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
        <NewPasswordForm />
      </div>
    </div>
  );
}

export default NewPassword;
