import { useEffect } from 'react';
import { errorTracker } from '../error-tracker/error-tracker';

export default function useTrackerBreadcrumbs() {
  useEffect(() => {
    document.addEventListener('click', (event) => {
      const target = event.target as any;
      if (target.innerText || target.name)
        errorTracker.addUIBreadcrumb(`Click on ${target.tagName.toLowerCase()} ${target.innerText || target.name}`);
    });
  }, []);
}
