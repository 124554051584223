import { useIntl } from 'react-intl';
import { LayoutHeader } from 'components/layout/Layout';
import { StickyDescriptionOfferTranslations } from './StickyDescriptionOffer.translations';
import { PremiumOfferTranslations } from 'i18n/premium-offer.translations';
import CheckIcon from 'assets/component-icons/CheckIcon';
import XIcon from 'assets/component-icons/XIcon';

import './StickyDescriptionOffer.scss';

const INCLUDED_MENTIONS = [
  PremiumOfferTranslations.mention1,
  PremiumOfferTranslations.mention2,
  PremiumOfferTranslations.mention3,
  PremiumOfferTranslations.mention4,
];

const EXCLUDED_MENTIONS = [
  PremiumOfferTranslations.mention5,
  PremiumOfferTranslations.mention6,
  PremiumOfferTranslations.mention7,
  PremiumOfferTranslations.mention8,
  PremiumOfferTranslations.mention9,
  PremiumOfferTranslations.mention10,
];

export default function StickyDescriptionOffer() {
  const intl = useIntl();

  return (
    <div className="offer-container">
      <LayoutHeader>
        <div className="offer-container__header">
          <h1>{intl.formatMessage(StickyDescriptionOfferTranslations.title)}</h1>
          <div className='flag green'>Gratuit</div>
        </div>
      </LayoutHeader>
      <h2>{intl.formatMessage(StickyDescriptionOfferTranslations.subtitleInclude)}</h2>
      {INCLUDED_MENTIONS.map((msg) => (
        <p key={msg.id}>
          <CheckIcon className="icon icon__check" />
          {intl.formatMessage(msg)}
        </p>
      ))}

      <h2>{intl.formatMessage(StickyDescriptionOfferTranslations.subtitleNotInclude)}</h2>
      {EXCLUDED_MENTIONS.map((msg) => (
        <p key={msg.id}>
          <XIcon className="icon icon__cross" />
          {intl.formatMessage(msg)}
        </p>
      ))}
    </div>
  );
}
