import { IconProps } from 'assets/component-icons/Icon';
import classNames from 'classnames';
import { FormikNetworkState } from 'hooks/use-formik-network-state';
import { ComponentType } from 'react';
import './PrimaryButton.scss';

type PrimaryButtonProps = {
  label?: string;
  icon?: ComponentType<IconProps> | null;
  accent?: boolean;
  onClick?: () => void;
  submit?: boolean;
  disabled?: boolean;
  leftIcon?: boolean;
  state?: FormikNetworkState;
  outlined?: boolean;
  smaller?: boolean;
  cyLabel?: string;
};

export default function PrimaryButton({
  label,
  icon: Icon,
  onClick,
  submit,
  disabled,
  accent,
  leftIcon,
  state,
  outlined,
  smaller,
  cyLabel="primary-button"
}: PrimaryButtonProps) {
  return (
    <button
      data-cy={cyLabel}
      className={classNames(
        'primary-button',
        { 'primary-button--only-icon': !label },
        { 'primary-button--disabled': disabled },
        { 'primary-button--accent': accent },
        { 'primary-button--left-icon': leftIcon },
        { 'primary-button--succeeded': state === 'succeeded' },
        { 'primary-button--outlined': outlined },
        { 'primary-button--smaller': smaller },
      )}
      type={submit ? 'submit' : 'button'}
      onClick={onClick}
      disabled={disabled}
    >
      {label && label}
      {Icon && <Icon className="primary-button__icon" />}
    </button>
  );
}
