import { defineMessages } from 'react-intl';

export const errorTranslations = defineMessages({
  emailRequired: {
    id: 'RequestNewPasswordForm.mandatory.email',
    defaultMessage: 'Veuillez saisir une adresse e-mail valide.',
  },
});

export default defineMessages({
  requestButton: {
    id: 'RequestNewPasswordForm.requestButton',
    defaultMessage: 'envoyer le lien',
  },
});
